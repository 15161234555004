import { Circles } from "react-loader-spinner";
// import { API_TIMEOUT } from '../../api/request/config';

const Loader = (props) => {
 console.log("props?.isLoading isLoading   ====>", props?.isLoading)
 if (props?.isLoading) {
  setTimeout(() => {
   props?.callBack();
  }, 5000);
 }
 return (
  <>
   {
    props?.isLoading && <div
    style={{
     backgroundColor: "transparent",
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     // position:'absolute',
     overflow: "hidden",
     // width:'80vw',
     height: "100vh",
     userSelect: "none",
    }}
   >
    <Circles
     height="40"
     width="40"
     color="var(--primary-color)"
     ariaLabel="circles-loading"
     wrapperStyle={{}}
     wrapperClass=""
     visible={true}
    />
   </div>
   }
  </>
 );
};

export default Loader;
