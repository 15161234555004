import img1 from '../../../new_assets/img/auth-img-grid1.jpg'
import img2 from '../../../new_assets/img/auth-img-grid2.jpg'
import img3 from '../../../new_assets/img/auth-img-grid3.jpg'
import img4 from '../../../new_assets/img/auth-img-grid4.jpg'
import img5 from '../../../new_assets/img/auth-img-grid5.jpg'
import img6 from '../../../new_assets/img/auth-img-grid6.jpg'

export const authvideocarddata = [
    {image:img1,text:"Parent Monitoring"},
    {image:img2,text:"Organization"},
    {image:img3,text:"Students Collabration"},
    {image:img4,text:"Multiple Device Responses"},
    {image:img5,text:"Teachers Forum"},
    {image:img6,text:"Interactive Learning"}
]