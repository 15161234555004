import { useNavigate } from 'react-router-dom';
import '../Styles/SessionExpiredStyles.css';
import { API_URL } from '../../../api/URL/Api_Route';
import RunTimeConstant from '../../../config/RuntimeConfig';
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import { useDispatch } from 'react-redux';
import { LogOut, showbanner } from '../../../redux/action';
import LoaderView from '../../LoaderComponet/View/LoaderView';
import { useState } from 'react';

const SessionExpiredView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();


  // const handleNavigate = () => {
  //   setIsLoading(true)
  //   window.location.href = '/';
  //   setTimeout(() => {
  //     setIsLoading(false)
  //     }, 1000);    
  //     dispatch(showbanner(false));
  // };

  const HandleSession = async () => {

    try {
      const payload = {
        baseURL: RunTimeConstant.SERVICEURL.CS,
        url: `${API_URL.getSession}`,
      };

      const res = await ApiRequestMethod(payload);

      if (res?.status === 200) {
        dispatch(showbanner(false));
        setIsLoading(false);
      }
    } catch (err) {

      if (err?.response?.status === 419 || err?.response?.status === 404) {
        setIsLoading(true);
        window.location.href = '/';
        // handleNavigate(); 


      } else {
        console.error('Error fetching session details:', err?.response?.status || err?.message || err);
        setIsLoading(false);
      }
    }
  };

  // Trigger session check and loading indicator on button click
  const onSessionCheckClick = () => {
    setIsLoading(true)
    HandleSession();
  };

  // Show loader view while loading
  if (isLoading) {
    return <LoaderView isLoading={isLoading} callBack={() => setIsLoading(false)} />;
  }

  return (
    <div style={{ height: '100dvh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div className="session-banner">
        <p className="session-text">Session is Expired</p>
        <div className="session-button-container">
          <button className="session-button" onClick={onSessionCheckClick}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionExpiredView;
