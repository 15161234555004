import * as React from "react";
const ProfileSvg = (props) => (
  <svg
    width={23}
    height={18}
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 17C1 17 4.02794 13.3117 11.4993 13.3117C18.9707 13.3117 22 17 22 17M11.4993 8.7758C12.5802 8.7758 13.6167 8.36618 14.381 7.63706C15.1453 6.90794 15.5746 5.91903 15.5746 4.8879C15.5746 3.85676 15.1453 2.86786 14.381 2.13874C13.6167 1.40962 12.5802 1 11.4993 1C10.4185 1 9.38192 1.40962 8.61765 2.13874C7.85339 2.86786 7.42402 3.85676 7.42402 4.8879C7.42402 5.91903 7.85339 6.90794 8.61765 7.63706C9.38192 8.36618 10.4185 8.7758 11.4993 8.7758Z"
      stroke="#21373F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ProfileSvg;
