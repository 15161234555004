import '../Styles/MyCourseVideoStyles.css';
import Video_Svg from '../../../assets/svg/Video_Svg';
import VideoPlayerView from '../../../CommonComponents/VideoPlayerContainer/View/VideoPlayerView';
import CoursePriceCard from '../../../CommonComponents/CoursePriceCard/View/CoursePriceCard';
import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import TopicDetailsBoxView from '../../../CommonComponents/TopicDetailsContainer/View/TopicDetailsBoxView';
import { useDispatch } from 'react-redux';
import { showbanner } from '../../../redux/action';
import { API_URL } from '../../../api/URL/Api_Route';
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import Configuration from '../../../config/configuration';
import Assessment_Svg from '../../../assets/svg/Assessment_Svg';
import Document_Svg from '../../../assets/svg/Document_Svg';
import PurchasedTopicDetailBoxView from './../../../CommonComponents/PurchasedTopicDetailBox/View/PurchasedTopicDetailBoxView';
import NetworkError from '../../../component/javascript/Network_Error';
import LoaderView from '../../../CommonComponents/LoaderComponet/View/LoaderView';

const MyCourseVideoPageView = () => {
    const [videoDataValue, setVideoDataValue] = useState(null);
    const [videoData, setVideoData] = useState(null)
    const [loading, setLoading] = useState(false);
    const { courseId } = useParams();
    const dispatch = useDispatch()

    const [is_network_error, set_is_network_error] = useState(false);

    

    const fetchData = async () => {
        setLoading(true)
        const payload = {
            method: "post",
            url: API_URL.getCourseById,
            baseURL: Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE,
            data: {
                "_id": courseId
            },
        };
        ApiRequestMethod(payload)
            .then((res) => {

                if (res?.status == 200) {
                    if (res?.data?.data) {
                        setVideoDataValue(res?.data?.data);
                        setVideoData(res?.data?.data?.AssetPath + res?.data?.data?.Video);
                        setLoading(false)
                    }
                }
            })
            .catch((err) => {
                console.log("Home Page Error===>", err)
                if (err?.response?.status === 404) {
                    dispatch(showbanner({ showbanner: true }))
                    setLoading(false)
                } else {
                    console.error("Get Subject Data Fetching Error:", err);
                      set_is_network_error(true)
                      setLoading(false)
                    }
            });

    };


    useEffect(() => {
        if (!courseId) return;
        fetchData();
    }, [courseId]);


    
    if (is_network_error) {
        return <NetworkError callBack={() => {
            set_is_network_error(false)
            fetchData();
        }} />;
    }

      if(loading){
        return <LoaderView isLoading={loading} />;
      }

    return (
        <div className='mycourse-video-page-main-container'>
            <div className='mycourse-video-page-left-video-container'>
                <div className='mycourse-video-page-course-video-container'>
                    <VideoPlayerView video_path_data={videoData} />
                </div>
            </div>
            <div className='mycourse-video-page-right-video-details-container'>
                <div className='mycourse-video-page-main-topic-container'>
                    <div className='mycourse-video-page-topic-heading-row'>
                        <div className='mycourse-topic-heading-container'>
                            <span className='mycourse-topic-heading-styling'>
                                {videoDataValue?.CourseName}
                            </span>
                        </div>
                        <div className='mycourse-topic-icon-details-container'>
                            <div className='mycourse-topic-icon-details'>
                                <div className='mycourse-individual-icon-wrap'>
                                    <Video_Svg />
                                    <span className='mycourse-icon-desc-styling'>20 Videos</span>
                                </div>
                                {/* <div className='mycourse-individual-icon-wrap'>
                                    <Document_Svg />
                                    <span className='mycourse-icon-desc-styling'>20 Documemnts</span>
                                </div>
                                <div className='mycourse-individual-icon-wrap'>
                                    <Assessment_Svg />
                                    <span className='mycourse-icon-desc-styling'>20 Assessment</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='mycourse-row-wrapup'>
                        <PurchasedTopicDetailBoxView setVideoData={setVideoData} set_is_network_error={set_is_network_error}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyCourseVideoPageView;
