import { useEffect, useState } from "react";
import HomeVideoCardView from './../../HomeVideoCardComponent/View/HomeVideoCardView';
import '../Styles/CourseCardStyles.css';
import { RiArrowDropDownLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { showbanner } from "../../../redux/action";
import { API_URL } from "../../../api/URL/Api_Route";
import { ApiRequestMethod } from "../../../api/request/Api_Calls";
import Configuration from "../../../config/configuration";
import MathsImage from '../../../assets/image/CourseImage/CourseImage.png'
import ChemistryImage from '../../../assets/image/CourseImage/ChemistryImage.png'
import BiologyImage from '../../../assets/image/CourseImage/BiologyImage.png'
import React, { useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import Slider from "react-slick";
// import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { use } from "react";



const CourseCardView = (props) => {
  const righthideout = useRef(null)
  const lefthideout = useRef(null)
  const { datas, getPurchasedCourse, countryCode, position } = props
  const [currentIndex, setCurrentIndex] = useState(0);
  const getDeviceType = () => {
  
      if (window.innerWidth <= 900) return 'mobile';
      if (window.innerWidth <= 1440) return 'tablet';
      // if (window.innerWidth <= 1250) return 'laptop';
      // if (window.innerWidth <= 1378.5) return 'laptop';
      // return 'desktop';
  };
      // if (window.innerWidth <= 1092.5) return 'tablet';
      const [deviceType, setDeviceType] = useState(getDeviceType());

  useEffect(() => {
    const handleResize = () => setDeviceType(getDeviceType());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);


  const itemCount = deviceType === 'mobile' ? 1
    : deviceType === 'tablet' ? 2:3
  


  // const datas=[]
  console.log("position============>", position)

  console.log("datas -- ", datas)


  const carouselRef = useRef(null);  

  const handlePrevClick = () => {
    if (carouselRef.current) {
      console.log("index",carouselRef.current)
      carouselRef.current.prev();  
    }
    // setTimeout(() => {
    //   laterchange()      
    // }, 800);

  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.next();  
    }
  };

  const [showAll, setShowAll] = useState(false);
  const [videoCardData, SetVideoCardData] = useState([]);
  const [purchasedcourses, setPurchasedcourses] = useState([])


  console.log("videoCardData====>", videoCardData)

  // const navigate = useNavigate();
  const dispatch = useDispatch()
  const onChangedd =(event)=>{
    console.log("Onchanges", datas,  event)
    console.log("a?.item?.count - a?.page?.size) == a?.item?.index", (event?.item?.count - event?.page?.size) == event?.item?.index, righthideout.current)
    console.log("lefthideout", event?.item?.index == 0, lefthideout.current)
    if ((event?.item?.count - event?.page?.size) == event?.item?.index && righthideout.current) {
      righthideout.current.style.opacity = "0"
    } 
    else if (righthideout.current) {
      righthideout.current.style.opacity = "1"
    }
    if (event?.item?.index == 0 && lefthideout.current) {
      console.log("lefthideout", lefthideout.current)
      lefthideout.current.style.opacity = "0"
    } 
    else if (lefthideout.current) {
      lefthideout.current.style.opacity = "1"
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!datas) return;

      const payload = {
        method: "post",
        url: API_URL.getCourseBySubject,
        baseURL: Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE,
        data: {
          "Subject": datas,
          "page": 1,
          "limit": 4
        },
      };

      try {
        const res = await ApiRequestMethod(payload);
        if (res?.status === 200 && res?.data?.data?.length) {
          console.log("course data subject wise ---", res?.data?.data);
          const purchased_course_data = await getPurchasedCourse();
          console.log("purchased_course_data --- ", purchased_course_data);
          setPurchasedcourses(purchased_course_data)
          SetVideoCardData(res?.data?.data);
        }
      } catch (err) {
        console.log("Home Page Error===>", err);
        if (err?.response?.status === 404) {
          dispatch(showbanner({ showbanner: true }));
        } else {
          console.error("Error during get course by subject:", err);
        }
      }
    };

    fetchData();
  }, [datas]);




  const options = {
    loop: false,
    margin: 20,
    nav: false,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    responsive: {
      0: { items: 1 },
      900: { items: 2 },
      1440: { items: 3 }
    }
  };

  return (
    <>
      {position === 'left' ? (
        <div className="left-view-home-page-view-overall-container" >


          {datas.length > 0 ? (
            <div className="left-view-home-page-left-side-content-view-course-name-header-container-right-mobile">
              <p className="home-page-individual-courses-container-course-name">
                {datas}
              </p>
            </div>
          ) :
            (
              <div className="left-view-home-page-left-side-content-view-course-name-header-container-right-mobile" style={{ marginBottom: '15px' }}>
                <div className="home-page-individual-courses-container-course-name-container-p">
                  <p className="do"> </p>
                </div>
              </div>
            )
          }

          {/* Left Side Content */}
          <div className="left-view-home-page-left-side-content-view">
            <div className="left-view-home-page-left-side-content-view-wrapup" >

            
              {datas.length > 0 ? (
                <div className="left-view-home-page-left-side-content-view-course-name-header-container">
                  <p className="home-page-individual-courses-container-course-name">
                    {datas}
                  </p>
                </div>
              ) :
                (
                  <div className="left-view-home-page-left-side-content-view-course-name-header-container" style={{ marginBottom: '15px' }}>

                    <div className="home-page-individual-courses-container-course-name-container-p">
                      <p className="do"> </p>
                    </div>
                  </div>
                )


              }

              {/* Course Content */}
              <div className="left-view-home-page-left-side-content-view-course-content-container" >
                <div className="left-view-home-page-left-side-content-view-course-content-container-wrapup">

                  <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>

{
  videoCardData?.length>=1?(
                    <div className="slider-container">
                      <div
                        className="arrow left-arrow"
                        ref={lefthideout}
                        style={{
                          height: '20px',
                          width: '20px',
                          zIndex: 1, 
                          cursor: 'pointer',
                          opacity: videoCardData.length == itemCount ? '0' : '1'
                        }}
                        onClick={handlePrevClick}
                      >
                        {/* ‹ */}
                        <SlArrowLeft />
                      </div>
                      <div style={{width:'85%',display:'flex',flexDirection:'row',justifyContent:"space-around"}}>

                        <OwlCarousel  ref={carouselRef} className="owl-theme" onChanged ={onChangedd} {...options}>
                          {videoCardData.map((value, index) => (
                            <div key={index} className="slide-item">
                              <HomeVideoCardView
                                data={value}
                                countryCode={countryCode}
                                purchasedcourses={purchasedcourses}
                              />

                            </div>
                          ))}

                        </OwlCarousel>



                      </div>
                      <div
                        className="arrow right-arrow"
                        ref={righthideout}
                        style={{ 
                          height: '20px',
                          width: '20px',
                          zIndex: 1,  
                          cursor: 'pointer',
                          opacity: videoCardData.length == itemCount ? '0' : '1'
                        }}
                        onClick={handleNextClick}
                      >
                        {/* › */}
                        <SlArrowRight />
                      </div>
                    </div>

                      ):(
                        <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:"center",alignItems:'center'}}>
                        <div style={{width:'86%',display:'flex',flexDirection:'row',justifyContent:"space-between"}}>
                           {Array(itemCount).fill(0).map((value, index) => (
                            <div key={index} style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'space-around'}}>
                              <HomeVideoCardView
                                data={value}
                                countryCode={countryCode}
                                purchasedcourses={purchasedcourses}
                              />

                            </div>
                          ))}
                        </div>
                        </div>
                      )}
                  </div>

                </div>
              </div>
            </div>
          </div>

          {/* Right Side Content */}
          <div className="left-view-home-page-right-side-content-view">
            {datas.length > 0 ? (
              <div style={{ height: "312px", width: '340px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {datas === "Chemistry" ? (
                  <img
                    src={ChemistryImage}
                    alt="Course"
                    style={{ width: "100%", height: "auto", objectFit: "cover" }}
                  />) : datas === "Mathematics" ? (
                    <img
                      src={MathsImage}
                      alt="Course"
                      style={{ width: "100%", height: "auto", objectFit: "cover" }} />

                  ) : (
                  <img
                    src={BiologyImage}
                    alt="Course"
                    style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                )
                }

              </div>) : (
              <div style={{ height: "90%", width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="do-123"></div>
              </div>
            )}
          </div>
        </div>
      ) :
        (<div className="left-view-home-page-view-overall-container" >

          {datas.length > 0 ? (
            <div className="left-view-home-page-left-side-content-view-course-name-header-container-right-mobile">
              <p className="home-page-individual-courses-container-course-name">
                {datas}
              </p>
            </div>
          ) :
            (
              <div className="left-view-home-page-left-side-content-view-course-name-header-container-right-mobile" style={{ marginBottom: '15px' }}>

                <div className="home-page-individual-courses-container-course-name-container-p">
                  <p className="do"> </p>
                </div>
              </div>
            )
          }

          {/* Left Side Content */}
          <div className="left-view-home-page-left-side-content-view-right" >
            <div className="left-view-home-page-left-side-content-view-wrapup-right" >


              {datas.length > 0 ? (
                <div className="left-view-home-page-left-side-content-view-course-name-header-container-right">
                  <p className="home-page-individual-courses-container-course-name">
                    {datas}
                  </p>
                </div>
              ) :
                (
                  <div className="left-view-home-page-left-side-content-view-course-name-header-container-right" style={{ marginBottom: '15px' }}>

                    <div className="home-page-individual-courses-container-course-name-container-p">
                      <p className="do"> </p>
                    </div>
                  </div>
                )


              }

              {/* Course Content */}
              <div className="left-view-home-page-left-side-content-view-course-content-container-right" >
                <div className="left-view-home-page-left-side-content-view-course-content-container-wrapup">

                  <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>

                  {videoCardData?.length>=1?(
                    <div className="slider-container">
                      <div
                        className="arrow left-arrow"
                        ref={lefthideout}
                        style={{
                          height: '20px',
                          width: '20px',
                          zIndex: 1, 
                          cursor: 'pointer',
                          opacity: videoCardData.length == itemCount ? '0' : '1'
                        }}
                        onClick={handlePrevClick}
                      >
                        {/* ‹ */}
                        <SlArrowLeft />
                      </div>
                      <div style={{width:'85%',display:'flex',flexDirection:'row',justifyContent:"space-around"}}>

                        <OwlCarousel  ref={carouselRef} className="owl-theme" onChanged ={onChangedd} {...options}>
                          {videoCardData.map((value, index) => (
                            <div key={index} className="slide-item">
                              <HomeVideoCardView
                                data={value}
                                countryCode={countryCode}
                                purchasedcourses={purchasedcourses}
                              />

                            </div>
                          ))}

                        </OwlCarousel>



                      </div>
                      <div
                        className="arrow right-arrow"
                        ref={righthideout}
                        style={{ 
                          height: '20px',
                          width: '20px',
                          zIndex: 1,  
                          cursor: 'pointer',
                          opacity: videoCardData.length == itemCount ? '0' : '1'
                        }}
                        onClick={handleNextClick}
                      >
                        {/* › */}
                        <SlArrowRight />
                      </div>
                    </div>

                      ):(
                        <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:"center",alignItems:'center'}}>
                        <div style={{width:'85%',display:'flex',flexDirection:'row',justifyContent:"space-between"}}>
                           {Array(itemCount).fill(0).map((value, index) => (
                            <div key={index} style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'space-around'}}>
                              <HomeVideoCardView
                                data={value}
                                countryCode={countryCode}
                                purchasedcourses={purchasedcourses}
                              />

                            </div>
                          ))}
                        </div>
                        </div>
                      )}


                  </div>

                </div>
              </div>
            </div>
          </div>

          {/* Right Side Content */}
          <div className="left-view-home-page-right-side-content-view-right">
            <div style={{ height: "312px", width: '340px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {datas.length > 0 ? (
                <div style={{ height: "312px", width: '340px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {datas === "Chemistry" ? (
                    <img
                      src={ChemistryImage}
                      alt="Course"
                      style={{ width: "100%", height: "auto", objectFit: "cover" }}
                    />) : datas === "Mathematics" ? (
                      <img
                        src={MathsImage}
                        alt="Course"
                        style={{ width: "100%", height: "auto", objectFit: "cover" }} />

                    ) : (
                    <img
                      src={BiologyImage}
                      alt="Course"
                      style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                  )
                  }

                </div>) : (
                <div style={{ height: "90%", width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="do-123"></div>
                </div>
              )}
            </div>
          </div>
        </div>)
      }
    </>

  );
};

export default CourseCardView;
