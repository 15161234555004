import { useEffect, useState } from "react";
import { ApiRequestMethod } from "../../../api/request/Api_Calls";
import { API_URL } from "../../../api/URL/Api_Route";
import Configuration from "../../../config/configuration";
import { useDispatch } from "react-redux";
import '../Styles/ExpandVideoStyles.css';
import Video_Svg from "../../../assets/svg/Video_Svg";
import { showbanner } from "../../../redux/action";
import toast from "react-hot-toast";

const ExpandVideoContent = ({ TopicID, indexvalue, setVideoData }) => {
    const [videoDisplayData, setVideoDisplayData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const payload = {
                method: "post",
                url: API_URL.getVideoByTopic,
                baseURL: Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE,
                data: { "TopicID": TopicID },
            };

            ApiRequestMethod(payload)
                .then((res) => {
                    if (res?.status === 200 && res?.data?.data?.Videos?.length) {
                        setVideoDisplayData(res?.data?.data);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error("Error fetching topic data:", err);
                    if (err?.response?.status === 404) {
                        dispatch(showbanner({ showbanner: true }));
                    }
                    setIsLoading(false);
                });
        };

        fetchData();
    }, [TopicID, dispatch]);

    return (
        <div style={{ display: "flex", flexDirection: 'column', width: '100%', alignItems: 'flex-end' }}>
            {isLoading ? (
                <p style={{ textAlign: "center", width: "100%", opacity: "0.6" }}>Loading videos...</p>
            ) : videoDisplayData?.Videos?.length > 0 ? (
                videoDisplayData?.Videos?.map((value, index) => (
                    <div
                        className='expanded-content'
                        style={{ cursor: 'not-allowed' }}
                        key={index}
                        onClick={() => { toast("Purchase the course to watch the video") }}
                    >
                        <div style={{ width: '97%', display: 'flex', height: '100%', alignItems: 'center', flexDirection: 'row' }}>
                            <div style={{ width: '94%' }}>
                                <span className='topic-styling-details' style={{ opacity: '0.2' }}>
                                    <Video_Svg color={'var(--grey-color)'} height={'25'} width={'15'} />
                                    &nbsp;&nbsp; {value?.VideoName}
                                </span>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p style={{ textAlign: "center", width: "100%", opacity: "0.6" }}>Video will be updated soon</p>
            )}
        </div>
    );
};

export default ExpandVideoContent;
