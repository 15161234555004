import '../Styles/HomeVideoCardStyles.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Configuration from '../../../config/configuration';
import { CountryToCurrency } from '../../../helper/country_to_currency';
import { useSelector } from 'react-redux';



const HomeVideoCardView = ({ data, countryCode, purchasedcourses }) => {
    console.log("HomeVideoCardView data =========>",data)
    const { cartDetails } = useSelector((state) => state.applicationState);
    
    const [loading, setLoading] = useState(true);
    const [purchaseStatus, setPurchaseStatus] = useState('Loading...');
    
    const navigate = useNavigate();
    const videoId = data?._id;

  
    useEffect(() => {
        if (data) {
            setTimeout(() => setLoading(false), 500);
        }
    }, [data]);

    useEffect(() => {
        const isPurchased = purchasedcourses?.some(course => course.Course.CourseID === videoId);
        const isCartCourse = cartDetails?.includes(videoId);

        if (isPurchased) {
            setPurchaseStatus('View Course');
        } else if (isCartCourse) {
            setPurchaseStatus('Go to Cart');
        } else {
            setPurchaseStatus('Explore');
        }
    }, [purchasedcourses, cartDetails, videoId]);

    const handleNavigation = () => {
        const routes = {
            'View Course': `/mycourse_video/${videoId}`,
            'Go to Cart': `/cart`,
            'Explore': `/course_video/${videoId}`
        };
        navigate(routes[purchaseStatus]);
    };

    

    

    const cost = data?.Price?.[CountryToCurrency(countryCode)] || 0;
    const percentage = 25;
    const revisedAmount = (percentage / 100) * cost;
    const includedAmount = cost + revisedAmount;

    console.log("includedAmount",includedAmount)
    console.log("revisedAmount",revisedAmount)
    console.log("revisedAmount",percentage)


    const thumbnail_image = `${Configuration.BASEURL}${Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE}assert/image?id=${encodeURIComponent(data?.AssetPath + data?.Image)}`;

    if (loading) {
        return (
            <>
                {Array(1).fill(0).map((_, index) => (
                    <div key={index} className='individual-course-card-container skeleton-loader'>
                        <div className='individual-course-card-container-video-container-b'></div>
                        <div className='column-split-up'>
                            <div className='individual-course-card-container-video-details-container'>
                                <div className='individual-course-card-container-video-details-container-row1'>
                                    <div className='individual-course-card-container-video-details-container-row1-sub-name-container'>
                                        <div className='individual-course-card-container-video-details-container-row1-sub-name-inside-container-b'>
                                            <span className='Sub-name-stylings'></span>
                                        </div>
                                    </div>
                                    <div className='individual-course-card-container-video-details-container-row1-sub-amount-container-b'>
                                        <span className='Sub-amount-stylings'></span>
                                    </div>
                                </div>
                                <div className='individual-course-card-container-video-details-container-row2-b'>
                                    <div style={{ width: '100%' }}>
                                        <span className='topic-heading'></span>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <span className='topic-details'></span>
                                    </div>
                                </div>
                            </div>
                            <div className='individual-course-card-container-video-details-button-container'>
                                <div className='individual-course-card-container-video-details-button-b'></div>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    }

    var thumnail_image = `${Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE}assert/image?id=${encodeURIComponent(
        data?.AssetPath + data?.Image
    )}`

    console.log("checking the opacity====>", data?.CourseName)

    return (
        <div 
        className='individual-course-card-container' 
        style={{
          opacity: ['AP calculus AB', 'CBSE Mathematics-12'].includes(data?.CourseName) ? 1 : 0.5,
          pointerEvents: ['AP calculus AB', 'CBSE Mathematics-12'].includes(data?.CourseName) ? 'auto' : 'none'
        }}
      >
            <div className='individual-course-card-container-video-container'>
                <img src={thumnail_image} crossOrigin="anonymous" />
            </div>
            <div className='column-split-up' >
                <div className='individual-course-card-container-video-details-container'>

                    <div className='individual-course-card-container-video-details-container-row1'>
                        <div className='individual-course-card-container-video-details-container-row1-sub-name-container'>
                        </div>
                        {(['Mathematics'].includes(data?.Subject)) ? (<div className='individual-course-card-container-video-details-container-row1-sub-amount-container'>
                            <span className='Sub-amount-stylings-cancel'>
                                <s>{CountryToCurrency(countryCode)} {includedAmount}</s>
                            </span>
                        </div>):(<></>)}
                    </div>


                    <div className='individual-course-card-container-video-details-container-row1'>
                        <div className='individual-course-card-container-video-details-container-row1-sub-name-container'>
                            <div className='individual-course-card-container-video-details-container-row1-sub-name-inside-container'>
                                <span className='Sub-name-stylings'>
                                    {data?.Subject?.slice(0, 3)}
                                </span>
                            </div>
                        </div>
                        {(['Mathematics'].includes(data?.Subject)) ? (<div className='individual-course-card-container-video-details-container-row1-sub-amount-container'>
                            <span className='Sub-amount-offer'>{percentage}% Off</span>
                            &nbsp;
                            <span className='Sub-amount-stylings'>
                                {CountryToCurrency(countryCode)} {cost}
                            </span>
                        </div>):(<></>)}
                    </div>
                    <div className='individual-course-card-container-video-details-container-row2'>
                        <div style={{ width: '100%' }}>
                            <span className='topic-heading'> {data?.CourseName}</span>
                        </div>
                        <div style={{ width: '100%' }}>
                            <span className='topic-details'>

                                {data?.Description ? data.Description.slice(0, 25) + "..." : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='individual-course-card-container-video-details-button-container'>
                    {purchaseStatus==='View Course'?(
                    <div
                        className='individual-course-card-container-video-details-incart-button'
                        style={{ cursor: 'pointer' }}
                        onClick={handleNavigation}
                    >
                        {purchaseStatus}
                    </div>
                    ):(

                    <div
                        className='individual-course-card-container-video-details-button'
                        style={{ cursor: 'pointer' }}
                        onClick={handleNavigation}
                    >
                        {purchaseStatus}
                    </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default HomeVideoCardView;
