import { useEffect, useState } from 'react';
import '../Styles/HomePageStyles.css';
import CourseCardView from '../../../CommonComponents/CourseCardComponent/View/CourseCardView';
import { useDispatch, useSelector } from 'react-redux';
import { setPurchasedCourses, showbanner } from '../../../redux/action';
import { API_URL } from '../../../api/URL/Api_Route';
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import Configuration from './../../../config/configuration';
import toast from 'react-hot-toast';
import DropdownCourses from '../../../CommonComponents/DropdownComponents/View/DropdownView';
import SearchIcon from './../../../new_assets/Svg/SearchIcon';

const HomePageView = () => {
    const dispatch = useDispatch()

    const { session_details, token, cart_item_count } = useSelector(
        (state) => state.applicationState
    );


    const [courseData, setCourseData] = useState([0]);
    const [Loading, Setisloading] = useState(false);

    // const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024.5);
    
    //   useEffect(() => {
    //     const handleResize = () => setIsMobile(window.innerWidth <= 1024.5);
    //     window.addEventListener("resize", handleResize);
    //     return () => window.removeEventListener("resize", handleResize);
    //   }, []);


    useEffect(() => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href);
        };
    }, []);

    /* getting list of ids of purchase courses */
    const getPurchasedCourse = async () => {
        Setisloading(true);
        let payload = {
            baseURL: Configuration.BASEURL + Configuration.SERVICEURL.PURCHASE_SERVICE,
            url: `${API_URL.get_purchase_course}?UserID=${session_details?.sub}`,
        };

        return ApiRequestMethod(payload) // Return the promise
            .then(async (response) => {
                console.log("getPurchasedCourse response outside my_courses", response);
                if (response.status === 200) {
                    const new_data = response.data.data; // Array of course IDs
                    dispatch(setPurchasedCourses(new_data));
                    console.log("new_data outside my_courses === ", new_data);
                    Setisloading(false);
                    return new_data; // Return the purchased course data
                } else {
                    Setisloading(false);
                    toast.dismiss();
                    toast.error(response.message);
                    return []; // Return an empty array or handle it as needed
                }
            })
            .catch((error) => {
                console.log("getPurchasedCourse error outside", error);
                Setisloading(false);
                // set_Is_Network_Error(true);
                return []; // Return an empty array or handle it as needed
            });
    };


    /* getting course data subject wise */
    useEffect(() => {
        const fetchData = async () => {
            Setisloading(true);
            const payload = {
                method: "post",
                url: API_URL.getSubjects,
                baseURL: Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE,
                data: {},
            };
            ApiRequestMethod(payload)
                .then((res) => {
                    if (res?.status == 200) {
                        if (res?.data?.data?.length) {
                            console.log("course data subject list  -- ", res?.data?.data)
                            setCourseData(res?.data?.data);
                            Setisloading(false);
                        }
                    }
                })
                .catch((err) => {
                    console.log("Home Page Error===>", err)
                    if (err?.response?.status === 404) {
                        dispatch(showbanner({ showbanner: true }))
                    } else {
                        console.error("Get Subject Data Fetching Error:", err);
                    }
                });

        };

        fetchData();
    }, []);

    return (
        <div className="home-page-main-container">
             
                            
                                <div className='home-page-filter-container'>
                                <div className='home-page-dropdown-filter-container'>
                                    <span className='home-page-dropdown-filter-name'>Select Subject</span>
                                    <DropdownCourses courseData={courseData} />
                                </div>
                                <div className='home-page-search-filter-container' >
                                <div className='search-box-container' style={{opacity:'0.5',cursor:'not-allowed'}}>
                                    <input className='search-input-box' placeholder='Search' style={{pointerEvents:'none'}}/>
                                    <SearchIcon/>
                                </div>
                                </div>
                               
                            </div>
                            
                        
          
            <div className="home-page-all-courses-container">
                {courseData?.slice().reverse().map((value, index) => (
                    <CourseCardView
                        key={index}
                        indexvalue={index}
                        datas={value}
                        position={index % 2 === 0 ? 'left' : 'right'}
                        getPurchasedCourse={getPurchasedCourse}
                        countryCode={session_details?.cc}
                    />
                ))}


            </div>
        </div>
    );
};

export default HomePageView;



