import "../Styles/MyCoursesStyles.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import MyCourseCardView from "../../../CommonComponents/MyCourseCard/View/MyCourseCardView";
import { API_URL } from "../../../api/URL/Api_Route";
import { ApiRequestMethod } from "../../../api/request/Api_Calls";
import { useDispatch, useSelector } from "react-redux";
import Configuration from "../../../config/configuration";
import NetworkError from "../../../component/javascript/Network_Error";
import { showbanner } from "../../../redux/action";
import LoaderView from './../../../CommonComponents/LoaderComponet/View/LoaderView';

const MyCoursesView = () => {
  //  const default_class_image = require("../../assets/image/Default_class_image.jpg");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, session_details, course, purchased_course_details } = useSelector((state) => state.applicationState);

  const [is_Loading, set_Is_Loading] = useState(false);
  const [is_Network_Error, set_Is_Network_Error] = useState(false);

  const [suggest_Course_Data, set_Suggest_Course_Data] = useState([]);
  const [my_Course_Data, set_My_Course_Data] = useState([])



  //   let payload = {
  //    url: API_URL.userCart,
  //    data: {
  //     _id: token,
  //     limit: CONFIG.PAGINATION_COURSE_LIMIT,
  //    },
  //   };
  //   PostMethod(payload)
  //    .then(async (response) => {
  //     console.log("suggest_Course response", response);
  //     if (response.success) {
  //      let exist_data = [];
  //      const new_data = response.data;
  //      new_data.forEach((ele) => {
  //       ele.thumbnail_image = `${
  //        RunTimeConstant.SERVICEURL.CS
  //       }assert/image?id=${encodeURIComponent(ele.AssetPath + ele.Image)}`;
  //       exist_data.push(ele);
  //      });
  //      set_Suggest_Course_Data(exist_data);
  //     } else {
  //      toast.dismiss();
  //      toast(response.message);
  //     }
  //    })
  //    .catch((error) => {
  //     set_Is_Network_Error(true);
  //    });
  //  };

  // Updated getCourseDetails function to accept a course ID
  const getCourseDetails = (courseID) => {
    return new Promise((resolve, reject) => {
      let payload = {
        method: 'post',
        baseURL: Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE,
        url: API_URL.getCourseById,
        data: JSON.stringify({
          _id: courseID,
          cc: session_details?.cc,
          page: 1,
          limit: 1,
        }),
      };

      ApiRequestMethod(payload)
        .then((response) => {
          console.log(`getCourseDetails response for ID`, courseID, response);
          if (response.status === 200) {
            resolve(response.data);
            console.log("resolved")
          } else {
            reject(new Error(response.message));
          }
        })
        .catch((error) => {
          console.log("getCourseDetails error", error);
          reject(error);
        });
    });
  };


  /* get purchase course */
  const getPurchasedCourse = () => {
    set_Is_Loading(true);
    let payload = {
      baseURL: Configuration.BASEURL + Configuration.SERVICEURL.PURCHASE_SERVICE,
      url: `${API_URL.get_purchase_course}?UserID=${session_details?.sub}`,
    };

    ApiRequestMethod(payload)
      .then(async (response) => {
        console.log("getPurchasedCourse response inside my_courses", response);
        if (response.status === 200) {
          const new_data = response.data.data; // Array of course IDs
          console.log("new_data inside my_courses === ", new_data);

          const courseDetailsPromises = new_data.map((mycourse) => getCourseDetails(mycourse.Course.CourseID));

          console.log("courseDetailsPromises -- ", courseDetailsPromises)

          const courseDetailsArray = await Promise.all(courseDetailsPromises);

          console.log("courseDetailsArray -- ", courseDetailsArray)

          let exist_data = [];
          courseDetailsArray.forEach((ele) => {
            const updatedCourse = {
              ...ele,
              thumbnail_image: `${Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE}assert/image?id=${encodeURIComponent(
                ele.AssetPath + ele.Image
              )}`,
              IsPurchase: true
            };
            exist_data.push(updatedCourse);
          });

          console.log("purchase course data ==== ", exist_data)
          set_My_Course_Data(exist_data);
          //  dispatch(setTrendingCourses(exist_data));
          set_Is_Loading(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          dispatch(showbanner({ showbanner: true }));
        }
        else {
          set_Is_Loading(false);
          set_Is_Network_Error(true);
        }

      });
  };

  //  const getCourseProgress = () => {
  //   set_Is_Loading(true);
  //   let payload = {
  //    url: API_URL.getCourseProgress,
  //    data: {
  //     UserID: token,
  //    },
  //   };
  //   PostMethod(payload)
  //    .then(async (response) => {
  //     console.log("getCourseProgress response", response);
  //     if (response.success) {
  //      let exist_data = [];
  //      const new_data = response.data;
  //      new_data.forEach((ele) => {
  //       ele.thumbnail_image = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(
  //        ele.AssetPath + ele.Image
  //       )}`;
  //       exist_data.push(ele);
  //      });
  //      set_My_Course_Data(exist_data);
  //      set_Is_Loading(false);
  //     } else {
  //      toast.dismiss();
  //      toast(response.message);
  //      set_Is_Loading(false);
  //     }
  //    })
  //    .catch((error) => {
  //     set_Is_Network_Error(true);
  //     set_Is_Loading(false);
  //    });
  //  };

  useEffect(() => {
    // getCourseProgress();
    getPurchasedCourse()
  }, []);

  //  useEffect(() => {
  //   dispatch(tabId({ tab_Id: 1 }));
  //  }, []);


  const handleClick = (selectedCourseID) => {
    navigate(`/mycourse_video/${selectedCourseID}`)
    //   const dataString = JSON.stringify(selectedCourseID);
    //   console.log("course purchased data id ====== ", dataString);
    //   const queryStringified = queryString.stringify({ data: dataString });
    //   navigate(`/courseContent?${queryStringified}`);
  };

  if (is_Loading) {
    return <LoaderView isLoading={is_Loading} />;
  }

  if (is_Network_Error) {
    return <NetworkError callBack={() => {
      // set_Is_Network_Error(false);
      getPurchasedCourse();
    }
    } />;
  }

  return (
    <div className="myCourse_Container">
      {my_Course_Data.length ? (
        <>
          <div className="myCourse_Title_View" >
            <div className="myCourse_Title">My Courses</div>
          </div>

          <div className="myCourse_Body_Container">
            {my_Course_Data.map((my_crs_data, ind) => {
              return (
                <MyCourseCardView key={ind} my_crs_data={my_crs_data} />
              );
            })}
          </div>


          {suggest_Course_Data?.length ? (
            <>
              <div className="horizontal_LineStyle" />

              <div className="suggest_Course_Title">
                Our Suggestions Because You Complete{" "}
                <span style={{ fontWeight: "700", color: "var(--primary-color)" }}>"Course 1"</span>
              </div>
              <div className="course_Direction">
                {suggest_Course_Data.map((ele, ind) => {
                  return (
                    <div key={ind} className="course_Container">
                      <div className="card_View">
                        <div className="course_Image_View">
                          <img
                            src={ele.thumbnail_image}
                            crossOrigin="anonymous"
                            className="course_Image_Size"
                          />
                        </div>

                        <div className="course_Sub_Name">{ele?.Subject}</div>
                        <div className="course_Name">{ele.CourseName}</div>

                        <div
                          className="rating_Like_Direction"
                          onClick={() => handleClick(ele?._id)}
                        >
                          <div className="price_Text">{"AED" + " " + ele?.Price}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className='alternate-my-course-view'>
          <p className='alternate-my-course-heading-text'>My Course is empty</p>
          <div className='alternate-my-course-explore-our-courses-button' onClick={() => navigate('/')}>Explore now</div>
        </div>
      )
      }
    </div >
  );
};

export default MyCoursesView;
