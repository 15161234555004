import "./App.css";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiRequestMethod } from "../src/api/request/Api_Calls";
import { API_URL } from "../src/api/URL/Api_Route";
import { sessionDetails, LogIn } from "./redux/action";
import toast, { Toaster } from "react-hot-toast";
import { RouterProvider } from "./router_container/routers";
import AOS from "aos";
import "aos/dist/aos.css";
import LoaderView from "./CommonComponents/LoaderComponet/View/LoaderView";
import SessionExpiredView from "./CommonComponents/SessionExpiredBanner/View/SessionExpiredView";
import Configuration from "./config/configuration";

toast.success = (msg) => toast(msg, { icon: null });
toast.error = (msg) => toast(msg, { icon: null });

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { token, showbanner } = useSelector((state) => state.applicationState);

  // Fetch Token on App Load
  const initialFetchToken = useCallback(async () => {
    try {
      setIsLoading(true)
      const payload = {
        baseURL: Configuration.BASEURL + Configuration.SERVICEURL.CACHE_MANAGEMENT_SERVICE,
        url: `${API_URL.getSession}`,
      };
      const res = await ApiRequestMethod(payload);
      if (res?.status === 200 && res.data?.status) {
        dispatch(sessionDetails({ session_details: res.data }));
        dispatch(LogIn({ token: res.data.sub }));
        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)
      console.error("Error fetching session details:", err?.response?.status || err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);



  // Initialize AOS and Fetch Token
  useEffect(() => {
    AOS.init({ duration: 500, easing: "ease-in-out", once: true, mirror: false });
    initialFetchToken();
  }, [initialFetchToken]);


  if (isLoading) {
    return <LoaderView isLoading={isLoading} />;
  }

  console.log("Show Banner in App.js====>", showbanner)
  if (showbanner) {
    return <SessionExpiredView />;
  }

  // {!shouldShowHeader 


  // if(!token){
  //   return(

  //     <SessionExpiredView/>
  // )}

  console.log("showbanner")

  return (
    <div className="App">
      <Toaster
        toastOptions={{
          className: "custom-toast",
          duration: 5000,
          position: "top-center",
        }}
      />
      {/* {showbanner ? <SessionExpiredView/>: <></>} */}
      <RouterProvider token={token} />
    </div>
  );
}

export default App;
