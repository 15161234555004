import React, { useState } from "react";
import '../Styles/CoursePriceCard.css'
/* helper */
import CheckWindowSize from "../../../helper/screen_size_check"
/* icons */
import CartSvg from '../../../new_assets/Svg/CartSvg'
import Video_Svg from "../../../assets/svg/Video_Svg";
import Document_Svg from "../../../assets/svg/Document_Svg";
import Assessment_Svg from "../../../assets/svg/Assessment_Svg";
import { API_URL } from "../../../api/URL/Api_Route";
import toast from "react-hot-toast";
import { addToCart, cartItemCount } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import LoaderView from "../../LoaderComponet/View/LoaderView";
import NetworkError from "../../../component/javascript/Network_Error";
import { useNavigate } from "react-router-dom";
import Configuration from "../../../config/configuration";
import { ApiRequestMethod } from "../../../api/request/Api_Calls";
import { CountryToCurrency } from "../../../helper/country_to_currency";

const CoursePriceCard = (props) => {

  console.log("props====>", props)

  const { goToPayment } = props
  const [isLoading, SetIsLoading] = useState(false)
  const [isNetworkError, setIsNetworkError] = useState(false)
  const { isSmallScreen } = CheckWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart_item_count, session_details, cartDetails } = useSelector(
    (state) => state.applicationState
  );

  console.log("cartDetails --- 9 ", cartDetails)

  const courseId = props?.courseId
  console.log("cardTimeCount===>", cart_item_count)
  console.log("session_details===>", session_details)

  const add_Cart = () => {
    let payload = {
      method: 'post',
      baseURL: Configuration.BASEURL + Configuration.SERVICEURL.PURCHASE_SERVICE,
      url: API_URL.add_to_cart,
      data: {
        UserID: session_details?.sub,
        CourseID: courseId,
      },
    };

    ApiRequestMethod(payload)
      .then(async (res) => {
        console.log("add_Cart response", res);
        console.log("response.statusText ====", res?.statusText)
        if (res?.status === 200) {
          dispatch(addToCart(courseId));
          dispatch(cartItemCount({ cart_item_count: cart_item_count + 1 }));
          toast("Item added to cart");
        } else {
          console.log(res)
          // toast("Error adding item into cart");
        }
      })
      .catch((error) => {
        console.log("Error in Add to Cart API ====>", error)
        SetIsLoading(false);
        // setIsNetworkError(true);
      });
  };


  if (isLoading) {
    return <LoaderView isLoading={isLoading} />;
  }

  if (isNetworkError) {
    return <NetworkError callBack={() => { }} />;
  }




  return (

    <div className="course-price-card" >
      <div className="price">

        {CountryToCurrency(session_details?.cc)} {props?.price?.[CountryToCurrency(session_details?.cc)]}
      </div>
      <div className="button-container">
        <div className="add-cart-btn" onClick={() => (cartDetails?.includes(courseId) ? navigate("/cart") : add_Cart())}>
          {isSmallScreen ? <CartSvg height={25} width={25} className='cart-svg-add-cart-btn-mobile-view' /> : <></>}
          &nbsp; &nbsp;
          {cartDetails?.includes(courseId) ? "Go to Cart" : "Add Cart"}
        </div>


        <div className="buy-now-btn" onClick={() => {
          goToPayment();
        }}>
          {isSmallScreen ? <p style={{ fontSize: "20px", fontWeight: "600" }}>
            {CountryToCurrency(session_details?.cc)} {props?.price?.[CountryToCurrency(session_details?.cc)]}</p> : <></>}
          Buy Now
        </div>
      </div>
      <div className="includes">
        <p>This course includes:</p>
        <div className="item">
          <span className="item-icon">
            <Video_Svg color={"rgba(13, 95, 103, 1)"} width={"26"} height={"24"} />
          </span> 20 Videos
        </div>
        {/* <div className="item">
          <span className="item-icon">
          <Document_Svg color={"rgba(13, 95, 103, 1)"} width={"26"} height={"24"} />
          </span> 20 Documents
        </div>
        <div className="item">
          <span className="item-icon">
          <Assessment_Svg color={"rgba(13, 95, 103, 1)"} width={"26"} height={"24"} />
          </span> 20 Assessments
        </div> */}
      </div>
    </div>

  );
}

export default CoursePriceCard;



