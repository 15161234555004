import * as React from "react";
const CartSvg = (props) => (
  <svg
    width={24}
    height={22}
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.1703 17.6428H5.288C5.08506 17.6428 4.88212 17.5075 4.74683 17.3722C4.61153 17.2369 4.61153 16.9664 4.67918 16.7634L5.96447 13.5164L4.67918 1.40762H0.552734V0.0546875H5.288C5.62623 0.0546875 5.89682 0.325274 5.96447 0.663507L7.3174 13.5164C7.3174 13.6517 7.3174 13.7193 7.24975 13.8546L6.3027 16.2899H20.8467L20.1703 17.6428Z"
      fill="#21373F"
    />
    <path
      d="M6.77681 14.2617L6.50622 12.9087L22.2002 9.66168V4.11465H5.96505V2.76172H22.8767C23.2826 2.76172 23.5532 3.03231 23.5532 3.43819V10.2029C23.5532 10.5411 23.3502 10.8117 23.012 10.8793L6.77681 14.2617ZM20.8473 21.7028C19.3591 21.7028 18.1414 20.4851 18.1414 18.9969C18.1414 17.5087 19.3591 16.2911 20.8473 16.2911C22.3355 16.2911 23.5532 17.5087 23.5532 18.9969C23.5532 20.4851 22.3355 21.7028 20.8473 21.7028ZM20.8473 17.644C20.1032 17.644 19.4944 18.2528 19.4944 18.9969C19.4944 19.741 20.1032 20.3498 20.8473 20.3498C21.5914 20.3498 22.2002 19.741 22.2002 18.9969C22.2002 18.2528 21.5914 17.644 20.8473 17.644ZM4.61212 21.7028C3.12389 21.7028 1.90625 20.4851 1.90625 18.9969C1.90625 17.5087 3.12389 16.2911 4.61212 16.2911C6.10034 16.2911 7.31798 17.5087 7.31798 18.9969C7.31798 20.4851 6.10034 21.7028 4.61212 21.7028ZM4.61212 17.644C3.868 17.644 3.25918 18.2528 3.25918 18.9969C3.25918 19.741 3.868 20.3498 4.61212 20.3498C5.35623 20.3498 5.96505 19.741 5.96505 18.9969C5.96505 18.2528 5.35623 17.644 4.61212 17.644Z"
      fill="#21373F"
    />
  </svg>
);
export default CartSvg;
