import '../Styles/ResetPasswordScreenStyles.css'
import { Formik } from "formik";
import { passworddata, ValidationSchema } from "../Model/ResetPasswordScreenModel";
import { authvideocarddata } from '../../../CommonComponents/AuthImageCard/Model/AuthImageCardModel'
import NetworkError from './../../../component/javascript/Network_Error';
import Passwordfield from './../../../CommonComponents/PasswordboxComponent/View/PasswordfieldView';
import AuthImageCardView from '../../../CommonComponents/AuthImageCard/View/AuthImageCardView';
import BackgroundImage from '../../../CommonComponents/AuthBackgroundImage/View/AuthBackgroundImage';
import AuthButton from '../../../CommonComponents/AuthButton/View/AuthButton';
import HeaderSectionView from '../../../CommonComponents/HeaderComponent/View/HeaderSectionView';
import AuthMainContainerView from '../../../CommonComponents/AuthMainContainer/View/AuthMainContainerView';
import FormContainerView from '../../../CommonComponents/FormContainerComponent/View/FormContainerView';
import ResetPasswordScreenController from '../Controller/ResetPasswordScreenController';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import LoaderView from '../../../CommonComponents/LoaderComponet/View/LoaderView';

const ResetPasswordScreenView = () => {
  const location = useLocation();
  let queryParams = queryString.parse(location.search);

  const {
    is_small_screen,
    is_landscape,
    is_network_error,
    isLoading,
    set_is_network_error,
    setIsLoading,
    onSubmit,
    forgetPageNavigation,
    signupPageNavigation,
    passwordIconType,
    passwordEyeIcon,
    confirmPasswordIconType,
    confirmPasswordEyeIcon,
    passwordEyeIconChange,
    confirmPasswordEyeIconChange,
  } = ResetPasswordScreenController();

  if (is_network_error) {
    return <NetworkError callBack={() => set_is_network_error(false)} />;
  }

  return (
    <>
      <LoaderView isLoading={isLoading} callBack={() => setIsLoading(false)} />
      <BackgroundImage>
        <Formik
          initialValues={{ new_password: "", confirm_password: "", user_email: queryParams.email || '' }}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <div className='signin-screen-view-outLine' >
              <HeaderSectionView />
              <AuthMainContainerView>
                <div className='signin-left-container'>
                  <div className='signin-image-grid'>
                    {
                      authvideocarddata.map((data, index) => (
                        <AuthImageCardView key={index} image={data.image} text={data.text} />
                      ))
                    }

                  </div>
                </div>

                <div className='signin-right-container'>
                  <FormContainerView>
                    <div className='form-text-container'>
                      <span className='signin-in-screen-signInText'>Reset Password</span>
                    </div>
                    <div className='form-text-field-container'  >
                      <div className='form-text-field-wrapup-container' >
                        <div style={{ height: '85%', display: 'flex', flexDirection: 'column' }}>

                          {passworddata.map((values, index) => (
                            <Passwordfield
                              key={index}
                              error={errors[values.name]}
                              touched={touched[values.name]} handleSubmit={handleSubmit}
                              setFieldValue={setFieldValue}
                              type={values.type}
                              name={values.name}
                              placeholder={values.placeholder}
                              label={values.label}
                              config={values.MAX_LENGTH}
                              passwordIconType={values.name === "new_password" ? passwordIconType : confirmPasswordIconType}
                              passwordEyeIconChange={values.name === "confirm_password" ? confirmPasswordEyeIconChange : passwordEyeIconChange}
                              height='50'
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='form-text-field-button-container'>
                      <AuthButton text='Submit' action={handleSubmit} bgcolor='var(--primary-color)' color='var(--inside-primary-color)' />
                    </div>
                  </FormContainerView>
                </div>
              </AuthMainContainerView>
            </div>
          )}
        </Formik>
      </BackgroundImage>
    </>
  )
}
export default ResetPasswordScreenView;