import ProgressBar from "@ramonak/react-progress-bar";
import Video_Svg from "../../../assets/svg/Video_Svg";
import thumbnail_image from "../../../new_assets/img/my_course_img1.png"
import '../Styles/MyCourseCardStyles.css'
import { useNavigate } from "react-router-dom";

const MyCourseCardView = (props) => {

    const navigate = useNavigate()

    const handleClick = (selectedCourseID) => {
        navigate(`/mycourse_video/${selectedCourseID}`)
        //   const dataString = JSON.stringify(selectedCourseID);
        //   console.log("course purchased data id ====== ", dataString);
        //   const queryStringified = queryString.stringify({ data: dataString });
        //   navigate(`/courseContent?${queryStringified}`);
    };

    const my_crs_data = props.my_crs_data

    console.log("my_crs_data -- ", my_crs_data)
    return (
        <div className="myCourse_Card_View">
            <div className="image_View">
                <img
                    // src={my_crs_data?.thumbnail_image}
                    src={thumbnail_image}
                    crossOrigin="anonymous"
                    className="img_Fit"
                />
            </div>

            <div className="my_Course_Card_Content_View">
                <div>
                    <div className="myCourse_SubjectName">{my_crs_data?.data?.Subject}</div>
                    <div className="myCourse_CourseName_Text">{my_crs_data?.data?.CourseName}</div>
                </div>

                {/* <div>
                    <div className="myCourse_Completion_Text">
                        {"Completion" + " " + Math.trunc(my_crs_data?.TotalCompletionPercentage) + "%"}
                    </div>
                    <div className="myCourse_Progress">
                        <ProgressBar
                            completed={Math.trunc(my_crs_data?.TotalCompletionPercentage)}
                            bgColor={"#21B573"}
                            height={"5%"}
                            width={"100%"}
                            customLabel=" "
                        />
                    </div>
                </div> */}

                {/* <div className="myCourse_Assets_Container">
                    <Video_Svg color={"var(--primary-color)"} height={"13"} width={"16"} />
                    <div className="myCourse_Assets_Text">
                        {my_crs_data?.TotalTopics} Videos
                    </div>

                    <div className="assest_Divider_Line" />

                    <div className="myCourse_Assets_Text" style={{ color: "#21B573" }}>
                        Completed {my_crs_data?.TotalVideoCompletion}
                    </div>

                    <div className="assest_Divider_Line" />

                    <div className="myCourse_Assets_Text" style={{ color: "#FF455B" }}>
                        Pending {my_crs_data?.TotalVideoPending}
                    </div>
                </div> */}
            </div>

            <div className="myCourse_Line_Style" />

            <div className="myCourse_Button_Container">
                <div className="myCourse_Button_View">
                    <div
                        className="myCourse_Button_Text"
                        onClick={() => handleClick(my_crs_data?.data?._id)}
                    >
                        Go to Course
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyCourseCardView;