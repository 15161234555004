import '../Styles/FormContainerStyles.css'
const FormContainerView = ({children})=>{
    return(
        <div className='form-container-common' >
            <div className='form-container-inside-wrap-up' >
            {children}
            </div>
        </div>
    )
}

export default FormContainerView;