import { setLocalStorage } from "../storage/localStorage";

//please add the application state here
const INITIAL_STATE = {
 token: null,
 showbanner:false,
 tab_Id: 0,
 cart_item_count: 0,
 user_details: {},
 session_details: {},
 cartDetails: [],
 course: {},
 purchased_course_details: [],
};

//please add the redux action here
const REDUX_ACTION = {
 WEB_INIT: "WEB_INIT",
 LOGIN: "LOGIN",
 LOGOUT: "LOGOT",
 TAB_ID: "TAB_ID",
 CART_ITEM_COUNT: "CART_ITEM_COUNT",
 USER_DETAILS: "USER_DETAILS",
 SESSION_DETAILS:"SESSION_DETAILS",
 SHOW_BANNER:"SHOW_BANNER",
 /* added by samir */
 ADD_TO_CART: "ADD_TO_CART",
 REMOVE_FROM_CART: "REMOVE_FROM_CART",
 SET_TRENDING_COURSES: "SET_TRENDING_COURSES",
 SET_PURCHASED_COURSES: "SET_PURCHASED_COURSES",
 UPDATE_COURSE_DETAILS: "UPDATE_COURSE_DETAILS",
 UPDATE_CHAPTER_TOPICS: "UPDATE_CHAPTER_TOPICS",

 START_PROGRESS: "START_PROGRESS",
 UPDATE_PROGRESS: "UPDATE_PROGRESS",
 COMPLETE_PROGRESS: "COMPLETE_PROGRESS",
};
const WebsiteInit = (details) => {
 return {
  type: REDUX_ACTION.WEB_INIT,
  token: details.token,
 };
};
//please add the dispatch action here
const LogIn = (details) => {
//  setLocalStorage("token", details?.token);
 return {
  type: REDUX_ACTION.LOGIN,
  token: details.token,
 };
};
const LogOut = () => {
//  setLocalStorage("token", null);
 return {
  type: REDUX_ACTION.LOGOUT,
  token: null,
 };
};

const tabId = (details) => {
 setLocalStorage("Tab_ID", details.tab_Id);
 return {
  type: REDUX_ACTION.TAB_ID,
  tab_Id: details.tab_Id,
 };
};

const cartItemCount = (details) => {
 return {
  type: REDUX_ACTION.CART_ITEM_COUNT,
  cart_item_count: details.cart_item_count,
 };
};

const userDetails = (details) => {
 return {
  type: REDUX_ACTION.USER_DETAILS,
  user_details: details.user_details,
 };
};

const sessionDetails =(details)=>{
 return{
  type:REDUX_ACTION.SESSION_DETAILS,
  session_details: details.session_details,
}
}

const addToCart = (itemId) => {
  return {
    type: REDUX_ACTION.ADD_TO_CART,
    itemId,
  };
};

const removeFromCart = (itemId) => {
  return {
    type: REDUX_ACTION.REMOVE_FROM_CART,
    itemId,
  };
};

const setTrendingCourses = (courses) => {
  return {
    type: REDUX_ACTION.SET_TRENDING_COURSES,
    courses,
  };
};

const updateCourseDetails = (courseId, chapters, topics) => {
  return {
    type: REDUX_ACTION.UPDATE_COURSE_DETAILS,
    payload: { courseId, chapters, topics },
  };
};

const updateChapterTopics = (courseId, chapterId, topics) => ({
  type: REDUX_ACTION.UPDATE_CHAPTER_TOPICS,
  payload: { courseId, chapterId, topics: Array.isArray(topics) ? topics : [] },
});

const setPurchasedCourses = (purchased_course_details) => {
  return {
    type: REDUX_ACTION.SET_PURCHASED_COURSES,
    purchased_course_details,
  };
};

const showbanner = (status) => {
  console.log("Statusinredux",status)
  return {
    type: REDUX_ACTION.SHOW_BANNER,
    showbanner: status, 
  };
};


// const startProgress = (courseId, taskId) => {
//   return {
//     type: REDUX_ACTION.START_PROGRESS,
//     payload: { courseId, taskId },
//   };
// };

// const updateProgress = (courseId, taskId, progress) => {
//   return {
//     type: REDUX_ACTION.UPDATE_PROGRESS,
//     payload: { courseId, taskId, progress },
//   };
// };

// const completeProgress = (courseId, taskId) => {
//   return {
//     type: REDUX_ACTION.COMPLETE_PROGRESS,
//     payload: { courseId, taskId },
//   };
// };

export {
 INITIAL_STATE,
 REDUX_ACTION,
 setTrendingCourses,
 setPurchasedCourses,
 updateCourseDetails,
 updateChapterTopics,
//  startProgress,
//  updateProgress,
//  completeProgress,
 addToCart,
 removeFromCart,
 LogIn,
 LogOut,
 WebsiteInit,
 tabId,
 cartItemCount,
 userDetails,
 sessionDetails,
 showbanner
};
