import '../Styles/PasswordfieldStyles.css'
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Passwordfield = ({
    error,
    touched,
    handleSubmit,
    setFieldValue,
    type,
    placeholder,
    name,
    label,
    passwordEyeIconChange,
    passwordEyeIcon,
    passwordIconType,
    config,
    height
  }) => {
    return (
      // <div>
      //   <div className='passwordBox' style={{ position: "relative" }}>
      //             <label className='field'>{label}</label>
      //             <span className='requiredFieldSymbol'>*</span>
      //             <br />
      //             <input
      //               type={passwordIconType}
      //               placeholder={placeholder}
      //               name={name}
      //               className={`${'fieldInput-pw'} ${
      //                 error&& touched ? 'fieldInputError' : ""
      //               }`}
      //               onChange={(val) => {
      //                 setFieldValue(name, val.target.value);
      //               }}
      //               onKeyDown={(e) => {
      //                 if (e.key === "Enter") {
      //                   e.preventDefault();
      //                   handleSubmit();
      //                 }
      //               }}
      //               maxLength={config}
      //             />
      //             <span onClick={passwordEyeIconChange} className='eyeIcon'>
      //               {passwordIconType === "password" ?   <FaEyeSlash size={20}/>:<FaEye size={20}/>}
      //               </span>
      //             <div className='formikErrorText'>
      //               <div>
      //                 {error && touched && error && (
      //                   <AiOutlineExclamationCircle size={14} />
      //                 )}
                      
      //               </div>
      //               <div>{error && touched && error}</div>
                    
      //             </div>
      //           </div>
      //         </div>



      <div style={{width:'100%',height:`${height}%`,display:'flex',flexDirection:'column'}}>
      <div style={{height:'30%',width:'100%'}}>
      <label className='textfield-field-tf' >{label} <span className='requiredFieldSymbol-tf'>*</span></label>
      </div>
      <div style={{width:'100%',height:'50%',position: "relative" }}>
          
              
               <input style={{height:'100%',width:'100%'}}
                    type={passwordIconType}
                     placeholder={placeholder}
                     name={name}
                     className={`${'fieldInput-tt'} ${error && touched ? 'fieldInputError' : ""}`}
                     onChange={(val) => {
                       setFieldValue(name, val.target.value);
                     }}
                     onKeyDown={(e) => {
                      if (e.key === " ") {
                        e.preventDefault(); // Prevent space input
                      } else if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit(); // Call submit function on Enter
                      }
                    }}
                  
                     maxLength={config}
                   />
               <span onClick={passwordEyeIconChange} className='eyeIcon-pw'>
                     {passwordIconType === "password" ?   <FaEyeSlash size={20}/>:<FaEye size={20}/>}
                     </span>
        </div>
        <div className='formikErrorText-tf' >
         <div style={{display:'block'}}>{error && touched && error && <AiOutlineExclamationCircle size={14} />}</div>
         <div style={{display:'block'}}>{error && touched && error}</div>
        </div> 
     </div>
    )}

export default Passwordfield;
