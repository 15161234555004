import { useEffect, useState } from 'react';
import '../Styles/PurchasedTopicDetailBoxStyles.css';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showbanner } from '../../../redux/action';
import { API_URL } from '../../../api/URL/Api_Route';
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import Upward_Svg from '../../../assets/svg/Upward_Svg';
import DropdowniconSvg from '../../../assets/svg/Dropdownicon_Svg';
import Configuration from '../../../config/configuration';
import PurchasedChapterBoxView from './../../PurchasedChapterBox/View/PurchasedChapterBoxView';
import NetworkError from './../../../component/javascript/Network_Error';
import LoaderView from './../../LoaderComponet/View/LoaderView';
import Dropdownicon_Svg from '../../../assets/svg/Dropdownicon_Svg';

const PurchasedTopicDetailBoxView = (props) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [expandedChapter, setExpandedChapter] = useState(null);
    const { courseId } = useParams();
    const {set_is_network_error} = props;

    const [isNetworkError, setNetworkError] = useState(false)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            if (!courseId) return;
            const payload = {
                method: "post",
                url: API_URL.getChapterByCourse,
                baseURL: Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE,
                data: { "_id": courseId },
            };

            ApiRequestMethod(payload)
                .then((res) => {
                    if (res?.status === 200 && res?.data?.data?.length) {
                        setData(res.data.data);
                        setExpandedChapter(res.data.data[0]?._id);
                        setLoading(false)
                    }
                })
                .catch((err) => {
                    if (err?.response?.status === 404) {
                        dispatch(showbanner({ showbanner: true }));
                        setLoading(false)
                    } else {
                        console.error("Get topics detail Data Fetching Error:", err);
                        setLoading(false)
                        set_is_network_error(true)
                    }
                });
        };
        fetchData();
    }, [courseId]);



    const handleOption = (chapterId) => {
        setExpandedChapter(prev => (prev === chapterId ? null : chapterId));
    };

    // if (isNetworkError) {
    //     return <NetworkError callBack={() => isNetworkError(false)} />;
    // }

    // if (isLoading) {
    //     return <LoaderView isLoading={isLoading} />;
    // }

    return (
        <>
            {data.length ? (
                <div style={{ display: "flex", width: "100%", height: "100%", flexDirection: "column", alignItems: "flex-start" }}>
                    {data.map((value, index) => (
                        <div key={value._id} style={{ width: "100%" }}>
                            {/* Topic Title */}
                            <div

                                className="p-topic-title-container-field"
                                onClick={() => handleOption(value._id)}
                                style={{ cursor: 'pointer' }}
                            >
                                <div className="p-topic-title-container-field-wrapup">
                                    <div style={{ width: '90%', height: "100%", display: 'flex', alignItems: 'center' }}>
                                        <span className="p-topic-styling-details">
                                            {index + 1} &nbsp; {value?.ChapterName}
                                        </span>
                                    </div>
                                    <div style={{ width: '10%', height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {expandedChapter === value._id ? (
                                            <Upward_Svg stoke={"rgba(151, 151, 151, 1)"} />
                                        ) : (
                                            <Dropdownicon_Svg stoke={"rgba(151, 151, 151, 1)"} />
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Expandable Content with Scroll Ref */}
                            {expandedChapter === value._id && (
                                <div className='p-topic-box-container-extend'>
                                    <PurchasedChapterBoxView ChapterId={value._id} indexvalue={index} setVideoData={props.setVideoData} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <div style={{ display: "flex", width: "100%", height: "100%", flexDirection: "column", alignItems: "flex-start" }}>
                    <div className='p-topic-title-container-field-p'></div>
                </div>
            )}
        </>
    );
};

export default PurchasedTopicDetailBoxView;
