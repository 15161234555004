import RunTimeConstant from "../../config/RuntimeConfig";
import { apiRequest } from "../Interceptor";
import { API_HEADER, API_TIMEOUT } from "./Config";

 export const ApiRequestMethod = async (payload) => {
  console.log("Api Request Method Payload===> -- ", payload)
  try {
    if (!payload.baseURL) throw new Error("baseURL is required");
    if (!payload.url) throw new Error("url is required");

    return await apiRequest({
      method: payload.method || "get",
      url: payload.url,
      baseURL: payload.baseURL,
      timeout: API_TIMEOUT,
      headers: payload.header || API_HEADER,
      data: payload.data,
      withCredentials: true,
    });
  } catch (err) {
    if (err.code === "ECONNABORTED") {
      console.error("Request Timeout: The API request took too long to respond.");
      throw new Error("Request Timeout: Please try again later.");
    }
    console.error("API Request Error:", err.message || err);
    throw err;
  }
};