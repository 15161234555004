export const API_URL = {
    //  login: "users/login",
    login: "/identity/authenticate",
    authorize: "/identity/authorize",
    getSession: "/cache/session_details",
    getCourseById: "/course/by_id",
    getSubjects: "/course/get_subjects",
    getCourseBySubject: "/course/get_course_by_subject",
    getChapterByCourse: "/chapter/get_chapterByCourse",
    getTopicByChapter: "/topic/topic_by_Chapter",
    getVideoByTopic: "/video/video_by_topic",
    usersAccount: "/users/account",
    add_to_cart: "/cart/add_cart",
    //  user_registration: "users/user_registeration",
    user_registration: "/users/user_registeration",
    otpverify: "/users/otp_verify",
    resetPassword: "users/reset_password",
    forgetPassword: "users/forget_password",
    resend_otp: "users/resend_otp",
    logout: "/identity/logout",
    deleteExistingSession: "/cache/delete_existing_session",
    updateCart: "users/update_cart",
    userCart: "users/user_cart",
    updatePurchase: "users/update_purchase",
    multiplePurchase: "users/multiple_purchase",
    letterPad: "letterpad/create_letter_pad",
    //  getSubjects: "course/get_subjects",

    trendingCourse: "course/trending_course",

    //  getChapterByCourse: "course/get_course_completion",
    //  getTopicByChapter: "course/get_chapter_completion",


    getCourseProgress: "student_course_progress/get_course_progress",
    searchCourse: "course/search",
    readTestimonial: "testimonial/read_testimonial",

    editProfile: "users/edit_account",
    update_video_completion: "student_course_progress/update_video_completion",
    update_last_video_view_point: "student_course_progress/update_last_video_view_point",
    read_individual_question: "questions/read_individual_question",
    take_assessment: "assessment/take_assessment",
    submit_question: "assessment/submit_question",
    submit_assessment: "assessment/submit_assessment",
    attend_assessment: "assessment/attend_assessment",

    get_from_cart: "cart/get_cart",
    remove_from_cart: "cart/remove_cart",
    get_purchase_course: "purchase/get_purchased_course",
    get_tax_details: "tax/gettax"
};

export const MESSAGING_SERVICE_ROUTE = {
    CREATE_ORDER: "publish/set_order_pending",
};
