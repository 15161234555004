import { useEffect, useState } from 'react';
import '../Styles/TopicDetailsBoxStyles.css';
import ChapterDetailsBoxView from '../../ChapterDetailsBoxContainer/View/ChapterDetailsBoxView';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showbanner } from '../../../redux/action';
import { API_URL } from '../../../api/URL/Api_Route';
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import Upward_Svg from '../../../assets/svg/Upward_Svg';
import DropdowniconSvg from '../../../assets/svg/Dropdownicon_Svg';
import Configuration from '../../../config/configuration';
import NetworkError from '../../../component/javascript/Network_Error';
import LoaderView from '../../LoaderComponet/View/LoaderView';
import Dropdownicon_Svg from '../../../assets/svg/Dropdownicon_Svg';

const TopicDetailsBoxView = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [expandedChapter, setExpandedChapter] = useState(null);
  const { courseId } = useParams();
  const [isLoading, setLoading] = useState(false)
  const {set_is_network_error} = props;

  console.log("Course Id===>", courseId)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(false)
      if (!courseId) return;
      const payload = {
        method: "post",
        url: API_URL.getChapterByCourse,
        baseURL: Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE,
        // baseURL: "http://192.168.123.134:6004/",
        data: { "_id": courseId },
      };

      ApiRequestMethod(payload)
        .then((res) => {
          if (res?.status === 200 && res?.data?.data?.length) {
            setData(res.data.data);
            setExpandedChapter(res.data.data[0]?._id);
            setLoading(false)
          }
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            dispatch(showbanner({ showbanner: true }));
            setLoading(false)
          } else {
            console.error("Get topics detail Data Fetching Error:", err);
            set_is_network_error(true)
            setLoading(false)
          }
        });
    };
    fetchData();
  }, [courseId]);


  const handleOption = (chapterId) => {
    setExpandedChapter(prev => (prev === chapterId ? null : chapterId));
  };

  // if (isNetworkError) {
  //   return <NetworkError callBack={() => isNetworkError(false)} />;
  // }

  // if (isLoading) {
  //   return <LoaderView isLoading={isLoading} />;
  // }

  return (
    <>
      {data.length ? (
        <div style={{ display: "flex", width: "100%", height: "100%", flexDirection: "column", alignItems: "flex-start" }}>
          {data.map((value, index) => (
            <div key={value._id} style={{ width: "100%" }}>
              {/* Topic Title */}
              <div
                className="topic-title-container-field"
                onClick={() => handleOption(value._id)}
                style={{ cursor: 'pointer' }}
              >
                <div className="topic-title-container-field-wrapup">
                  <div style={{ width: '90%', height: "100%", display: 'flex', alignItems: 'center' }}>
                    <span className="topic-styling-details">
                      {index + 1} &nbsp; {value?.ChapterName}
                    </span>
                  </div>
                  <div style={{ width: '10%', height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {expandedChapter === value._id ? (
                      <Upward_Svg stoke={"rgba(151, 151, 151, 1)"} />
                    ) : (
                      <Dropdownicon_Svg stoke={"rgba(151, 151, 151, 1)"} />
                    )}
                  </div>
                </div>
              </div>

              {/* Expandable Content with Scroll Ref */}
              {expandedChapter === value._id && (
                <div className='topic-box-container-extend'>
                  <ChapterDetailsBoxView ChapterId={value._id} indexvalue={index} setVideoData={props.setVideoData} />
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: "flex", width: "100%", height: "100%", flexDirection: "column", alignItems: "flex-start" }}>
          <div className='topic-title-container-field-p'></div>
        </div>
      )}
    </>
  );
};

export default TopicDetailsBoxView;
