import * as React from "react";
const MenuSvg = (props) => (
  <svg
    width={53}
    height={30}
    viewBox="0 0 53 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={4} y={4} width={45} height={3} fill={props.fill} />
    <rect x={4} y={13} width={26} height={3} fill={props.fill} />
    <rect x={4} y={22} width={45} height={3} fill={props.fill} />
  </svg>
);
export default MenuSvg;
