import CryptoJS from "crypto-js";
import forge from 'node-forge';

/* RSA */
const publicKey = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsVnzMfIHH7VOdV57SY9E
3IOPvyBTsXN7MgWwqzQEjwokogCBDwlHZ/kPBmQ2FqA5VYJCUVtr7lBwo25+9G2T
reyLPYL2jSIPXeY9XaMeDSlxng/dlfEetXw0bawfjLHaY4PwCFb7ORGiz6/1qsLy
lDFTiN7NayMmtaNIXzgkcXXAH9SYbX9IQjxvLa0dQWszhfN+Bdu2Qklc0soRTUVt
HXSEDE67klo99Irdz/WsuEDd5Xg0dnO3IOzWz6Pe25e2phN288B1ntgCP0L+UwFq
OJlCVUN4gz88Ors7mOZQNiy5MLZdYGlLGXzQ+mQvR1lGypa5itEtVioX4korzxg7
gwIDAQAB
-----END PUBLIC KEY-----
  `;
export const encryptPassword = (password) => {
    const rsa = forge.pki.publicKeyFromPem(publicKey);
    const encrypted = rsa.encrypt(password, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
    });
    return forge.util.encode64(encrypted);
  };

/* AES */
// const secretKey = "cognitived-secure-key"; // Store securely or fetch from config
// export const encryptPassword = (password) => {
//   const encrypted = CryptoJS.AES.encrypt(password, secretKey).toString();
//   return encodeURIComponent(encrypted); // Encode the encrypted password
// };