import React from 'react';
import '../Styles/OtpScreenStyles.css';
import BackgroundImage from '../../../CommonComponents/AuthBackgroundImage/View/AuthBackgroundImage';
import HeaderSectionView from '../../../CommonComponents/HeaderComponent/View/HeaderSectionView';
import AuthMainContainerView from '../../../CommonComponents/AuthMainContainer/View/AuthMainContainerView';
import FormContainerView from '../../../CommonComponents/FormContainerComponent/View/FormContainerView';
import { authvideocarddata } from '../../../CommonComponents/AuthImageCard/Model/AuthImageCardModel';
import AuthImageCardView from '../../../CommonComponents/AuthImageCard/View/AuthImageCardView';
import AuthButton from '../../../CommonComponents/AuthButton/View/AuthButton';
import { Formik } from 'formik';
import OtpInputsBoxView from '../../../CommonComponents/OtpInputs/View/OtpInputsBoxView';
import NetworkError from '../../../component/javascript/Network_Error';
import OtpScreenController from '../Controller/OtpScreenController';
import { ValidationSchema } from '../Model/OtpScreenModel';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import LoaderView from '../../../CommonComponents/LoaderComponet/View/LoaderView';

const OtpScreenView = () => {

  const location = useLocation();
  let queryParams = queryString.parse(location.search);
  const { isLoading, is_network_error, setIsLoading, onSubmit, resendOtp, navigate, editEmailHandler, set_is_network_error } = OtpScreenController(queryParams?.navigate_from);
  if (is_network_error) {
    return <NetworkError callBack={() => set_is_network_error(false)} />;
  }

  return (
    <>
      <LoaderView isLoading={isLoading} callBack={() => setIsLoading(false)} />
      <BackgroundImage>

        <Formik
          initialValues={{ user_email: queryParams.email || '', user_otp: '' }}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (

            <div className='otp-screen-view-outLine'>
              <HeaderSectionView />
              <AuthMainContainerView>
                <div className='otp-left-container'>
                  <div className='otp-page-image-grid'>
                    {
                      authvideocarddata.map((data, index) => (
                        <AuthImageCardView key={index} image={data.image} text={data.text} />
                      ))
                    }

                  </div>
                </div>

                <div className='otp-right-container'>
                  <FormContainerView>
                    <div className='otp-form-text-container'>
                      <span className='otp-in-screen-otpText'>OTP Verification</span>
                    </div>
                    <div className='otp-form-text-field-container'>
                      <div className="instruction-otp">
                        Enter the OTP to the respected mail is <br />
                        <strong style={{ color: "rgba(60, 60, 60, 1)" }}>{queryParams.email}</strong>
                      </div>
                      <OtpInputsBoxView value={values.user_otp} error={errors.user_otp} touched={touched.user_otp} onChange={(val) => setFieldValue('user_otp', val)} resendOtp={resendOtp} email={queryParams.email} />
                    </div>
                    <div className='otp-form-text-field-button-container' >
                      <AuthButton
                        text="Verify & Proceed"
                        action={handleSubmit}
                        bgcolor="var(--primary-color)"
                        color="var(--inside-primary-color)"
                      />

                      <div className="editEmail-otp"><span className="editLink-otp" onClick={() => editEmailHandler(queryParams)}>Edit the email </span></div>
                    </div>

                  </FormContainerView>
                </div>

              </AuthMainContainerView>
            </div>


          )}
        </Formik>

      </BackgroundImage>
    </>
  )
}

export default OtpScreenView;