import '../Styles/AuthImageCardStyles.css'
const AuthImageCardView =(props)=>{
    return(
        <div className="auth-image-card-main-container">
        <img 
        src={props.image} 
        className="auth-image-card-image-container"
        />
        <div className="auth-image-card-text-container">
          <p className="auth-image-card-text-style">
           {props.text}
            </p>
            </div>
          </div>
    )
}



export default AuthImageCardView;