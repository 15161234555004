import React from 'react'
import "../Styles/PaymentScreenStyles.css"
import PaymentPreparationCardView from '../../../CommonComponents/PaymentPreparationCard/View/PaymentPreparationCardView'
import BillingDetailsCardView from '../../../CommonComponents/BillingDetailsCard/View/BillingDetailsCardView'
import WS from "../../../config/WS";
/* controller */
import PaymentScreenController from '../Controller/PaymentScreenController'
import BillingCardView from '../../../CommonComponents/BillingDetailsCardComponent/View/BillingCardView';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NetworkError from '../../../component/javascript/Network_Error';
import Configuration from '../../../config/configuration';


const PaymentScreenView = () => {

    const { session_details, cart_item_count } = useSelector(
        (state) => state.applicationState
    );

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const addToCardData = JSON.parse(queryParams.cart);

  console.log("addToCardData in payment page --- ", addToCardData)
  console.log("countryCode --  ", addToCardData[0]?.data?.countryCode)

const {
  handleWSOpen,
  handleWSError,
  handleWSClose,
  handleWSMessage,
  paymentHandler,
  paymentCardTitle,
  paymentCardButtonTxt,
  secondaryButtonTxt,
  showPaymentButton,
  showPaymentLoader,
  connectSocket,
  wsRef,
  is_network_error,
  set_is_network_error
} = PaymentScreenController(addToCardData, session_details, cart_item_count)


//  if (is_Loading) {
//   return <Loader isLoading={is_Loading} callBack={() => {}} />;
//  }

 if (is_network_error) {
  return <NetworkError callBack={() => set_is_network_error(false)} />;
 }

  return (
    <div className='payment-container'>

      {/* WebSocket Component */}
      {connectSocket && <WS
          // url="wss://staging.cognitive-edu.com:55621/CEDUMsgServiceWebSoc/"
          // url="ws://127.0.0.1:6013"
          url={Configuration.BASEURL_WS + Configuration.SERVICEURL.WEBSOCKET_SERVICE}
          onOpen={handleWSOpen}
          onMessage={handleWSMessage}
          onClose={handleWSClose}
          onError={handleWSError}
          ref={wsRef}
          reconnect={true}
      />}

      <div className='payment-page-left-container'>
        <PaymentPreparationCardView paymentCardTitle={paymentCardTitle} paymentCardButtonTxt={paymentCardButtonTxt} secondaryButtonTxt={secondaryButtonTxt} showPaymentButton={showPaymentButton} showPaymentLoader={showPaymentLoader} paymentHandler={paymentHandler} />
      </div>

      <div className='payment-page-right-container'> <BillingCardView addToCardData={addToCardData} totalNoOfVideosInCart={0} totalPriceOfAllVideos={0} isButton={false} countryCode={addToCardData[0]?.data?.countryCode}/>
      </div>

    </div>
  )
}

export default PaymentScreenView