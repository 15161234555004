import { useEffect, useRef, useState } from "react";
import BellSvg from "../../../new_assets/Svg/BellSvg";
import CartSvg from "../../../new_assets/Svg/CartSvg";
import LogoSvg from "../../../new_assets/Svg/LogoSvg";
import MenuSvg from "../../../new_assets/Svg/MenuSvg";
import ProfilePicIcon from "../../../new_assets/Svg/ProfilePicIcon";
import ProfileSvg from "../../../new_assets/Svg/ProfileSVg";
import '../Styles/MainHeaderStyles.css'
import { useSelector } from "react-redux";
import axios from "axios";
import { FiLogOut } from "react-icons/fi";
import { LogOut, cartItemCount, addToCart } from "../../../redux/action";
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import { API_URL } from '../../../api/URL/Api_Route';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from '../../../component/javascript/loader';
import NetworkError from '../../../component/javascript/Network_Error';
import Configuration from "../../../config/configuration";



const menuData = [
    { data: "Home", path: "/" },
    { data: "Progress" },
    { data: "Courses", path: "/courses" },
    { data: "Profile" },
    { data: "Notification" },
]


const MainHeaderView = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [isProfileOpen, setProfileOpen] = useState(false);
    const profileRef = useRef(null);

    const [isMenuExpand, setMenuExpand] = useState(false)
    const [data, setData] = useState()
    console.log("data12345===>", data)
    const { session_details, cart_item_count } = useSelector((state) => state.applicationState);

    const _id = session_details?.sub
    const cartNotificationNumbers = 3;
    console.log("cart_item_count", cart_item_count)
    console.log('cartNotificationNumbers', cartNotificationNumbers)


    console.log("session_details_header_main", session_details)


    const [displayMenu, setDisplayMenu] = useState(false);
    const [showMenu, setshowMenu] = useState(false);
    const menuRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [is_network_error, set_is_network_error] = useState(false);

    /* get cart data */

    const getCartData = () => {
        let payload = {
            baseURL: Configuration.BASEURL + Configuration.SERVICEURL.PURCHASE_SERVICE,
            url: `${API_URL.get_from_cart}?UserID=${_id}`,
        };
        ApiRequestMethod(payload)
            .then(async (response) => {
                console.log("getCartData response outside cart ---", response);
                if (response.status === 200) {
                    let new_data = response?.data.data;
                    console.log("new_data outside cart ======== ", new_data)

                    new_data.forEach(itemId => {
                        dispatch(addToCart(itemId));
                    });

                    dispatch(cartItemCount({ cart_item_count: new_data.length }));
                    //  dispatch(cartItemCount({ cart_item_count: cart_item_count + 1 }));
                }
            })
            .catch((error) => {
                console.log("getCartData error", error);
            });
    };

    useEffect(() => {
        if (_id) {
            getCartData();
        }
    }, [_id]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setDisplayMenu(false);
                // setshowMenu(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);



    useEffect(() => {

        const fetchData = async () => {

            const body = {
                _id: _id
            }

            try {
                if (!_id) return;
                console.log("hello123456789")
                const { data: response } = await axios.post(
                    `${Configuration.BASEURL + Configuration.SERVICEURL.ACCOUNT_MANAGEMENT_SERVICE}users/account`,
                    body,
                    {withCredentials:true}
                );

                if (response?.data) {
                    // response.data.UserName= "123456789012345678901234"
                    // response.data.UserName= "1234567890123456"
                    console.log("response.data", response.data)
                    setData(response.data);
                    // Setisloading(false)
                } else {
                    console.warn("No data received, keeping placeholders.");
                }
            } catch (error) {
                console.error("API call failed:", error.message);
            }
        };

        fetchData();
    }, [_id]);


    useEffect(() => {
        function handleClickOutside(event) {
            if (profileRef.current && !profileRef.current.contains(event.target)) {
                setProfileOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    /* logout API */

    const onSubmit = () => {
        console.log("logout clicked -- ")
        setIsLoading(true)

        let payload = {
            baseURL: Configuration.BASEURL + Configuration.SERVICEURL.IDENTITY_MANAGEMENT_SERVICE,
            url: API_URL.logout,
        };
        ApiRequestMethod(payload)
            .then(async (res) => {
                setIsLoading(false)
                if (res?.status == 200) {
                    dispatch(LogOut());
                    navigate('/')
                }
            })
            .catch((err) => {
                console.log(err);
                set_is_network_error(true)
            })
    }


    if (isLoading) {
        return <Loader isLoading={isLoading} callBack={() => { }} />;
    }
    if (is_network_error) {
        return <NetworkError callBack={() => { }} />;
    }

    console.log("data?.UserName?.length", data?.UserName?.length)

    return (
        <>
            {showMenu &&
                <div style={{overflow:"hidden"}} className={`home-page-menu-container ${displayMenu ? "home-page-menu-container-show" : "home-page-menu-container-hide"}`} ref={menuRef} >
                    <div className='home-page-menu-profile-container'>
                        <div className='home-page-menu-profile-container-wrap'>
                            <div className='home-page-menu-profile-image-container'>
                                <ProfilePicIcon className='home-page-menu-profile-image' />
                            </div>
                            <div className='home-page-menu-profile-info-container'>
                                <div className='home-page-menu-profile-info-name'>
                                    <a>{data?.UserName || "Loading..."}</a>
                                </div>
                                <div className='home-page-menu-profile-info-email'>
                                    <a>{data?.EmailId || "Loading..."}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* <div style={{backgroundColor:"tomato",display:"flex", flexDirection:"column", justifyContent:"space-around" , height:"80%", width:"100%"}}> */}

                    
                    <div className='home-page-menu-list-container'>
                        {
                            menuData.map((value) => (
                                <div 
                                // style={{backgroundColor:"ButtonShadow"}} 
                                className='home-page-menu-list'>
                                    <a onClick={() => { navigate(value?.path); setDisplayMenu(false); }}>{value?.data}</a>
                                </div>
                            ))
                        }

                    </div>
                    {/* <div style={{ backgroundColor:"GrayText", height:"130px", display:"flex", justifyContent:"flex-end", alignItems:"flex-end", }} > */}
                        <div className="logout-button" onClick={onSubmit}>
                            <div>
                                Log Out
                            </div>
                            <div>
                                <FiLogOut />
                            </div>
                        </div>
                    {/* </div> */}
                </div>
                // </div>
            }
            <div className='home-page-header-container' style={{ position: 'relative', backgroundColor: props.screen ? 'transparent':""  }}>

                <div className='home-page-header-menu-icon' onClick={() => {
                    setshowMenu(true)
                    setDisplayMenu(true)
                }} >
                    <MenuSvg fill={props.screen ? "white":"#0D5F67"} />
                </div>

                <div className='home-page-header-logo-container'>
                    <LogoSvg onClick={() => { navigate('/') }} fill={props.screen ? "white":""} style={{ cursor: 'pointer' }} />
                </div>

                <div className='home-page-header-menu-container'>

                    <div className='home-page-header-menu-container-words' style={{color: props.screen ? "white":""}}>
                        <a onClick={() => { navigate('/') }} style={{ cursor: "pointer", userSelect:"none" }}>Home</a>
                        {/* <a onClick={() => { navigate('/mycourse') }} style={{ cursor: "pointer" }}>My Courses</a> */}
                        <a style={{ cursor: "not-allowed", userSelect:"none" }}>Progress</a>
                        <a onClick={() => { navigate('/courses') }} style={{ cursor: "pointer", userSelect:"none" }}>Courses</a>
                    </div>
                    <div className='home-page-header-menu-container-svg-name-wrap'>
                        <div className='home-page-header-menu-container-svg'>

                            {/* <div><AiOutlineShoppingCart/></div> */}
                            {/* <div><BiUserCircle/></div> */}

                            {/* <div className='home-page-header-menu-container-svg-circle ' onClick={() => { navigate('/cart') }}><CartSvg className='home-page-header-menu-svg' /></div>*/}

                            <div className="home-page-header-menu-container-svg-circle" onClick={() => navigate('/cart')} style={{ cursor: "pointer" }}>
                                <CartSvg className="home-page-header-menu-svg" />
                                {cart_item_count >= 1 && (
                                    <span className="cart-notification-badge">{cart_item_count}</span>
                                )}

                            </div>


                            <div className='home-page-header-menu-container-svg-circle home-page-header-menu-container-words-hideable' style={{ cursor: "pointer" }}><BellSvg className='home-page-header-menu-svg' /></div>

                            <div className='home-page-header-menu-container-svg-circle home-page-header-menu-container-words-hideable profile-icon-container' style={{ cursor: "pointer" }}
                                // onClick={()=>{setMenuExpand(!isMenuExpand)}}

                                onClick={() => setProfileOpen(!isProfileOpen)}
                                ref={profileRef}>
                                <ProfileSvg className='home-page-header-menu-svg' />

                                {isProfileOpen && (
                                    <div className="profile-dropdown">
                                        {/* <div className="profile-dropdown-header">
                                            <ProfileSvg />
                                            <div>
                                                <h4>{data?.UserName || "Loading..."}</h4>
                                                <p>{data?.EmailId || "Loading..."}</p>
                                            </div>
                                        </div> */}
                                        <div className="logout-button-out" onClick={onSubmit}>
                                            <div>
                                                Log Out
                                            </div>
                                            <div>
                                                <FiLogOut />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                

                            </div>
                        </div>
                        <div className="home-page-header-container-name home-page-header-menu-container-words-hideable" style={{ cursor: "pointer", color: props.screen ? "white":"", fontSize: data?.UserName?.length > 16 ? "10px" : "14px"}}>
                            <div>{data?.UserName || "Loading..."}</div>
                            <div style={{fontSize:"9px"}}>Student</div>
                        </div>
                    </div>
                </div>

            </div>



        </>
    )
}

export default MainHeaderView;