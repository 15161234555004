import { useState, useEffect } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import '../Styles/DropdownStyles.css';
import Dropdownicon_Svg from './../../../assets/svg/Dropdownicon_Svg';
import Upward_Svg from './../../../assets/svg/Upward_Svg';

const DropdownCourses = ({ courseData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [courses, setCourses] = useState(courseData); 


  useEffect(() => {
    setCourses(courseData);
  }, [courseData]);

  return (
    <div>
      <div 
        className="home-page-dropdown-filter" 
        // onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "not-allowed", display: "flex", alignItems: "center", position: "relative" }}
      >
        <span className="home-page-dropdown-filter-extend-name" style={{opacity:'0.2'}}>All Subjects</span>&nbsp;
       {isOpen ?(<Upward_Svg stoke={"rgba(0,0,0,0.2)"} fill={"rgba(0,0,0,0.2)"}style={{color:"rgba(0,0,0,0.2)"}} size={5} />
):(<Dropdownicon_Svg stoke={"rgba(0,0,0,0.2)"} fill={"rgba(0,0,0,0.2)"}style={{color:"rgba(0,0,0,0.2)"}} size={5}/>)}       </div>

      {isOpen && (
        <div className="course-list">
          {courses.length > 0 ? (
            courses.map((value, index) => (
              <p key={index} className="home-page-dropdown-filter-extend-name" 
                 style={{ borderBottom: "1px solid black" , opacity:'0.2' ,cursor:'not-allowed'}}>
                 {value|| "loading.."}
              </p>
            ))
          ) : (
            <p>No courses available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownCourses;
