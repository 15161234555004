import React, { useState, useRef } from 'react'
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import RunTimeConstant from '../../../config/RuntimeConfig';
import { MESSAGING_SERVICE_ROUTE } from '../../../api/URL/Api_Route';
import Configuration from '../../../config/configuration';
import { cartItemCount, showbanner } from '../../../redux/action';
import { useDispatch } from 'react-redux';

const PaymentScreenController = (addToCardData, session_details, cart_item_count) => {

  const wsRef = useRef(null);
  const dispatch = useDispatch()

  const [paymentCardTitle, setPaymentCardTitle] = useState("Prepare for Payment")
  const [paymentCardButtonTxt, setPaymentCardButtonTxt] = useState("Proceed to Payment")
  const [secondaryButtonTxt, setSecondaryButtonTxt] = useState("Back")
  const [showPaymentButton, setShowPaymentButton] = useState(true)
  const [showPaymentLoader, setShowPaymentLoader] = useState(false)
  const [connectSocket, setConnectSocket] = useState(false)
  const [orderData, setOrderData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [is_network_error, set_is_network_error] = useState(false);

  /* connection open */
  const handleWSOpen = async () => {
    console.log("WebSocket connection established --");
    if (wsRef.current) {
      let data = { UserID: session_details?.sub, payload: { action: "FIRST_CONNECT" } };
      let stringifiedData = JSON.stringify(data);
      wsRef.current.send(stringifiedData);
    }

    // Call CREATE_ORDER API only after WebSocket connection is established
    await createOrder();
  };

  /* connection error */
  const handleWSError = (error) => {
    console.log("WebSocket encountered an error --", error);
    setConnectSocket(false)
  };

  /* connection close */
  const handleWSClose = () => {
    if (paymentCardTitle === "Payment Successful!") {
      console.log("WebSocket connection closed as payment completed --");
      setConnectSocket(false)
    } else {
      console.log("WebSocket connection closed as something went wrong in server --");
      setShowPaymentLoader(false)
      setShowPaymentButton(true)
      setPaymentCardTitle("Something Went Wrong!")
      setPaymentCardButtonTxt("Retry Payment")
      // setSecondaryButtonTxt("Need help?")
      setConnectSocket(false)
    }
  };

  /* message send & receive */
  const handleWSMessage = (event) => {
    console.log(`Message from server -- ${event.data}`);
    let parsedData = JSON.parse(event.data);
    switch (parsedData?.payload?.action) {
      case "ORDER_CREATION_SUCCESS":
        console.log("ORDER_CREATION_SUCCESS -- ", parsedData.payload.data);
        setOrderData(parsedData.payload.data);
        console.log("orderData.id -- 1 ", orderData?.id);
        paymentOrderCreationReceived()
        break;
      case "DISPLAY_ORDER_SUCCESS":
        console.log("DISPLAY_ORDER_SUCCESS -- ", parsedData.payload.data);
        paymentCompletionReceived()
        break;
      case "ORDER_PENDING_FAILURE":
        console.log("ORDER_PENDING_FAILURE -- ", parsedData.payload.data);
        paymentOrderFailureReceived()
        break;
      case "PAYMENT_UPDATE_FAILED":
        console.log("PAYMENT_UPDATE_FAILED -- ", parsedData.payload.data);
        paymentUpdateFailureReceived()
        break;

      default:
        break;
    }
  };

  /* -- payment order creation scenario handling -- */
  const paymentOrderCreationReceived = async () => {
    openPaymentWindow(); // open payment portal using order id
    setShowPaymentLoader(true)
    setShowPaymentButton(false)
    setPaymentCardTitle("Payment Processing")
  }

  /* -- payment order failure scenario handling -- */
  const paymentOrderFailureReceived = async () => {
    setShowPaymentLoader(false)
    setShowPaymentButton(true)
    setPaymentCardTitle("Payment couldn't process")
    setPaymentCardButtonTxt("Retry Payment")
    // setSecondaryButtonTxt("Need help?")
  }

  /* -- Payment completion scenario handling -- */
  const paymentCompletionReceived = async () => {
    setPaymentCardTitle("Payment Processing")
    setShowPaymentLoader(true)
    if (wsRef.current) {
      let data = {
        UserID: session_details?.sub,
        payload: {
          action: "CLOSE_CONNECTION",
          origin: "INITIATE_PAYMENT",
          msg: "please close the socket connection as payment successfully completed",
        },
      };
      console.log("paymentCompleted data to send -- ", data);
      let stringifiedData = JSON.stringify(data);
      wsRef.current.send(stringifiedData);
      /* updating cart badge count */
      dispatch(cartItemCount({ cart_item_count: addToCardData[0]?.data?.cartCount ? 0 : cart_item_count - 1 }));
      setShowPaymentLoader(false)
      setPaymentCardTitle("Payment Successful!")
      setPaymentCardButtonTxt("Start Learning")
      setSecondaryButtonTxt("Go to Homepage")
      setShowPaymentButton(true)
    }
  };

  /* -- Payment failure scenario handling -- */
  const paymentUpdateFailureReceived = async () => {
    setShowPaymentLoader(true)
    if (wsRef.current) {
      let data = {
        UserID: session_details?.sub,
        payload: {
          action: "CLOSE_CONNECTION",
          origin: "PAYMENT_FAILURE_RECEIVE",
          msg: "please close the socket connection as payment failed while updating records",
        },
      };
      console.log("paymentFailureReceived data to send -- ", data);
      let stringifiedData = JSON.stringify(data);
      wsRef.current.send(stringifiedData);
      setShowPaymentLoader(false)
      setShowPaymentButton(true)
      setPaymentCardTitle("Payment Failed")
      setPaymentCardButtonTxt("Retry Payment")
      // setSecondaryButtonTxt("Need help?")
    }
  };

  /* -- payment trigger handler including set order pending API -- */
  // const paymentHandler = async (e) => {
  //   e.preventDefault();
  //   setConnectSocket(true)
  //   try {
  //     setShowPaymentLoader(true)
  //     setShowPaymentButton(false)
  //     setPaymentCardTitle("Payment Processing")
  //     const courseValidation = new Date();
  //     courseValidation.setFullYear(courseValidation.getFullYear() + 1);
  //     const CREATE_ORDER = {
  //       UserID: session_details?.sub,
  //       Courses: addToCardData.map(item => ({
  //         CourseID: item?.data?._id,
  //         Validity: courseValidation.toISOString(),
  //         Amount: item?.data?.Price?.[item.data.currencyCode],
  //         Currency: item?.data?.currencyCode
  //       })),

  //       TotalAmount: addToCardData?.reduce((sum, item) => sum + (item?.data?.Price[item?.data?.currencyCode] || 0), 0),
  //       CurrencyValue: addToCardData[0]?.data?.currencyCode,
  //     };

  //     console.log(" -- CREATE_ORDER Payload by saMir -- ", CREATE_ORDER)

  //     const payload = {
  //       baseURL: Configuration.BASEURL + Configuration.SERVICEURL.MESSAGE_SERVICE,
  //       url: MESSAGING_SERVICE_ROUTE.CREATE_ORDER,
  //       data: CREATE_ORDER,
  //       withCredentials: false,
  //       method: "Post"
  //     };
  //     console.log("order msg payload ===> ", payload);
  //     try {
  //       let resOrder = await ApiRequestMethod(payload);
  //       console.log("order response ===> ", resOrder);
  //     } catch (error) {
  //       if (error?.response?.status === 404) {
  //         dispatch(showbanner({ showbanner: true }));
  //       }
  //       else {
  //         console.log("error --- >", error);
  //         set_is_network_error(true);
  //         setShowPaymentLoader(false)
  //         setShowPaymentButton(true)
  //         setPaymentCardTitle("Prepare for Payment")
  //         // setPaymentCardButtonTxt("Retry Payment")
  //         // setSecondaryButtonTxt("Need help?")
  //         console.log("error --- >", error);
  //         set_is_network_error(true);
  //       }
  //     }
  //   } catch (error) {
  //     setConnectSocket(false)
  //     console.error("Error in paymentHandler:", error);
  //   }
  // };

  /* -- Opening payment protal window -- */
  const openPaymentWindow = async () => {
    let ordData = null;

    setOrderData(async (nextState) => {
      ordData = nextState;
      console.log("nextState: ", nextState);
      if (ordData) {
        console.log("orderData.id -- 2 ", ordData?.id);
        const options = {
          key: Configuration.RAZOR_PAY_KEY_ID,
          name: "CognitivED",
          description: "Welcome to CognitivED. Empowering Students CognitivED",
          image:
            "https://res.cloudinary.com/dunlmbvk1/image/upload/v1740134285/powoxmocnpg4uiwbgnwi.png",
          order_id: ordData?.id,
          handler: async (response, error) => {
            try {
              console.log("pay handler response === ", response);
              const PaymentId = response.razorpay_payment_id;
              console.log("PaymentId ===> ", PaymentId);
              try {
                if (wsRef.current) {
                  let data = {
                    UserID: session_details?.sub,
                    payload: {
                      action: "PAYMENT_SUCCED",
                      origin: "INITIATE_PAYMENT",
                      PaymentId: PaymentId,
                      Amount: addToCardData?.reduce((sum, item) => sum + (item?.data?.Price[item?.data?.currencyCode] || 0), 0),
                      Currency: addToCardData[0]?.data?.currencyCode, // Currency
                      PurchaseID: ordData?.PurchaseID,
                    },
                  };
                  console.log("payload11111 ", data);
                  let stringifiedData = JSON.stringify(data);
                  wsRef.current.send(stringifiedData);
                }
              } catch (error) {
                console.log("error: ===========>", error);
              }
            } catch (err) {
              console.error("Error capturing payment: **************", err);
            }
          },
          theme: {
            color: "rgba(13, 95, 103, 1)",
          },
          modal: {
            // ondismiss: () => {
            //   setShowPaymentLoader(false)
            //   setShowPaymentButton(true)
            //   setPaymentCardTitle("Prepare for Payment")
            //   setPaymentCardButtonTxt("Proceed to Payment")
            //   console.log("User closed the payment modal without completing the payment --");
            //   if (wsRef.current) {
            //     let data = {
            //       UserID: session_details?.sub,
            //       payload: {
            //         action: "PAYMENT_CANCLED_BY_USER",
            //         origin: "INITIATE_PAYMENT",
            //         PaymentId: "",
            //         PurchaseID: ordData?.PurchaseID,
            //       },
            //     };
            //     console.log("PAYMENT_CANCLED_BY_USER -- ", data);
            //     let stringifiedData = JSON.stringify(data);
            //     wsRef.current.send(stringifiedData);
            //   }
            // },

            ondismiss: () => {

              setTimeout(() => {
                setShowPaymentLoader(false);
                setShowPaymentButton(true);
                setPaymentCardTitle("Prepare for Payment");
                setPaymentCardButtonTxt("Proceed to Payment");
              }, 10);
            
              console.log("User closed the payment modal -- resetting state");
            
              // Reset connectSocket to allow retriggering when user retries
              setConnectSocket(false);
            
              // Clear or refresh order data (if required)
              setOrderData(null); // This ensures a new order is created on retry
            
              // Notify backend about cancellation
              if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                let data = {
                  UserID: session_details?.sub,
                  payload: {
                    action: "PAYMENT_CANCELED_BY_USER",
                    origin: "INITIATE_PAYMENT",
                    PaymentId: "",
                    PurchaseID: ordData?.PurchaseID,
                  },
                };
                console.log("PAYMENT_CANCELED_BY_USER -- ", data);
                wsRef.current.send(JSON.stringify(data));
              }
            }
            
          },
        };

        // Create a new Razorpay instance and open the payment form
        const rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          console.log("Payment failed:", response.error);
          if (wsRef.current) {
            let data = {
              UserID: session_details?.sub,
              payload: {
                action: "PAYMENT_NOT_COMPLETED",
                origin: "INITIATE_PAYMENT",
                msg: "Payment could not be completed",
              },
            };
            console.log("PAYMENT_NOT_COMPLETED in frontend -- ", data);
            let stringifiedData = JSON.stringify(data);
            wsRef.current.send(stringifiedData);
          }
        });
        rzp1.open();
      } else {
        console.log("");
      }
      return nextState;
    });
  };

  /* payment trigger handler */
  const paymentHandler = async (e) => {
    e.preventDefault();
    setConnectSocket(true); // This will trigger `handleWSOpen`, which in turn calls `createOrder`
    setShowPaymentLoader(true)
    setShowPaymentButton(false)
    setPaymentCardTitle("Payment Processing")
};

  /* set order pending API */
  const createOrder = async () => {
    try {
        setShowPaymentLoader(true);
        setShowPaymentButton(false);
        setPaymentCardTitle("Payment Processing");

        const courseValidation = new Date();
        courseValidation.setFullYear(courseValidation.getFullYear() + 1);

        const CREATE_ORDER = {
            UserID: session_details?.sub,
            Courses: addToCardData.map(item => ({
                CourseID: item?.data?._id,
                Validity: courseValidation.toISOString(),
                Amount: item?.data?.Price?.[item.data.currencyCode],
                Currency: item?.data?.currencyCode
            })),
            TotalAmount: addToCardData?.reduce((sum, item) => sum + (item?.data?.Price[item?.data?.currencyCode] || 0), 0),
            CurrencyValue: addToCardData[0]?.data?.currencyCode,
        };

        console.log("CREATE_ORDER Payload:", CREATE_ORDER);

        const payload = {
            baseURL: Configuration.BASEURL + Configuration.SERVICEURL.MESSAGE_SERVICE,
            url: MESSAGING_SERVICE_ROUTE.CREATE_ORDER,
            data: CREATE_ORDER,
            withCredentials: false,
            method: "Post"
        };

        let resOrder = await ApiRequestMethod(payload);
        console.log("Order response:", resOrder);
    } catch (error) {
        console.error("Error in createOrder:", error);
        if (error?.response?.status === 404) {
          dispatch(showbanner({ showbanner: true }));
        }else{
          set_is_network_error(true);
        }
        setShowPaymentLoader(false);
        setShowPaymentButton(true);
        setPaymentCardTitle("Prepare for Payment");
    }
};


  return {
    /* handlers */
    handleWSOpen,
    handleWSError,
    handleWSClose,
    handleWSMessage,
    paymentHandler,
    /* state variables */
    paymentCardTitle,
    paymentCardButtonTxt,
    secondaryButtonTxt,
    showPaymentButton,
    showPaymentLoader,
    connectSocket,
    is_network_error,
    /* state update variables */
    set_is_network_error,
    /* ref */
    wsRef
  }
}

export default PaymentScreenController;