import Dropdownicon_Svg from './../../../assets/svg/Dropdownicon_Svg';
import Upward_Svg from './../../../assets/svg/Upward_Svg';
import '../Styles/PurchasedChapterBoxView.css';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showbanner } from '../../../redux/action';
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import { API_URL } from '../../../api/URL/Api_Route';
import Configuration from '../../../config/configuration';
import PurchasedVideoExpandView from '../../PurchasedVideoExpand/View/PurchasedVideoExpandView';

const PurchasedChapterBoxView = ({ ChapterId, indexvalue, setVideoData }) => {
    const [topicData, setTopicData] = useState([]);
    const [expandedTopic, setExpandedTopic] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const payload = {
                method: "post",
                url: API_URL.getTopicByChapter,
                baseURL: Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE,
                data: { "_id": ChapterId },
            };
            try {
                const res = await ApiRequestMethod(payload);
                if (res?.status === 200 && res?.data?.data?.length) {
                    setTopicData(res.data.data);
                } else {
                    setTopicData([]);
                }
            } catch (err) {
                console.error("Error fetching topic data:", err);
                if (err?.response?.status === 404) {
                    dispatch(showbanner({ showbanner: true }));
                }
                setTopicData([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [ChapterId, dispatch]);

    const toggleExpand = (topicId) => {
        setExpandedTopic((prev) => (prev === topicId ? null : topicId));
    };

    return (
        <div style={{ display: "flex", width: '100%' }}>
            <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-end", height: '100%', width: "100%" }}>
                {isLoading ? (
                    <div style={{ textAlign: 'center', padding: '14px', width: '95%', border: '1px solid rgba(217, 217, 217, 1)' }}>
                        <span style={{ opacity: '0.2' }}>Loading topics...</span>
                    </div>
                ) : topicData.length ? (
                    topicData.map((value, index) => (
                        <div key={value._id} style={{ width: '100%', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                            <div className='p-chapter-details-container'>
                                <div
                                    className='p-chapter-title-container-field-wrapup'
                                    onClick={() => toggleExpand(value._id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div style={{ width: '95%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                        <span className='p-topic-styling-details'>
                                            {(indexvalue + 1) + "." + (index + 1)} {value?.TopicName}
                                        </span>
                                    </div>
                                    <div style={{ width: '5%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {expandedTopic === value._id ? <Upward_Svg stoke="rgba(151, 151, 151, 1)" /> : <Dropdownicon_Svg stoke="rgba(151, 151, 151, 1)" />}
                                    </div>
                                </div>
                            </div>
                            {expandedTopic === value._id && (
                                <div className='p-video-expand-container'>
                                    <PurchasedVideoExpandView TopicID={value?._id} index={index} setVideoData={setVideoData} />
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <div style={{ textAlign: 'center', padding: '14px', width: '95%', border: '1px solid rgba(217, 217, 217, 1)' }}>
                        <span style={{ opacity: '0.4', fontSize: '15px' }}>Topics will be updated shortly</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PurchasedChapterBoxView;
