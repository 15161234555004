import React, { useEffect } from 'react';
import LogoSvg from '../../../../new_assets/Svg/LogoSvg';
import '../Styles/AuthenticatedLandingScreen.css'
import landImg from '../../../../new_assets/img/landing-page-bg.png'
import AuthLandingScreenModel from '../Model/AuthenticatedScreenModel';
import LandingScreenController from '../Controller/AuthenticatedScreenController';
import { useLocation, useNavigate } from 'react-router-dom';
import MainHeaderView from '../../../../CommonComponents/MainHeaderComponent/View/MainHeaderView';
import HowItWorksSectionView from '../../../LandingScreen/Components/View/HowItWorksSectionView';
import InSightSectionView from '../../../LandingScreen/Components/View/InSightSectionView';
import CourseOverviewSectionView from '../../../LandingScreen/Components/View/CourseOverviewSectionView';
import TestimonialSectionView from '../../../LandingScreen/Components/View/TestimonialsSectionView';
import GetStartedSectionView from '../../../LandingScreen/Components/View/GetStartedSectionView';



const AuthenticatedLandingScreen = () => {
  const navigate = useNavigate();
  const { containerStyle, setContainerStyle, overlay, setOverlay } = AuthLandingScreenModel();
  const videoRef = React.useRef(null);
  React.useEffect(() => {
    console.log("entry.isIntersecting: qwertyuio")
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (videoRef.current) {
          if (entry.isIntersecting) {
            videoRef.current.play(); 
          } else {
            videoRef.current.pause(); 
          }
        }
      },
      { threshold: 0.25 } 
    );
    
    

    const observers = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setContainerStyle(''); // Reset styles when at the top
          setOverlay(''); // Reset overlay
        } else {
          setContainerStyle('scrolled-container-a'); 
          setOverlay('extend-logo-container'); 
        }
      },
      { threshold: 0.99 } 
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
      observers.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
        observers.unobserve(videoRef.current);
      }
    };
  }, []);

  

  LandingScreenController(setContainerStyle, setOverlay);

  return (
    <div className={`landing-screen-a ${containerStyle}`} >
      <div className="auth-container-land-a" >
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          id="video"
          className="background-video"
          controlsList="nodownload nofullscreen noremoteplayback" 
          disablePictureInPicture 
          poster={landImg}
          style={{pointerEvents: 'none'}}
        >
          <source src='https://dgptv56br1zqm.cloudfront.net/landing_screen_backgrund_video.mp4' type="video/mp4" />
        </video>
        <MainHeaderView screen="Header"/>
        <div className="auth-video-overlay">
          <div className="main-cont">
            <p className="main-heading">
              Revolutionize Learning <div className='line-break-landing'></div> with 
              <br/>
              <span className="highlight">AI-Driven Insights</span>
            </p>
            <p className="sub-heading">
              {/* Uncover weaker areas and empower students to excel with personalized learning paths. */}
              Identify growth areas and inspire students to reach their full potential with personalized learning paths.
            </p>
            <div className="get-started-button"  onClick={()=>{navigate('/courses')}}>Explore Course</div>
            {/* <div className='sign-in-up-wrap hide-laptop-desktop'>
              <div className="sign-in-button" onClick={()=>{navigate('/signin')}}>
                Log in 
              </div>
              <div className="sign-up-button" onClick={()=>{navigate('/signup')}}>
                Sign up
              </div>
            </div> */}
          </div>
        </div>
      </div>

      

      {/* Additional Sections */}
      {/* <HowItWorksSectionView />
      <InSightSectionView />
      <CourseOverviewSectionView />
      <TestimonialSectionView />
      <GetStartedSectionView /> */}
      <HowItWorksSectionView/>
      <InSightSectionView/>
      <CourseOverviewSectionView/>
      <TestimonialSectionView/>
      <GetStartedSectionView/>
      {/* <Footer /> */}
    </div>
  );
};

export default AuthenticatedLandingScreen;
