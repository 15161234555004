const RunTimeConstant = {
 BASEURL: "",
 SERVICEURL: {
  AMS: "", // Account Management Service
  IMS: "", // Identity Management Service
  PMS: "", // Policy Management Service
  CMS: "", // Cache Management Service
  CS: "", // Course Service
  MS: "", // Message Service
  PRSMS: "", // Purchase Service
  PAYMS: "", // Payment Service
 },
 ROLEID:""
}


//Development Environment URLS:

if (process.env.NODE_ENV == "development") {

  RunTimeConstant.SERVICEURL.AMS = "https://staging.cognitive-edu.com:55621/CEDUA/"
  RunTimeConstant.SERVICEURL.IMS = "https://staging.cognitive-edu.com:55621/CEDUIdenty/"
  RunTimeConstant.SERVICEURL.PMS = "https://staging.cognitive-edu.com:55621/CEDUPolicy/"
  RunTimeConstant.SERVICEURL.CMS = "https://staging.cognitive-edu.com:55621/CEDUCO/"
  RunTimeConstant.SERVICEURL.CS = "https://staging.cognitive-edu.com:55621/CEDUC/"

  RunTimeConstant.SERVICEURL.MS = "https://staging.cognitive-edu.com:55621/CEDUMsgService/"
  RunTimeConstant.SERVICEURL.PRSMS = "https://staging.cognitive-edu.com:55621/CEDUPurchaseService/"
  RunTimeConstant.SERVICEURL.PAYMS = "https://staging.cognitive-edu.com:55621/CEDUPayService/"

  RunTimeConstant.ROLEID = "67aca3055997b1612988c47b"
} 

//Production Environment URLS:
else if (process.env.NODE_ENV == "production") {
  console.log = () => {}
  console.warn = () => {}
  console.error = () => {}


  //Production ==> Production Services and API's

  RunTimeConstant.SERVICEURL.AMS = "https://api.cognitive-edu.com:55585/CEDUA/"
  RunTimeConstant.SERVICEURL.IMS = "https://api.cognitive-edu.com:55585/CEDUIdenty/"
  RunTimeConstant.SERVICEURL.PMS = "https://api.cognitive-edu.com:55585/CEDUPolicy/"
  RunTimeConstant.SERVICEURL.CMS = "https://api.cognitive-edu.com:55585/CEDUCO/"
  RunTimeConstant.SERVICEURL.CS = "https://api.cognitive-edu.com:55585/CEDUC/"

  RunTimeConstant.ROLEID = "67aca3055997b1612988c47b"

  // Production ==> Staging Services and API's
 
  RunTimeConstant.SERVICEURL.AMS = "https://staging.cognitive-edu.com:55621/CEDUA/"
  RunTimeConstant.SERVICEURL.IMS = "https://staging.cognitive-edu.com:55621/CEDUIdenty/"
  RunTimeConstant.SERVICEURL.PMS = "https://staging.cognitive-edu.com:55621/CEDUPolicy/"
  RunTimeConstant.SERVICEURL.CMS = "https://staging.cognitive-edu.com:55621/CEDUCO/"
  RunTimeConstant.SERVICEURL.CS = "https://staging.cognitive-edu.com:55621/CEDUC/"

  RunTimeConstant.SERVICEURL.MS = "https://staging.cognitive-edu.com:55621/CEDUMsgService/"
  RunTimeConstant.SERVICEURL.PRSMS = "https://staging.cognitive-edu.com:55621/CEDUPurchaseService/"
  RunTimeConstant.SERVICEURL.PAYMS = "https://staging.cognitive-edu.com:55621/CEDUPayService/"

  RunTimeConstant.ROLEID = "67aca3055997b1612988c47b"

} 

//Staging Environment URLS
else {
 RunTimeConstant.SERVICEURL.AMS = "https://staging.cognitive-edu.com:55621/CEDUA/"
 RunTimeConstant.SERVICEURL.IMS = "https://staging.cognitive-edu.com:55621/CEDUIdenty/"
 RunTimeConstant.SERVICEURL.PMS = "https://192.168.10.33:40005/"
 RunTimeConstant.SERVICEURL.CMS = "https://staging.cognitive-edu.com:55621/CEDUC/"
 RunTimeConstant.SERVICEURL.CS = "https://staging.cognitive-edu.com:55621/CEDUC/"

 RunTimeConstant.ROLEID = "67aca3055997b1612988c47b"
}
export default RunTimeConstant
