import '../Styles/AddToCartStyles.css'
import BillingCardView from './../../../CommonComponents/BillingDetailsCardComponent/View/BillingCardView';
import AddToCartCourseCardView from '../../../CommonComponents/AddToCartCourseCard/View/AddToCartCourseCardView';
import { useEffect, useState } from 'react';
import Configuration from './../../../config/configuration';
import { useDispatch, useSelector } from 'react-redux';
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import { API_URL } from '../../../api/URL/Api_Route';
import { cartItemCount, showbanner } from "../../../redux/action";
import NetworkError from '../../../component/javascript/Network_Error';
import { useNavigate } from 'react-router-dom';
import queryString from "query-string";
import { CountryToCurrency } from '../../../helper/country_to_currency';
import LoaderView from '../../../CommonComponents/LoaderComponet/View/LoaderView';

const AddToCartView = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [addToCardData, setAddToCardData] = useState([])
    console.log("addToCardData====>", addToCardData)
    const [isLoading, SetIsLoading] = useState(true)
    const [is_network_error, set_is_network_error] = useState(false)

    // let totalPriceOfAllVideos = 0
    // totalPriceOfAllVideos += addToCardData.reduce((total, item) => total + parseFloat(item.price), 0);


    const { session_details } = useSelector(
        (state) => state.applicationState
    );
    const countryCode = session_details?.cc


    useEffect(() => {
        getCartData();
    }, []);

    const getCartData = async () => {
        try {
            SetIsLoading(true);
            let payload = {
                baseURL: Configuration.BASEURL + Configuration.SERVICEURL.PURCHASE_SERVICE,
                // url: `${API_URL.get_from_cart}?UserID=${session_details?.sub}`,
                url: `${API_URL.get_from_cart}?UserID=${session_details?.sub}`,
            };

            const response = await ApiRequestMethod(payload);
            console.log("getCartData response inside cart", response);

            if (response.status === 200) {
                const new_data = response?.data?.data;

                dispatch(cartItemCount({ cart_item_count: new_data.length }));

                const courseDetailsPromises = new_data.map((courseID) => getCourseDetails(courseID));

                const courseDetailsArray = await Promise.allSettled(courseDetailsPromises);

                let exist_data = [];
                courseDetailsArray.forEach((result, index) => {
                    if (result.status === "fulfilled") {
                        let ele = result.value;
                        ele.thumnail_image = `${Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE}assert/image?id=${encodeURIComponent(
                            ele.AssetPath + ele.Image
                        )}`;
                        exist_data.push(ele);
                    } else {
                        console.error(`Error fetching course ID ${new_data[index]}:`, result.reason);
                    }
                });

                console.log("cart data =========", exist_data);
                setAddToCardData(exist_data);
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                dispatch(showbanner({ showbanner: true }));
            }
            else {
                SetIsLoading(false);
                set_is_network_error(true)
            }

        }

        finally {
            SetIsLoading(false);
        }
    };

    const getCourseDetails = async (courseID) => {
        console.log('CourseId======>', courseID);
        let payload = {
            method: 'post',
            baseURL: Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE,
            url: API_URL.getCourseById,
            data: JSON.stringify({
                _id: courseID,
                cc: session_details?.cc,
                page: 1,
                limit: 1,
            }),
        };

        try {
            const res = await ApiRequestMethod(payload);
            console.log(`getCourseDetails response for ID ${courseID}`, res?.data);
            if (res.status === 200) {
                return res.data;
            }
        } catch (error) {
            console.log("getCourseDetails error", error);
            throw error;
        }
    };

    const goToPayment = () => {

        addToCardData.forEach((course) => {
            course.data.cartCount = addToCardData.length;
            course.data.currencyCode = CountryToCurrency(session_details?.cc);
            course.data.countryCode = session_details?.cc
        });

        // let transformedData = addToCardData.map(item => ({
        //     ...item.data,
        //     thumnail_image: item.thumnail_image
        // }));

        console.log("Data to send from add cart page -- ", addToCardData)

        const dataString = JSON.stringify(addToCardData);
        const queryStringified = queryString.stringify({ cart: dataString });
        navigate(`/payment?${queryStringified}`);
    }


    if (isLoading) {
        return <LoaderView isLoading={isLoading} />;
    }
    if (is_network_error) {
        return <NetworkError callBack={() => {
            set_is_network_error(false)
            getCartData();
        }} />;
    }


    return (
        <div className='add-to-card-main-container' >
            {addToCardData.length > 0 ? (
                <>
                    <p className='add-to-card-heading-text'>{addToCardData.length} {addToCardData.length <= 1 ? "course" : "courses"} in cart</p>
                    <div className="add-to-cart-view">
                        <div className='add-to-cart-left-list-courses-container'>
                            <div className='add-to-card-left-container-wrap-up'>
                                <AddToCartCourseCardView addToCardData={addToCardData} countryCode={countryCode} getCartData={getCartData} />
                            </div>
                        </div>
                        <div className='add-to-cart-right-payment-container'>
                            <BillingCardView addToCardData={addToCardData} totalNoOfVideosInCart={addToCardData.length} totalPriceOfAllVideos={addToCardData.reduce((total, item) => total + parseFloat(item.price), 0)} isButton={true} countryCode={countryCode} goToPayment={goToPayment} />
                        </div>
                    </div>
                </>
            ) : (
                <div className='alternate-add-to-cart-view'>
                    <p className='alternate-add-to-card-heading-text'>No Courses in the Cart</p>
                    <div className='alternate-explore-our-courses-button' onClick={() => navigate('/courses')}>Explore Our Courses</div>
                </div>
            )}
        </div>

    )
}

export default AddToCartView;