import React, { useEffect } from 'react';
import LogoSvg from '../../../../new_assets/Svg/LogoSvg';
import '../Styles/LandingScreenStyles.css'
import landImg from '../../../../new_assets/img/landing-page-bg.png'
import LandingScreenModel from '../Model/LandingScreenModel';
import LandingScreenController from '../Controller/LandingScreenController';
import HowItWorksSectionView from './HowItWorksSectionView';
import InSightSectionView from './InSightSectionView';
import TestimonialSectionView from './TestimonialsSectionView';
import GetStartedSectionView from './GetStartedSectionView';
import CourseOverviewSectionView from './CourseOverviewSectionView';
import { useLocation, useNavigate } from 'react-router-dom';



const LandingScreenView = () => {
  const navigate = useNavigate();
  const { containerStyle, setContainerStyle, overlay, setOverlay } = LandingScreenModel();
  const videoRef = React.useRef(null);
  React.useEffect(() => {
    console.log("entry.isIntersecting: qwertyuio")
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (videoRef.current) {
          if (entry.isIntersecting) {
            videoRef.current.play(); 
          } else {
            videoRef.current.pause(); 
          }
        }
      },
      { threshold: 0.25 } 
    );
    
    

    const observers = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setContainerStyle(''); // Reset styles when at the top
          setOverlay(''); // Reset overlay
        } else {
          setContainerStyle('scrolled-container-a'); 
          setOverlay('extend-logo-container'); 
        }
      },
      { threshold: 0.99 } 
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
      observers.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
        observers.unobserve(videoRef.current);
      }
    };
  }, []);

  

  LandingScreenController(setContainerStyle, setOverlay);

  return (
    <div className={`landing-screen-a ${containerStyle}`} >
      <div className="container-land-a" >
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          id="video"
          className="background-video"
          controlsList="nodownload nofullscreen noremoteplayback" 
          disablePictureInPicture 
          poster={landImg}
          style={{pointerEvents: 'none'}}
        >
          <source src='https://dgptv56br1zqm.cloudfront.net/landing_screen_backgrund_video.mp4' type="video/mp4" />
        </video>
        <div className="video-overlay">
          <div className={`logo-container ${overlay}`}>
            <LogoSvg style={{fill:'white', marginLeft:"35px"}}/>
            <div className='sign-in-up-wrap-header hide-mobile-tab'>
              <div style={{cursor:"pointer"}} onClick={()=>{navigate('/signin')}}>
                Log in
              </div>
              <div style={{cursor:"pointer"}} onClick={()=>{navigate('/signup')}}>
                Sign up
              </div>
            </div>
          </div>
          <div className="main-cont">
            <p className="main-heading">
              Revolutionize Learning <div className='line-break-landing'></div> with 
              <br/>
              <span className="highlight">AI-Driven Insights</span>
            </p>
            <p className="sub-heading">
              {/* Uncover weaker areas and empower students to excel with personalized learning paths. */}
              Identify growth areas and inspire students to reach their full potential with personalized learning paths.
            </p>
            <div className="get-started-button hide-mobile-tab"  onClick={()=>{navigate('/signin')}}>Get Started</div>
            <div className='sign-in-up-wrap hide-laptop-desktop'>
              <div className="sign-in-button" onClick={()=>{navigate('/signin')}}>
                Log in 
              </div>
              <div className="sign-up-button" onClick={()=>{navigate('/signup')}}>
                Sign up
              </div>
            </div>
          </div>
        </div>
      </div>

      

      {/* Additional Sections */}
      <HowItWorksSectionView />
      <InSightSectionView />
      <CourseOverviewSectionView />
      <TestimonialSectionView />
      <GetStartedSectionView />
      {/* <Footer /> */}
    </div>
  );
};

export default LandingScreenView;
