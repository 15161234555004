import { API_URL } from "../../../../api/URL/Api_Route";
import { ApiRequestMethod } from "../../../../api/request/Api_Calls";
import RunTimeConstant from "../../../../config/RuntimeConfig";



export const getAllCoursesInLandingPage = async () => {
  console.log("hi  broo===>")

  const payload = {
    method:"post",
    baseURL: RunTimeConstant.SERVICEURL.CMS,
    url: API_URL.trendingCourse,
    data: { cc: "IN", limit: 4 ,page:1},
    // sessionDetails?.cc
  }

  try {
    const response = await ApiRequestMethod(payload);
    console.log("response==>",response.data.success)
    if (response?.data?.success) {
      console.log("jjjjjjjjjjj",response)
      return response.data.data.map((ele) => ({
        ...ele,
        thumnail_image: `${RunTimeConstant.SERVICEURL.CMS}assert/image?id=${encodeURIComponent(
          ele.AssetPath + ele.Image
        )}`,
      }));
    } else {
      console.log("response xyz",response)
      throw new Error(response.message);
    }
  } catch (error) {
    throw error;
  }
};
