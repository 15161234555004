import { Formik } from "formik";
import '../Styles/ForgetpasswordScreenStyles.css'
import NetworkError from "../../../component/javascript/Network_Error";
import { data, ValidationSchema } from "../Model/ForgetpasswordScreenModel";
import { authvideocarddata } from '../../../CommonComponents/AuthImageCard/Model/AuthImageCardModel'
import BackgroundImage from './../../../CommonComponents/AuthBackgroundImage/View/AuthBackgroundImage';
import HeaderSectionView from "../../../CommonComponents/HeaderComponent/View/HeaderSectionView";
import AuthMainContainerView from "../../../CommonComponents/AuthMainContainer/View/AuthMainContainerView";
import Textfield from './../../../CommonComponents/TextboxComponent/View/TextfieldView';
import FormContainerView from './../../../CommonComponents/FormContainerComponent/View/FormContainerView';
import AuthImageCardView from './../../../CommonComponents/AuthImageCard/View/AuthImageCardView';
import AuthButton from "../../../CommonComponents/AuthButton/View/AuthButton";
import ForgetPasswordScreenController from "../Controller/ForgetpasswordScreenController";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import LoaderView from "../../../CommonComponents/LoaderComponet/View/LoaderView";

const ForgetPasswordScreenView = () => {

  const location = useLocation();
  let queryParams = queryString.parse(location.search);
  const { isLoading, is_network_error, setIsLoading, onSubmit, set_is_network_error } = ForgetPasswordScreenController();

  if (is_network_error) {
    return <NetworkError callBack={() => set_is_network_error(false)} />;
  }

  return (
    <>
      <LoaderView isLoading={isLoading} callBack={() => setIsLoading(false)} />
      <BackgroundImage>
        <Formik
          initialValues={{ user_email: queryParams.email || "" }}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <div className='forgetpassword-screen-view-outLine' >
              <HeaderSectionView />
              <AuthMainContainerView>
                <div className='forgetpassword-left-container'>
                  <div className='forgetpassword-image-grid'>
                    {

                      authvideocarddata.map((data, index) => (
                        <AuthImageCardView key={index} image={data.image} text={data.text} />
                      ))
                    }

                  </div>
                </div>

                <div className='forgetpassword-right-container'>
                  <FormContainerView>
                    <div className='forgetpassword-form-text-container'>
                      <span className='forgetpassword-in-screen-signInText'>Forgot Password</span>
                    </div>
                    <div className='forgetpassword-form-text-field-container'>
                      <div className='forgetpassword-form-text-field-wrapup-container'>
                        {data.map((field, index) => (
                          <Textfield
                            key={index}
                            error={errors[field.name]}
                            touched={touched[field.name]}
                            handleSubmit={handleSubmit}
                            setFieldValue={setFieldValue}
                            type={field.type}
                            name={field.name}
                            placeholder={field.placeholder}
                            label={field.label}
                            config={field.MAX_LENGTH}
                            height='100'
                            value={values[field.name]}
                          />
                        ))}
                      </div>

                    </div>
                    <div className='forgetpassword-form-text-field-button-container'>
                      <AuthButton text='Generate OTP' action={handleSubmit} bgcolor='var(--primary-color)' color='var(--inside-primary-color)' />
                    </div>

                  </FormContainerView>
                </div>
              </AuthMainContainerView>
            </div>
          )}
        </Formik>
      </BackgroundImage>
    </>
  );
};

export default ForgetPasswordScreenView;
