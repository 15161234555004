
import React from 'react';
import '../Styles/PaymentLoaderStyles.css'

const PaymentLoaderView = () => {
  return (
    <div className='payment-loader'>
      <div className='payment-circle' ></div>
    </div>
  );
};

export default PaymentLoaderView;
