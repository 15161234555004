import '../Styles/AddToCartCourseCardStyles.css'
import sampleImage from '../../../new_assets/img/auth-img-grid3.jpg'
import Video_Svg from './../../../assets/svg/Video_Svg';
import Document_Svg from '../../../assets/svg/Document_Svg';
import Assessment_Svg from '../../../assets/svg/Assessment_Svg';
import { cartItemCount, removeFromCart, sessionDetails } from '../../../redux/action';
import { CountryToCurrency } from '../../../helper/country_to_currency';
import { API_URL } from '../../../api/URL/Api_Route';
import { useDispatch, useSelector } from 'react-redux';
import Configuration from '../../../config/configuration';
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
const AddToCartCourseCardView = (props) => {

    const navigate = useNavigate();

    // const addToCardData = props?.addToCardData
    // const countryCode = props?.countryCode

    const { addToCardData, countryCode, getCartData } = props;
    const dispatch = useDispatch()

    const countryPrice = CountryToCurrency(countryCode);

    console.log("countryPrice check cartCardView --  ", countryPrice)

    const { session_details, token, cart_item_count } = useSelector(
        (state) => state.applicationState
    );

    /* Remove items from cart handler */
    const removeCart = (course_id, count) => {
        let payload = {
            method: 'delete',
            baseURL: Configuration.BASEURL + Configuration.SERVICEURL.PURCHASE_SERVICE,
            url: API_URL.remove_from_cart,
            data: {
                UserID: session_details?.sub,
                CourseID: course_id,
            },
        };
        ApiRequestMethod(payload)
            .then(async (response) => {
                console.log("removeCart response --- ", response);
                if (response.status === 200) {
                    const courseIds = Array.isArray(course_id) ? course_id : [course_id];
                    courseIds.map((id) => {
                        console.log("Removing CourseID === >>>> ", id);
                        dispatch(removeFromCart(id));
                    });
                    dispatch(cartItemCount({ cart_item_count: count ? 0 : cart_item_count - 1 }));
                    getCartData();
                    if (!count) {
                        toast.dismiss();
                        toast("Item removed from cart");
                    }
                } else {
                    toast.dismiss();
                    toast("Item not Deleted");
                }
            })
            .catch((error) => {
                console.log("removeCart error", error);
                //   set_is_network_error(true);
            });
    };

    /* handle cart item card click */
    const handleClickItemCard = (selectedCourseID) => {
        navigate(`/course_video/${selectedCourseID}`)
    };

    const showThumbnailImage = (AssetPath, Image) => {
        return (
            `${Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE}assert/image?id=${encodeURIComponent(
                `${AssetPath.replace(/\/$/, '')}/${Image.replace(/^\//, '')}`
            )}`
        );
    }
    return (
        <>
            {addToCardData.map((value, index) => (
                <div className='course-cart-main-container' key={index}>
                    <div className='course-cart-main-container-wrapup'>
                        <div className='course-cart-image-container'>
                            <img src={showThumbnailImage(value?.data?.AssetPath, value?.data?.Image)} crossOrigin="anonymous" />
                        </div>
                        <div className='course-cart-main-content-container' onClick={() => handleClickItemCard(value?.data?._id)}>
                            <div className='course-cart-main-content-container-wrapup'>
                                <div className='course-cart-main-content-container-part1'>
                                    <span className='course-cart-topic-name'>{value?.data?.CourseName}</span>
                                    <span className='course-cart-topic-name-amount'>{countryPrice} {value?.data?.Price[countryPrice]}</span>
                                </div>
                                <div className='course-cart-main-content-container-part2'>
                                    <div className='course-cart-subject-name-container'>
                                        <span className='course-cart-subject-name'>
                                            {value?.data?.Subject.slice(0, 3)}
                                        </span>
                                    </div>
                                </div>
                                {/* <div className='course-cart-main-content-container-part3'>
                                    <span className='course-cart-main-container-part3-text-styling'> <Video_Svg color={"var(--grey-color)"} width={"16"} height={"13"} /> {value?.numberOfVideos} Videos</span>
                                    <span className='course-cart-main-container-part3-text-styling'> <Document_Svg color={"var(--grey-color)"} width={"16"} height={"13"} /> {value?.numberOfDocuments} Document</span>
                                    <span className='course-cart-main-container-part3-text-styling'> <Assessment_Svg color={"var(--grey-color)"} width={"16"} height={"13"} /> {value?.numberOfAssessments} Assessment</span>
                                </div> */}
                            </div>
                        </div>
                        <div className='course-cart-buy-remove-container'>
                            <span className='course-cart-price-styling'>{countryPrice} {value?.data?.Price[countryPrice]}</span>
                            <span className='course-cart-price-styling-remove' onClick={() => removeCart(value?.data?._id)}>Remove</span>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default AddToCartCourseCardView;