import { createReducer } from "@reduxjs/toolkit";
import { INITIAL_STATE, REDUX_ACTION } from "./action";

const applicationReducer = createReducer(INITIAL_STATE, (builder) => {
 builder.addCase(REDUX_ACTION.WEB_INIT, (state, action) => {
  state.token = action.token;
 });
 builder.addCase(REDUX_ACTION.LOGIN, (state, action) => {
  state.token = action.token;
 });


 builder.addCase(REDUX_ACTION.SHOW_BANNER, (state, action) => {
  state.showbanner = action.showbanner;
 });


 builder.addCase(REDUX_ACTION.LOGOUT, (state, action) => {
  state.token = INITIAL_STATE.token;
 });
 builder.addCase(REDUX_ACTION.TAB_ID, (state, action) => {
  state.tab_Id = action.tab_Id;
 });
 builder.addCase(REDUX_ACTION.CART_ITEM_COUNT, (state, action) => {
  state.cart_item_count = action.cart_item_count;
 });
 builder.addCase(REDUX_ACTION.USER_DETAILS, (state, action) => {
  state.user_details = action.user_details;
 });
 builder.addCase(REDUX_ACTION.SESSION_DETAILS, (state, action) => {
  state.session_details = action.session_details;
 });
 /* added by samir */
 builder.addCase(REDUX_ACTION.ADD_TO_CART, (state, action) => {
  state.cartDetails = state.cartDetails || [];
  if (!state.cartDetails.includes(action.itemId)) {
    state.cartDetails.push(action.itemId);
  }
});
builder.addCase(REDUX_ACTION.REMOVE_FROM_CART, (state, action) => {
  state.cartDetails = state.cartDetails || [];
  console.log("Before removal:", state.cartDetails);
  state.cartDetails = state.cartDetails.filter(id => id !== action.itemId);
  console.log("After removal:", state.cartDetails);
});

builder.addCase(REDUX_ACTION.SET_TRENDING_COURSES, (state, action) => {
  state.course = {}; // Initialize or reset the course object
  action.courses.forEach(course => {
    state.course[course._id] = { courseDetails: course };
  });
});

builder.addCase(REDUX_ACTION.UPDATE_COURSE_DETAILS, (state, action) => {
  const { courseId, chapters, topics } = action.payload;

  // Initialize course entry if not present
  if (!state.course[courseId]) {
    state.course[courseId] = { courseDetails: {}, chapter: {} };
  }

  // Ensure the `chapter` object is initialized
  if (!state.course[courseId].chapter) {
    state.course[courseId].chapter = {};
  }

  // Update chapters
  chapters.forEach((chapter) => {
    const chapterId = chapter._id;
    
    // Initialize chapter if it does not exist
    if (!state.course[courseId].chapter[chapterId]) {
      state.course[courseId].chapter[chapterId] = {
        chapterDetails: chapter,
        topic: {},
      };
    } else {
      // Update chapter details if chapter already exists
      state.course[courseId].chapter[chapterId].chapterDetails = chapter;
    }
  });

  // Update topics within the appropriate chapter
  topics.forEach((topic) => {
    const chapterId = topic.ChapterId;
    const topicId = topic._id;

    // Ensure the chapter exists before setting topics
    if (!state.course[courseId].chapter[chapterId]) {
      state.course[courseId].chapter[chapterId] = { chapterDetails: {}, topic: {} };
    }

    // Ensure the topic object exists within the chapter
    if (!state.course[courseId].chapter[chapterId].topic) {
      state.course[courseId].chapter[chapterId].topic = {};
    }

    // Set topic details
    state.course[courseId].chapter[chapterId].topic[topicId] = {
      topicDetails: topic,
    };
  });
});

builder.addCase(REDUX_ACTION.UPDATE_CHAPTER_TOPICS, (state, action) => {
  const { courseId, chapterId, topics } = action.payload;

  // Check if course exists in state
  if (!state.course[courseId]) {
    state.course[courseId] = { courseDetails: {}, chapter: {} };
  }

  // Check if chapter exists within the course
  if (!state.course[courseId].chapter[chapterId]) {
    state.course[courseId].chapter[chapterId] = { chapterDetails: {}, topic: {} };
  }

  // Ensure the topic object exists within the chapter
  if (!state.course[courseId].chapter[chapterId].topic) {
    state.course[courseId].chapter[chapterId].topic = {};
  }

  // Update topics for the chapter
  topics.forEach((topic) => {
    const topicId = topic._id;
    state.course[courseId].chapter[chapterId].topic[topicId] = {
      topicDetails: topic,
    };
  });
});


builder.addCase(REDUX_ACTION.SET_PURCHASED_COURSES, (state, action) => {
  state.purchased_course_details = action.purchased_course_details.map((course) => ({
    courseId: course.Course.CourseID,
    validity: course.Course.Validity,
    userId: course.UserID,
    orderStatus: course.OrderStatus,
    paymentId: course.PaymentID,
    purchasedOn: course.PurchasedOn,
  }));
});


// /* video progress tracking */
// builder.addCase(REDUX_ACTION.START_PROGRESS, (state, action) => {
//   const { courseId, taskId } = action.payload;

//   // Ensure the course entry exists in the state
//   if (!state.course[courseId]) {
//     state.course[courseId] = { courseDetails: {}, progress: {} };
//   }

//   // Ensure the progress object is initialized
//   if (!state.course[courseId].progress) {
//     state.course[courseId].progress = {};
//   }

//   // Initialize the progress for the specific task
//   if (!state.course[courseId].progress[taskId]) {
//     state.course[courseId].progress[taskId] = {
//       completed: false,
//       progress: 0,
//     };
//   }
// });

// builder.addCase(REDUX_ACTION.UPDATE_PROGRESS, (state, action) => {
//   const { courseId, taskId, progress } = action.payload;

//   // Ensure the course and task entry exist
//   if (
//     state.course[courseId] &&
//     state.course[courseId].progress &&
//     state.course[courseId].progress[taskId]
//   ) {
//     state.course[courseId].progress[taskId].progress = progress;
//   }
// });

// builder.addCase(REDUX_ACTION.COMPLETE_PROGRESS, (state, action) => {
//   const { courseId, taskId } = action.payload;

//   // Ensure the course and task entry exist
//   if (
//     state.course[courseId] &&
//     state.course[courseId].progress &&
//     state.course[courseId].progress[taskId]
//   ) {
//     state.course[courseId].progress[taskId] = {
//       completed: true,
//       progress: 100,
//     };
//   }
// });

});

export default applicationReducer;
