import React, { useState } from 'react';
import '../Styles/ExistingSessionAlertStyles.css'
import Dialogpagecontroller from '../Controller/ExistingSessionAlertController';
import { data } from '../Model/ExistingSessionAlertModel';
import LoaderView from '../../../CommonComponents/LoaderComponet/View/LoaderView';

const ExistingalertView = () => {

    const {
        onSubmit,
        onConfirmSubmit,
        isLoading,
        setIsLoading,
    } = Dialogpagecontroller();

    return (
        <>
            <LoaderView isLoading={isLoading} />
            <div className='existing-main'>
                <div className='existing-body'>
                    <div className='existing-boxcontainer'>
                        <div className='existing-qnbox'>
                            <p className='existing-qn' style={{ textAlign: 'center' }}>{data.qn} </p>
                        </div>
                        <div className='existing-ansbox'>
                            <div className='existing-yesbox'>
                                <div className='existing-buttonbox'>
                                    <div className='existing-button' onClick={onConfirmSubmit}> Yes</div>
                                </div>
                            </div>
                            <div className='existing-nobox'>
                                <div className='existing-buttonbox'>
                                    <div className='existing-button' onClick={onSubmit}> No </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default ExistingalertView;