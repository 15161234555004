import '../Styles/TextfieldStyles.css'
import { AiOutlineExclamationCircle } from "react-icons/ai";

const Textfield = ({
 error,
 touched,
 handleSubmit,
 setFieldValue,
 type,
 placeholder,
 name,
 label,
 config,
 height,
 value
}) => {

     console.log("value -- ", value)
 return (
 <div style={{width:'100%',height:`${height}%`,display:'flex',flexDirection:'column'}}>
  <div style={{height:'30%',width:'100%'}}>
  <label className='textfield-field-tf' >{label} <span className='requiredFieldSymbol-tf'>*</span></label>
  </div>
  <div style={{width:'100%',height:'50%'}}>
      <input  style={{height:'100%',width:'100%'}}
      type={type}
           placeholder={placeholder}
           name={name}
           className={`${'fieldInput-tt'} ${error && touched ? 'fieldInputError' : ""}`}
           onChange={(val) => {
                 setFieldValue(name, val.target.value);
                }}
                onKeyDown={(e) => {
                 if (e.key === "Enter") {
                  e.preventDefault();
                  handleSubmit();
                 }
                }}
                maxLength={config}
                value={value || ""}
           />
    </div>
    <div className='formikErrorText-tf'  >
     <div style={{display:'block'}}>{error && touched && error && <AiOutlineExclamationCircle size={14} />}</div>
     <div style={{display:'block'}}>{error && touched && error}</div>
    </div> 
 </div>

    






 );
};
export default Textfield;
