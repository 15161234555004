import * as React from "react";
const SearchIcon = (props) => (
  <svg
    width={19}
    height={18}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 16.4062L13.8983 12.6891M13.8983 12.6891C14.5999 12.0533 15.1565 11.2984 15.5362 10.4677C15.9159 9.63692 16.1113 8.74652 16.1113 7.84732C16.1113 6.94812 15.9159 6.05772 15.5362 5.22696C15.1565 4.39621 14.5999 3.64137 13.8983 3.00553C13.1967 2.3697 12.3638 1.86533 11.4471 1.52122C10.5304 1.17711 9.54789 1 8.55566 1C7.56344 1 6.58093 1.17711 5.66424 1.52122C4.74754 1.86533 3.91461 2.3697 3.213 3.00553C1.79604 4.28966 1 6.0313 1 7.84732C1 9.66334 1.79604 11.405 3.213 12.6891C4.62996 13.9732 6.55178 14.6946 8.55566 14.6946C10.5596 14.6946 12.4814 13.9732 13.8983 12.6891Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SearchIcon;
