import { useEffect, useState } from "react";
import { ApiRequestMethod } from "../../../api/request/Api_Calls";
import { API_URL } from "../../../api/URL/Api_Route";
import Configuration from "../../../config/configuration";
import { useDispatch } from "react-redux";
import "../Styles/PurchasedVideoExpandStyles.css";
import Video_Svg from "../../../assets/svg/Video_Svg";
import { showbanner } from "../../../redux/action";
import toast from "react-hot-toast";

const PurchasedVideoExpandView = ({ TopicID, setVideoData }) => {
    const [videoDisplayData, setVideoDisplayData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const payload = {
                method: "post",
                url: API_URL.getVideoByTopic,
                baseURL: Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE,
                data: { TopicID },
            };

            ApiRequestMethod(payload)
                .then((res) => {
                    if (res?.status === 200 && res?.data?.data?.Videos?.length) {
                        setVideoDisplayData(res?.data?.data);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.error("Error fetching topic data:", err);
                    if (err?.response?.status === 404) {
                        dispatch(showbanner({ showbanner: true }));
                    }
                    setIsLoading(false);
                });
        };

        fetchData();
    }, [TopicID, dispatch]);

    const handleVideoOption = (value, AssertPath) => {
        const videoPath = `${AssertPath}${value.VideoPath}${value.Video}`;
        setVideoData(videoPath);
        window.scrollTo(0, 0);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-end" }}>
            {isLoading ? (
                <p style={{ width: '100%', display: 'flex', justifyContent: 'center', opacity: '0.3' }}>Loading videos...</p>
            ) : videoDisplayData?.Videos?.length ? (
                videoDisplayData?.Videos?.map((value, index) => (
                    <div
                        className="p-expanded-content"
                        style={{ cursor: "pointer" }}
                        key={index}
                    onClick={() => handleVideoOption(value, videoDisplayData?.AssertPath)}
                    >
                        <div style={{ width: "97%", display: "flex", height: "100%", alignItems: "center", flexDirection: "row" }}>
                            <div style={{ width: "94%" }}>
                                <span className="p-topic-styling-details"  >
                                    <Video_Svg color={"var(--grey-color)"} height={"25"} width={"15"} /> &nbsp;&nbsp; {value?.VideoName}
                                </span>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p style={{ textAlign: "center", width: "100%", marginTop: "10px" }}>Video will be updated soon</p>
            )}
        </div>
    );
};

export default PurchasedVideoExpandView;
