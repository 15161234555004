import * as React from "react";
const BellSvg = (props) => (
  <svg
    width={22}
    height={26}
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9395 25.0543C11.7351 25.0543 12.4982 24.7261 13.0608 24.1419C13.6234 23.5578 13.9395 22.7655 13.9395 21.9395H7.93945C7.93945 22.7655 8.25552 23.5578 8.81813 24.1419C9.38074 24.7261 10.1438 25.0543 10.9395 25.0543Z"
      fill="#21373F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0527 2.71585L9.85723 2.93913C8.50117 3.19458 7.28223 3.87538 6.40671 4.8663C5.53119 5.85722 5.05288 7.09738 5.05273 8.37689C5.05273 9.24782 4.85173 11.4238 4.36423 13.5664C4.12423 14.6301 3.80023 15.7382 3.36973 16.6979H18.7357C18.3052 15.7382 17.9827 14.6315 17.7412 13.5664C17.2537 11.4238 17.0527 9.24782 17.0527 8.37689C17.0522 7.09762 16.5738 5.85778 15.6983 4.86715C14.8228 3.87652 13.604 3.19592 12.2482 2.94051L11.0527 2.71585ZM20.3827 16.6979C20.7172 17.3178 21.1042 17.8087 21.5527 18.0847H0.552734C1.00123 17.8087 1.38823 17.3178 1.72273 16.6979C3.07273 14.2016 3.55273 9.59731 3.55273 8.37689C3.55273 5.02076 6.13273 2.21936 9.56023 1.58003C9.5393 1.3872 9.56231 1.19245 9.62778 1.00834C9.69326 0.824234 9.79974 0.654859 9.94037 0.51114C10.081 0.36742 10.2526 0.252546 10.4442 0.173927C10.6358 0.0953071 10.8431 0.0546875 11.0527 0.0546875C11.2624 0.0546875 11.4696 0.0953071 11.6612 0.173927C11.8528 0.252546 12.0245 0.36742 12.1651 0.51114C12.3057 0.654859 12.4122 0.824234 12.4777 1.00834C12.5432 1.19245 12.5662 1.3872 12.5452 1.58003C14.2407 1.89886 15.7648 2.74963 16.8596 3.98827C17.9544 5.2269 18.5525 6.77729 18.5527 8.37689C18.5527 9.59731 19.0327 14.2016 20.3827 16.6979Z"
      fill="#21373F"
    />
  </svg>
);
export default BellSvg;
