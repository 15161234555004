import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import '../Styles/SignupScreenStyles.css'
import SignupScreenController from "../Controller/SignupScreenController";
import { ValidationSchema, passworddata, data, UserTypeData } from "../Model/SignupScreenModel";
import NetworkError from './../../../component/javascript/Network_Error';
import Textfield from './../../../CommonComponents/TextboxComponent/View/TextfieldView';
import Passwordfield from './../../../CommonComponents/PasswordboxComponent/View/PasswordfieldView';
import HeaderSectionView from './../../../CommonComponents/HeaderComponent/View/HeaderSectionView';
import AuthMainContainerView from './../../../CommonComponents/AuthMainContainer/View/AuthMainContainerView';
import AuthButton from "../../../CommonComponents/AuthButton/View/AuthButton";
import BackgroundImage from './../../../CommonComponents/AuthBackgroundImage/View/AuthBackgroundImage';
import FormContainerView from "../../../CommonComponents/FormContainerComponent/View/FormContainerView";
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import LoaderView from "../../../CommonComponents/LoaderComponet/View/LoaderView";


const SignupScreenView = () => {
  const location = useLocation();
  let queryParams = queryString.parse(location.search);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767.5);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767.5);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [selectedRole, setSelectedRole] = useState(isMobile ? null : 1);

  const {
    is_small_screen,
    is_network_error,
    isLoading,
    passwordIconType,
    passwordEyeIcon,
    confirmPasswordIconType,
    confirmPasswordEyeIcon,
    set_is_network_error,
    setIsLoading,
    onSubmit,
    passwordEyeIconChange,
    confirmPasswordEyeIconChange,
    Navigation,
  } = SignupScreenController();

  if (is_network_error) {
    return <NetworkError callBack={() => set_is_network_error(false)} />;
  }

  return (
    <>
      <LoaderView isLoading={isLoading} callBack={() => setIsLoading(false)} />
      <BackgroundImage>
        <Formik
          initialValues={{
            user_email: queryParams.email || "",
            user_name: queryParams.name || "",
            password: "",
            confirm_password: "",
            // term_and_condition: false,
          }}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >

          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <div className={`signup-screen-view-outLine ${selectedRole ? "show-right" : ""}`}>
              <HeaderSectionView />
              <AuthMainContainerView>
                <div className={`signup-left-container ${selectedRole ? "hide-form" : ""}`}>
                  <div className='signup-image-grid'>
                    <div className="signup-left-text-main-container">
                      <div className="signup-left-text-main-container-part1">
                        {
                          isMobile ?
                            (
                              <p className="selet-who-you-are-text">Select <br />Who you Are?</p>
                            )
                            :
                            (
                              <p className="selet-who-you-are-text">Select  Who <br /> you Are</p>
                            )
                        }
                      </div>
                      <div className="signup-left-text-main-container-part2"><p className="signup-description">Cognitive ED is a one-stop solution that bridges the gaps between students, teachers, and organizations through a unified platform. It leverages cutting-edge AI insights to simplify learning processes and drives focused growth by tailoring development to individual and group needs.</p>
                      </div>
                      <div className="signup-role-container">
                        {UserTypeData.map((role, index) => (
                          <div
                            key={index}
                            className="signup-role-button"
                            onClick={() => role.enable === 1 && setSelectedRole(role)}
                            style={{ opacity: role.enable === 1 ? 1 : 0.5, cursor: role.enable === 1 ? 'pointer' : 'not-allowed' }}
                          >
                            {role.UserType}
                          </div>
                        ))}

                      </div>
                    </div>

                  </div>
                </div>

                {/* <div className='signup-right-container'> */}
                <div className={`signup-right-container ${selectedRole || Object.keys(queryParams).length > 0 ? "show-form" : ""}`} >
                  <FormContainerView>
                    <div className='signup-form-text-container'>
                      <span className='signup-in-screen-signInText'>Sign Up</span>
                    </div>
                    <div className='signup-form-text-field-container'>
                      <div className='signup-form-text-field-wrapup-container'>
                        <div className="signup-form-individual-wrap-container">
                          {data.map((field, index) => (
                            <Textfield
                              key={index}
                              error={errors[field.name]}
                              touched={touched[field.name]}
                              handleSubmit={handleSubmit}
                              setFieldValue={setFieldValue}
                              type={field.type}
                              name={field.name}
                              placeholder={field.placeholder}
                              label={field.label}
                              config={field.MAX_LENGTH}
                              height='25'
                              value={values[field.name]}
                            />
                          ))}

                          {passworddata.map((field, index) => (
                            <Passwordfield
                              key={index}
                              error={errors[field.name]}
                              touched={touched[field.name]} handleSubmit={handleSubmit}
                              setFieldValue={setFieldValue}
                              type={field.type}
                              name={field.name}
                              placeholder={field.placeholder}
                              label={field.label}
                              config={field.MAX_LENGTH}
                              passwordIconType={field.name === "password" ? passwordIconType : confirmPasswordIconType}
                              passwordEyeIconChange={field.name === "password" ? passwordEyeIconChange : confirmPasswordEyeIconChange}
                              height='25'
                            />
                          ))}

                        </div>

                       { /*<div className="terms-and-condition-wrap-container" >

                          <div className="checkboxfieldOutline" >
                            <input
                              type="checkbox"
                              name="term_and_condition"
                              className='checkbox'
                              onChange={(val) => {
                                setFieldValue("term_and_condition", val.target.checked);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  handleSubmit();
                                }
                              }}
                            />

                            <span className='sign-up-screen-checkboxText'>
                              I have read and agree to the{" "}
                              <span tabIndex={0} className='sign-up-screen-termOfService'
                              // onClick={()=>{navigate('/termsandcondition')}}
                              >
                                Terms and Conditions
                              </span>
                            </span>
                            <br />
                          </div>

                          <div className="formikErrorText-tf">
                            {errors.term_and_condition && touched.term_and_condition && (
                              <>
                                <div style={{ display: "inline-block", marginRight: "5px" }}>
                                  <AiOutlineExclamationCircle size={14} />
                                </div>
                                <span style={{ display: "inline-block" }}>
                                  {errors.term_and_condition}
                                </span>
                              </>
                            )}
                          </div>


                        </div>*/}

                      </div>
                    </div>
                    <div className='signup-form-text-field-button-container'>
                      <AuthButton text='Sign Up' action={handleSubmit} bgcolor='var(--primary-color)' color='var(--inside-primary-color)' />
                      <AuthButton text='Sign In' action={Navigation} bgcolor='var(--inside-primary-color)' color='var(--primary-color)' />
                    </div>
                  </FormContainerView>
                </div>
              </AuthMainContainerView>
            </div>

          )}
        </Formik>
      </BackgroundImage>
    </>
  );
};

export default SignupScreenView;

