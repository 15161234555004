import * as React from "react";
import "./semicircle.css";




const Semicircleview = (props) => {
  const videoRef = React.useRef(null);
  
  React.useEffect(() => {
    console.log("entry.isIntersecting: qwertyuio")
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (videoRef.current) {
          console.log("entry.isIntersecting: ", entry.isIntersecting)
          if (entry.isIntersecting) {
            videoRef.current.play(); // Auto-play when visible
          } else {
            videoRef.current.pause(); // Pause when out of view
          }
        }
      },
      { threshold: 0.25 } //0.25 
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return(
  <div className="semi-circle-container"  data-aos="fade up">
 
    <svg
    className="main-svg"
      viewBox="0 0 544 1104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      preserveAspectRatio="none"
    >
      <path
        className="line-1"
        opacity={0.7}
        d="M2.00015 10C2.00015 4.47719 6.47833 -0.00942182 12.0003 0.0906184C95.6753 1.60655 177.958 22.1309 252.603 60.1644C327.248 98.1979 392.217 152.702 442.626 219.505C445.953 223.914 444.955 230.174 440.487 233.42L434.339 237.888C429.871 241.134 423.624 240.137 420.293 235.731C372.447 172.45 310.837 120.812 240.073 84.7562C169.309 48.7002 91.3193 29.2081 12.0003 27.6954C6.47844 27.5901 2.00015 23.1229 2.00015 17.6001L2.00015 10Z"
        fill="#0D5F67"
      />
      <path
        className="line-2"
        opacity={0.7}
        d="M484.343 323.208C489.35 320.876 495.307 323.042 497.548 328.09C521.505 382.073 536.643 439.552 542.385 498.333C542.922 503.829 538.804 508.648 533.298 509.086L525.722 509.687C520.217 510.125 515.409 506.015 514.867 500.518C509.392 445.016 495.097 390.742 472.523 339.743C470.287 334.692 472.447 328.747 477.454 326.416L484.343 323.208Z"
        fill="#0D5F67"
      />
      <path
        className="line-3"
        opacity={0.7}
        d="M532.628 602.649C538.127 603.165 542.175 608.042 541.56 613.531C535.136 670.802 519.783 726.715 496.058 779.236C493.785 784.269 487.813 786.395 482.822 784.031L475.954 780.777C470.962 778.413 468.842 772.454 471.111 767.419C493.464 717.808 507.959 665.02 514.076 610.951C514.697 605.463 519.563 601.422 525.061 601.938L532.628 602.649Z"
        fill="#0D5F67"
      />
      <path
        className="line-4"
        opacity={0.7}
        d="M437.366 864.223C441.191 867.599 441.869 873.32 438.861 877.441C389.312 945.324 324.892 1001.03 250.483 1040.27C176.379 1079.35 94.3926 1101.02 10.7471 1103.71C5.22707 1103.89 0.686345 1099.47 0.608815 1093.95L0.502126 1086.35C0.424596 1080.83 4.83982 1076.3 10.3589 1076.09C89.6492 1073.17 167.357 1048.9 237.609 1011.86C307.519 974.989 368.149 922.82 415.009 859.294C418.543 854.503 425.432 853.689 429.895 857.629L437.366 864.223Z"
        fill="#0D5F67"
      />
    </svg>

    <div className="semi-circle-img video" >
      
       <video ref={videoRef} autoPlay loop muted playsInline style={{opacity:'0.9'}}> 
         <source src='https://dgptv56br1zqm.cloudfront.net/sampleVideo.mp4' type="video/mp4" /> 
       
       </video> 
    </div>


<div className="semi-circle-text text-1" >
                <div className="column-1">
                    <p className="column-text" style={{color: "#191414CC"}}>
                        One-Stop Solution <span class="line-break"></span>
                         for All
                        
                    </p>
                  
                </div>

                {/* <div className="border-left-1"></div> */}

               
                <div className="secondary-column-1">
                    <p className="secondary-text">
                    Designed For  
                     <span className="sub-text"> Students, Teachers, And  <span class="line-break"></span>Organizations</span> To Simplify Learning.
                    </p>
                </div>
</div>

            <div className="semi-circle-text text-2">
                <div className="column-2">
                    <p className="column-text" style={{color:'rgba(2, 28, 43, 1)'}}>
                        AI-Powered Learning <span class="line-break"></span>
                        Insights
                    </p>
                </div>

                <div className="border-left-2"></div>

                <div className="secondary-column-2">
                    <p className="secondary-text">
                        Leverage AI To Assess Challenging Areas
                        
                        <span class="line-break"></span> Continuous <span className="sub-text">Improvement.</span>
                    </p>
                </div>
            </div>

            <div className="semi-circle-text text-3">
                <div className="column-1">
                    <p className="column-text" style={{color: "#191414CC"}}>
                        Interactive Video <span class="line-break"></span>
                        Lessons
                    </p>
                </div>

                <div className="border-left-1"></div>

                <div className="secondary-column-1">
                    <p className="secondary-text">
                        Engage With <span className="sub-text" style={{ fontWeight: 600 }}>Videos Designed</span> To
                          
                        <span class="line-break"></span> <span className="sub-text" style={{ fontWeight: 600 }}>Simplify</span> Even The
                        <span className="sub-text" style={{ fontWeight: 600 }}> Most Complex </span>
                      
                       
                        Topics.
                    </p>
                </div>
            </div>

</div>

)}

export default Semicircleview;