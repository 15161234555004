import * as Yup from 'yup';

export const initialValues = {
  new_password: "",
  confirm_password: "",
};

export const passworddata = [
  { type: "text", placeholder: "Enter your new password", name: "new_password", label: "New Password", errors: "new_password", MAX_LENGTH: 20, passwordIconType: "password" },
  { type: "text", placeholder: "Enter your confirm password", name: "confirm_password", label: "Confirm Password", errors: "confirm_password", MAX_LENGTH: 20, passwordIconType: "password" },
]

export const ValidationSchema = Yup.object().shape({
  new_password: Yup.string()
    .required("Password is a required field")
    .matches(/\w*[a-z]\w*/, "Password must have a small letter")
    .matches(/\w*[A-Z]\w*/, "Password must have a capital letter")
    .matches(/\d/, "Password must have a number")
    .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, "Password must have a special character")
    .min(8, "Password must be at least 8 characters")
    .test("noSpaces", "Please enter a valid password", (value) => !/\s/.test(value)),
  confirm_password: Yup.string()
    .required("Confirm Password is a required field")
    .oneOf([Yup.ref("new_password"), null], "Passwords do not match")
});

