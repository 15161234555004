import React from "react";
import '../Styles/AuthBackgroundImage.css';
import Compass from '../../../new_assets/Svg/Phy.Svg';
import Chem from '../../../new_assets/img/sty.png'; 

const BackgroundImage = ({ children }) => {
  return (
    <div className="auth-background-image">
      {children}
      <img src={Compass} className="auth-icon icon-1" alt="Phy" />
      <img src={Chem} className="auth-icon icon-2" alt="Compass" />
    </div>
  );
};

export default BackgroundImage;
