import '../Styles/SigninScreenStyles.css'
import { Formik } from "formik";
import { data, initialValues, passworddata, ValidationSchema } from "../Model/SigninScreenModel";
import { authvideocarddata } from '../../../CommonComponents/AuthImageCard/Model/AuthImageCardModel'
import SigninScreenController from "../Controller/SigninScreenController";
import NetworkError from './../../../component/javascript/Network_Error';
import Textfield from './../../../CommonComponents/TextboxComponent/View/TextfieldView';
import Passwordfield from './../../../CommonComponents/PasswordboxComponent/View/PasswordfieldView';
import AuthImageCardView from '../../../CommonComponents/AuthImageCard/View/AuthImageCardView';
import BackgroundImage from '../../../CommonComponents/AuthBackgroundImage/View/AuthBackgroundImage';
import AuthButton from '../../../CommonComponents/AuthButton/View/AuthButton';
import HeaderSectionView from '../../../CommonComponents/HeaderComponent/View/HeaderSectionView';
import AuthMainContainerView from '../../../CommonComponents/AuthMainContainer/View/AuthMainContainerView';
import FormContainerView from '../../../CommonComponents/FormContainerComponent/View/FormContainerView';
import LoaderView from './../../../CommonComponents/LoaderComponet/View/LoaderView';

const SigninScreenView = () => {
  const {
    is_small_screen,
    is_landscape,
    is_network_error,
    isLoading,
    passwordIconType,
    passwordEyeIcon,
    confirmPasswordIconType,
    confirmPasswordEyeIcon,
    set_is_network_error,
    setIsLoading,
    onSubmit,
    passwordEyeIconChange,
    confirmPasswordEyeIconChange,
    forgetPageNavigation,
    signupPageNavigation
  } = SigninScreenController();

  if (is_network_error) {
    return <NetworkError callBack={() => set_is_network_error(false)} />;
  }

  return (
    <>
      <LoaderView isLoading={isLoading} callBack={() => setIsLoading(false)} />
      <BackgroundImage>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <div className='signin-screen-view-outLine' >
              <HeaderSectionView />
              <AuthMainContainerView>
                <div className='signin-left-container'>
                  <div className='signin-image-grid'>
                    {
                      authvideocarddata.map((data, index) => (
                        <AuthImageCardView key={index} image={data.image} text={data.text} />
                      ))
                    }
                  </div>
                </div>

                <div className='signin-right-container'>
                  <FormContainerView>
                    <div className='form-text-container'>
                      <span className='signin-in-screen-signInText'>Sign In</span>
                    </div>
                    <div className='form-text-field-container'  >
                      <div className='form-text-field-wrapup-container' >
                        <div style={{ height: '85%', display: 'flex', flexDirection: 'column' }}>
                          {data.map((field, index) => (
                            <Textfield
                              key={index}
                              error={errors[field.name]}
                              touched={touched[field.name]}
                              handleSubmit={handleSubmit}
                              setFieldValue={setFieldValue}
                              type={field.type}
                              name={field.name}
                              placeholder={field.placeholder}
                              label={field.label}
                              config={field.MAX_LENGTH}
                              height='50'
                              value={values[field.name]}
                            />
                          ))}
                          {passworddata.map((field, index) => (
                            <Passwordfield
                              key={index}
                              error={errors[field.name]}
                              touched={touched[field.name]} handleSubmit={handleSubmit}
                              setFieldValue={setFieldValue}
                              type={field.type}
                              name={field.name}
                              placeholder={field.placeholder}
                              label={field.label}
                              config={field.MAX_LENGTH}
                              passwordIconType={field.name === "password" ? passwordIconType : confirmPasswordIconType}
                              passwordEyeIconChange={field.name === "password" ? passwordEyeIconChange : confirmPasswordEyeIconChange}
                              height='50'
                            />
                          ))}
                        </div>

                        <div
                          tabIndex={0}
                          style={{ height: '10%' }}
                          className='signin-in-screen-forgetPassword'
                        >
                          <a onClick={forgetPageNavigation} style={{ cursor: 'pointer' }}> Forgot password</a>
                        </div>
                      </div>
                    </div>
                    <div className='form-text-field-button-container'>
                      <AuthButton text='Sign In' action={handleSubmit} bgcolor='var(--primary-color)' color='var(--inside-primary-color)' />
                      <AuthButton text='Sign Up' action={signupPageNavigation} bgcolor='var(--inside-primary-color)' color='var(--primary-color)' />
                    </div>
                  </FormContainerView>
                </div>
              </AuthMainContainerView>
            </div>
          )}
        </Formik>
      </BackgroundImage>
    </>
  )

}
export default SigninScreenView;