import * as Yup from "yup";
import img1 from '../../../new_assets/img/auth-img-grid1.jpg'
import img2 from '../../../new_assets/img/auth-img-grid2.jpg'
import img3 from '../../../new_assets/img/auth-img-grid3.jpg'
import img4 from '../../../new_assets/img/auth-img-grid4.jpg'
import img5 from '../../../new_assets/img/auth-img-grid5.jpg'
import img6 from '../../../new_assets/img/auth-img-grid6.jpg'


export const ValidationSchema = Yup.object().shape({
    user_email: Yup.string()
        .required("Email is a required field")
        .test("notEmpty", "Email cannot be empty", (value) => value.trim() !== "")
        .email(" Enter a valid email")
});

export const authvideocarddata = [
    { image: img1, text: "Parent Monitoring" },
    { image: img2, text: "Organization" },
    { image: img3, text: "Students Collabration" },
    { image: img4, text: "Multiple device responses" },
    { image: img5, text: "Organization" },
    { image: img6, text: "Multiple device responses" }
]

export const data = [
    { type: "text", placeholder: "Enter your email", name: "user_email", label: "Email", errors: "user_email", MAX_LENGTH: 50 },
]