import '../Styles/HeaderSectionStyles.css'
import LogoSvg from '../../../new_assets/Svg/LogoSvg';
import { useNavigate } from 'react-router-dom';

const HeaderSectionView = ()=>{

  const navigate = useNavigate()
    return(
         <div className='header-section-view-main-container'>
              <div className='header-section-view-logo-container'>
                <LogoSvg fill="black"style={{color:'rgba(13, 95, 103, 1),',cursor:'pointer'}} onClick={()=>{navigate('/')}}/>
              </div>
            </div>
    )
}

export default HeaderSectionView;