import { useEffect, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import MainHeaderView from "../CommonComponents/MainHeaderComponent/View/MainHeaderView";
import FooterSectionView from "../CommonComponents/FooterComponent/View/FooterSectionView";

// Screen Components
import LandingScreenView from "../ScreenComponents/LandingScreen/Components/View/LandingScreenView";
import SignupScreenView from "../ScreenComponents/AuthScreen/View/SignupScreenView";
import SigninScreenView from "../ScreenComponents/AuthScreen/View/SigninScreenView";
import OtpScreenView from "../ScreenComponents/AuthScreen/View/OtpScreenView";
import ForgetPasswordScreenView from "../ScreenComponents/AuthScreen/View/ForgetpasswordScreenView";
import ResetPasswordScreenView from "../ScreenComponents/AuthScreen/View/ResetPasswordScreenView";
import HomePageView from "../ScreenComponents/HomeScreen/View/HomePageView";
import CourseVideoPageView from "../ScreenComponents/HomeScreen/View/CourseVideoPageView";
import ExistingalertView from "../ScreenComponents/AuthScreen/View/ExistingSessionAlertView";
import Page_Not_Found from "../component/javascript/Page_Not_Found";
import PaymentScreenView from "../ScreenComponents/PaymentScreen/View/PaymentScreenView";
import MyCoursesView from "../ScreenComponents/CourseScreen/View/MyCoursesView";
import MyCourseVideoPageView from "../ScreenComponents/CourseScreen/View/MyCourseVideoView";
import AddToCartView from "../ScreenComponents/CourseScreen/View/AddToCartView";
import AuthenticatedLandingScreen from "../ScreenComponents/AuthenticatedLandingScreen/Components/View/AuthenticatedLandingScreen";

export const ROUTES = {
  HOME: "/",
  COURSES: "/courses",
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  OTP: "/otp",
  FORGET_PASSWORD: "/forgetpassword",
  RESET_PASSWORD: "/resetpassword",
  COURSE_VIDEO: "/course_video/:courseId",
  EXISTING_ALERT: "/existing_alert",
  NOT_FOUND: "*",
  PAYMENT: "/payment",
  ADDTOCART: "/cart",
  MYCOURSES: "/mycourse",
  MY_COURSE_VIDEO: "/mycourse_video/:courseId"
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  return null;
};

const AppRoutes = ({ token }) => {
  const location = useLocation();
  const hideHeaderRoutes = [
    ROUTES.SIGNIN,
    ROUTES.SIGNUP,
    ROUTES.OTP,
    ROUTES.FORGET_PASSWORD,
    ROUTES.RESET_PASSWORD,
    ROUTES.HOME
  ];

  const shouldShowHeader = useMemo(
    () => !hideHeaderRoutes.includes(location.pathname),
    [location.pathname]
  );

  return (
    <>
      {shouldShowHeader && token && <MainHeaderView />}
      <Routes>
        {token ? (
          <>
            {/* <Route path={ROUTES.HOME} element={<HomePageView />} /> */}
            <Route path={ROUTES.HOME} element={<AuthenticatedLandingScreen />} />
            <Route path={ROUTES.COURSES} element={<HomePageView/>}/>
            <Route path={ROUTES.COURSE_VIDEO} element={<CourseVideoPageView />} />
            <Route path={ROUTES.PAYMENT} element={<PaymentScreenView />} />
            <Route path={ROUTES.NOT_FOUND} element={<Page_Not_Found />} />
            <Route path={ROUTES.MY_COURSE_VIDEO} element={<MyCourseVideoPageView />} />
            <Route path={ROUTES.ADDTOCART} element={<AddToCartView />} />
            {/* <Route path={ROUTES.COURSES} element={<MyCoursesView />} /> */}
            <Route path={ROUTES.MYCOURSES} element={<MyCoursesView />} />
          </>
        ) : (
          <>
            <Route path={ROUTES.HOME} element={<LandingScreenView />} />
            <Route path={ROUTES.SIGNIN} element={<SigninScreenView />} />
            <Route path={ROUTES.SIGNUP} element={<SignupScreenView />} />
            <Route path={ROUTES.OTP} element={<OtpScreenView />} />
            <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPasswordScreenView />} />
            <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordScreenView />} />
            <Route path={ROUTES.EXISTING_ALERT} element={<ExistingalertView />} />
            <Route path={ROUTES.NOT_FOUND} element={<Page_Not_Found />} />
            {/* <Route path={ROUTES.ADDTOCART} element={<AddToCartView />} /> */}
            {/* <Route path={ROUTES.COURSES} element={<MyCoursesView />} /> */}
          </>
        )}
      </Routes>
      <FooterSectionView />
    </>
  );
};

export const RouterProvider = ({ token }) => {
  return (
    <Router>
      <ScrollToTop />
      <AppRoutes token={token} />
    </Router>
  );
};
