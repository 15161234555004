import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../api/URL/Api_Route";
import toast from "react-hot-toast";
import queryString from "query-string";
import { ApiRequestMethod } from "../../../api/request/Api_Calls";
import Configuration from "../../../config/configuration";

const ForgetPasswordScreenController = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [is_network_error, set_is_network_error] = useState(false);

  const onSubmit = (form_data) => {
    setIsLoading(true);
    let payload = {
      method: "post",
      baseURL: Configuration.BASEURL + Configuration.SERVICEURL.ACCOUNT_MANAGEMENT_SERVICE,
      url: API_URL.forgetPassword,
      data: { EmailId: form_data.user_email },
    };

    ApiRequestMethod(payload)
      .then((res) => {
        if (res?.status === 200) {
          let data = { id: 101, email: form_data.user_email, navigate_from: "forgetpassword" };

          const queryStringified = queryString.stringify(data);
          navigate(`/otp?${queryStringified}`);
          setIsLoading(false);
          toast("OTP has been sent to user registered Email");
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          console.log(err)
          setIsLoading(false);
          toast("Email Id does not exist");
        }
        else {
          console.log(err)
          setIsLoading(false);
          set_is_network_error(true);
        }

      });
  };

  return {
    isLoading,
    setIsLoading,
    setIsLoading,
    is_network_error,
    set_is_network_error,
    onSubmit
  }
};

export default ForgetPasswordScreenController;