import * as Yup from "yup";

export const initialValues = {
    user_email: "",
    user_name: "",
    password: "",
    confirm_password: "",
    term_and_condition: false,
};

export const data = [
    {
        type: "text",
        placeholder: "Enter your email",
        name: "user_email",
        label: "Email",
        errors: "user_email",
        MAX_LENGTH: 50,
    },
    {
        type: "text",
        placeholder: "Enter your name",
        name: "user_name",
        label: "Name",
        errors: "user_name",
        MAX_LENGTH: 26,
    },
];

export const passworddata = [
    {
        type: "text",
        placeholder: "Enter your password",
        name: "password",
        label: "Password",
        errors: "password",
        MAX_LENGTH: 20,
        passwordIconType: "password",
    },
    {
        type: "text",
        placeholder: "Retype your password",
        name: "confirm_password",
        label: "Confirm Password",
        errors: "confirm_password",
        MAX_LENGTH: 20,
        passwordIconType: "password",
    },
];

export const UserTypeData =
    [{ UserType: "Students", enable: 1 },
    { UserType: "Teachers", enable: 0 },
    { UserType: "Parents", enable: 0 },
    { UserType: "Organizations", enable: 0 }]

export const ValidationSchema = Yup.object().shape({
    user_email: Yup.string()
        .required("Email is a required field")
        .test("notEmpty", "Email cannot be empty", (value) => value.trim() !== "")
        .email(" Enter a valid email"),
    // .test("isValidDomain", " Enter a valid email", (value) => {
    //     if (value) {
    //         const domain = value.split("@")[1];
    //         return !!TOP_EMAIL_DOMAINS[domain];
    //     }
    //     return true;
    // }),
    user_name: Yup.string()
        .required("Name is a required field")
        .test("notEmpty", "Name cannot be empty", (value) => value.trim() !== "")
        .matches(/^[A-Za-z\s]+$/, "Name should contain only alphabets")
        .matches(/^(?!.*\s{2,}).*$/, "Name should not contain consecutive spaces.")
        .min(3, "Name must be at least 3 characters long"),
    password: Yup.string()
        .required("Password is a required field")
        .matches(/\w*[a-z]\w*/, "Password must have a small letter")
        .matches(/\w*[A-Z]\w*/, "Password must have a capital letter")
        .matches(/\d/, "Password must have a number")
        .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, "Password must have a special character")
        .min(8, ({ min }) => `Password must be at least 8 characters`)
        .test("noSpaces", "Please enter valid password", (value) => {
            return !/\s/.test(value);
        }),
    confirm_password: Yup.string()
        .required("Confirm Password is a required field")
        .oneOf([Yup.ref("password")], "Passwords do not match"),
    // term_and_condition: Yup.boolean().isTrue("Please accept terms and conditions"),
});
