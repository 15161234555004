import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../api/URL/Api_Route";
import { ApiRequestMethod } from "../../../api/request/Api_Calls";
import toast from "react-hot-toast";
import Configuration from "../../../config/configuration";

const ResetPasswordScreenController = () => {
  const [is_network_error, set_is_network_error] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [passwordEyeIcon, setPasswordEyeIcon] = useState(false);
  const [confirmPasswordEyeIcon, setConfirmPasswordEyeIcon] = useState(false);
  const [passwordIconType, setPasswordIconType] = useState("password");
  const [confirmPasswordIconType, setConfirmPasswordIconType] = useState("password");

  const navigate = useNavigate();

  const passwordEyeIconChange = () => {
    setPasswordIconType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const confirmPasswordEyeIconChange = () => {
    setConfirmPasswordIconType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const onSubmit = (form_data) => {
    setIsLoading(true);
    const payload = {
      method: "post",
      url: API_URL.resetPassword,
      baseURL: Configuration.BASEURL + Configuration.SERVICEURL.ACCOUNT_MANAGEMENT_SERVICE,
      data: {
        EmailId: form_data?.user_email,
        newPassword: form_data?.confirm_password,
      },
    };

    ApiRequestMethod(payload)
      .then((res) => {
        if (res?.status === 200) {
          setIsLoading(false);
          navigate("/signin");
          toast("Password reset successfully. Please log in.");

        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setIsLoading(false);
          toast("Password incorrect");
        }
        else if (err?.response?.status === 404) {
          setIsLoading(false);
          toast("Email not exist");
        }
        else {
          setIsLoading(false);
          set_is_network_error(true)
          console.log(err)
        }
      });
  };


  return {
    is_network_error,
    isLoading,
    set_is_network_error,
    setIsLoading,
    onSubmit,
    passwordIconType,
    passwordEyeIcon,
    confirmPasswordIconType,
    confirmPasswordEyeIcon,
    passwordEyeIconChange,
    confirmPasswordEyeIconChange,
  };
};

export default ResetPasswordScreenController;
