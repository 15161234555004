import React from 'react';

const Dropdownicon_Svg=(props)=>{
    return(

<svg width="25" height="7" viewBox="0 0 25 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1.39551L12.5 5.67458L24 1.39551" stroke={props.stoke} stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel"/>
</svg>


)}

export default Dropdownicon_Svg;
