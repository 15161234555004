import React from 'react';


const Upward_Svg=(props)=>{
    return(
<svg width="25" height="7" viewBox="0 0 25 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 5.6748L12.5 1.39573L1 5.6748" stroke={props.stoke} stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel"/>
</svg>
)}

export default Upward_Svg;
