import { Circles } from "react-loader-spinner";
import '../Styles/LoaderStyles.css'

const LoaderView = (props) => {
 console.log("props?.isLoading isLoading   ====>", props?.isLoading)
//  if (props?.isLoading) {
//   setTimeout(() => {
//    props?.callBack();
//   });

//  }
 return (
  <>
   {
    props?.isLoading && 
    <div 
    // style={{
    //  backgroundColor: "transparent",
    //  display: "flex",
    //  justifyContent: "center",
    //  alignItems: "center",
    //  position:'absolute',
    //  overflow: "hidden",
    //  // width:'80vw',
    //  width:'100%',
    //  backgroundColor:'white',
    //  height: "104%",
    //  userSelect: "none",
    //  zIndex: 999
    // }} 
    div className="loader-main-container"  
    >
 
    <Circles
     height="40"
     width="40"
     color="var(--primary-color)"
     ariaLabel="circles-loading"
     wrapperStyle={{}}
     wrapperClass=""
     visible={true}
    />
   </div>
   }
  </>
 );
};

export default LoaderView;
