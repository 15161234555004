import { useNavigate } from "react-router-dom";
import Styles from "../css/Page_Not_Found.module.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { tabId } from "../../redux/action";
import LoaderView from "../../CommonComponents/LoaderComponet/View/LoaderView";

const Page_Not_Found = () => {
 const navigate = useNavigate();
 const dispatch = useDispatch();

 const [isLoading , setIsLoading] = useState(true);

 

 useEffect(() => {
  dispatch(tabId({ tab_Id: -1 }));
 }, []);

 useEffect(()=>{
    setTimeout(() => {           
        setIsLoading(false)
    }, 2000); 
 })

 if(isLoading){
    return <LoaderView isLoading={isLoading}/>;
 }



 return (
  <div className={Styles.page_Not_Found_Container}>
   <div className={Styles.page_Not_Found_Title}>404 Error</div>

   <div className={Styles.page_Not_Found_SubTitle}>Page not Found</div>

   <div className={Styles.page_Not_Found_Content}>
    Sorry, the page you’re looking for doesn't exist. If you think something is broken, report
    a problem
   </div>

   <div onClick={() => navigate("/")} className={Styles.page_Not_Found_Button_View}>
    Return to home
   </div>
  </div>
 );
};

export default Page_Not_Found;
