import * as React from "react";
const ProfilePicIcon = (props) => (
  <svg
    width={33}
    height={33}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 27.375C2 25.4522 2.76384 23.6081 4.12348 22.2485C5.48311 20.8888 7.32718 20.125 9.25 20.125H23.75C25.6728 20.125 27.5169 20.8888 28.8765 22.2485C30.2362 23.6081 31 25.4522 31 27.375C31 28.3364 30.6181 29.2584 29.9383 29.9383C29.2584 30.6181 28.3364 31 27.375 31H5.625C4.66359 31 3.74156 30.6181 3.06174 29.9383C2.38192 29.2584 2 28.3364 2 27.375Z"
      stroke="black"
      strokeWidth={3}
      strokeLinejoin="round"
    />
    <path
      d="M16.5 12.875C19.503 12.875 21.9375 10.4405 21.9375 7.4375C21.9375 4.43445 19.503 2 16.5 2C13.497 2 11.0625 4.43445 11.0625 7.4375C11.0625 10.4405 13.497 12.875 16.5 12.875Z"
      stroke="black"
      strokeWidth={3}
    />
  </svg>
);
export default ProfilePicIcon;
