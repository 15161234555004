import FbSvg from '../../../new_assets/Svg/FbSvg';
import InstaSvg from '../../../new_assets/Svg/InstaSvg';
import TwitSvg from '../../../new_assets/Svg/TwitSvg';
import YtSvg from '../../../new_assets/Svg/Ytsvg';
import LogoSvg from '../../../new_assets/Svg/LogoSvg';
import '../Styles/FooterSectionStyles.css'; 
import { useNavigate } from 'react-router-dom';

const FooterSectionView = () => {


  const navigateContainer = ()=>{
    navigate('/');
    window.scrollTo(0, 0);
  }

  const navigate = useNavigate();
  return (
    <div className="footer-container" >
      <div className="footer-content" >
        <div className="footer-left">
          <div className="logo">
            <LogoSvg  style={{fill:'black',cursor:'pointer'}} onClick={navigateContainer} />
          </div>

          <div className="social-media">
           {/* <a className='footer-anchor-links' href='https://www.youtube.com/' target="_blank"> <YtSvg /> </a>
           <a className='footer-anchor-links' href='https://www.facebook.com/' target="_blank"> <FbSvg /> </a>
           <a className='footer-anchor-links' href='https://x.com/' target="_blank"> <TwitSvg/> </a>
           <a className='footer-anchor-links' href='https://www.instagram.com/' target="_blank"> <InstaSvg /> </a> */}
          </div>

          <div className="copyright">
            <p style={{color:'black'}}>©2025 <span style={{fontWeight:'BOLD'}}>CognitivED.</span> All Rights Reserved.</p>
          </div>
        </div>


        <div className="footer-right">
          <div className="learn">
            <p className="section-title">Learn</p>
            <div className="subjects">
              <a className='footer-anchor-links'>Maths</a>
              <a className='footer-anchor-links'>Physics</a>
              <a className='footer-anchor-links'>Chemistry</a>
            </div>
          </div>

          <div className="elearning">
            <p className="section-title">CognitivED</p>
            <div className="links">
              <a className='footer-anchor-links'>About</a>
              <a className='footer-anchor-links'>Support</a>
              <a className='footer-anchor-links'>FAQs</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSectionView;
