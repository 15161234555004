import React, { useEffect, useState } from 'react';
import '../Styles/OtpInputsBoxStyles.css';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const OtpInputsBoxView = ({ value, onChange, resendOtp, email, error, touched }) => {
  const [countdown, setCountdown] = useState(180);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, [countdown]);

  const handleResendOtp = () => {
    setCountdown(180); // Reset the timer
    resendOtp(email); // Call the provided `resendOtp` function
  };

  const handleChange = (index, event) => {
    const newOtp = value.split('');
    newOtp[index] = event.target.value;
    onChange(newOtp.join(''));

    if (event.target.value && index < 5) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && !value[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData('text').slice(0, 6);
    onChange(pasteData);
    pasteData.split('').forEach((char, index) => {
      document.getElementById(`otp-${index}`).value = char;
    });
    const nextInput = document.getElementById(`otp-${pasteData.length}`);
    if (nextInput) nextInput.focus();
  };

  return (
    <div className="otp-input-parent-cont">
      <div className="label-otp-cont">
        <label className="label-otp">
          OTP<span style={{ color: 'red' }}>*</span>
        </label>
      </div>

      <div className="otpInputs">
        {Array(6)
          .fill('')
          .map((_, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="text"
              maxLength="1"
              className="otpInput"
              value={value[index] || ''}
              onChange={(event) => handleChange(index, event)}
              onKeyDown={(event) => handleKeyDown(index, event)}
              onPaste={handlePaste}
              autoComplete="one-time-code"
            />
          ))}
      </div>

      <div className='formikErrorText-tf-otp'>
        <div style={{display:'block'}}>{error && touched && error && <AiOutlineExclamationCircle size={14} />}</div>
        <div style={{display:'block'}}>{error && touched && error}</div>
      </div>

      <div className="resendText-otp">
        {countdown > 0 ? (
          <>
            Resend Code in{' '}
            <strong>
              {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')}
            </strong>
          </>
        ) : (
          <>
          Didn't receive OTP? {" "}
          <span className="resendLink-otp" onClick={handleResendOtp}>
            <strong>Resend</strong>
          </span>
          </>
        )}
      </div>
    </div>
  );
};

export default OtpInputsBoxView;
