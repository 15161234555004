import * as Yup from 'yup';
import { TOP_EMAIL_DOMAINS } from "../../../config/Constant";
import img1 from '../../../new_assets/img/auth-img-grid1.jpg'
import img2 from '../../../new_assets/img/auth-img-grid2.jpg'
import img3 from '../../../new_assets/img/auth-img-grid3.jpg'
import img4 from '../../../new_assets/img/auth-img-grid4.jpg'
import img5 from '../../../new_assets/img/auth-img-grid5.jpg'
import img6 from '../../../new_assets/img/auth-img-grid6.jpg'

export const initialValues = {
    user_email: "",
    password: "",
};

export const data = [
    { type: "text", placeholder: "Enter your email", name: "user_email", label: "Email", errors: "user_email", MAX_LENGTH: 50 },
]

export const passworddata = [
    { type: "text", placeholder: "Enter your password", name: "password", label: "Password", errors: "password", MAX_LENGTH: 20, passwordIconType: "password" },
]

export const authvideocarddata = [
    { image: img1, text: "Parent Monitoring" },
    { image: img2, text: "Organization" },
    { image: img3, text: "Students Collabration" },
    { image: img4, text: "Multiple device responses" },
    { image: img5, text: "Organization" },
    { image: img6, text: "Multiple device responses" }
]


export const ValidationSchema = Yup.object().shape({
    // user_email: Yup.string()
    //     .required("Email is a required field")
    //     .test("notEmpty", "Email cannot be empty", (value) => value.trim() !== "")
    //     .email("Invalid email"),

    // .test("isValidDomain", "Invalid email", (value) => {
    //     if (value) {
    //         const domain = value.split("@")[1];
    //         return !!TOP_EMAIL_DOMAINS[domain];
    //     }
    //     return true;
    // }),

    user_email: Yup.string()
        .required("Email is a required field")
        .test("notEmpty", "Email cannot be empty", (value) => value.trim() !== "")
        .email("Invalid email"),
    password: Yup.string()
        .required("Password is a required field")
        .test("notEmpty", "Password cannot be empty", (value) => value.trim() !== ""),
});