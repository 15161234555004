import { CountryToCurrency } from '../../../helper/country_to_currency'
import '../Styles/BillingCardStyles.css'

// const taxOption =[{taxName:"CGST",percentage:"50"},
//     {taxName:"SGST",percentage:"50"}
// ]

const BillingCardView = (props) => {


    const billingCardData = props?.addToCardData
    const totalNoOfVideosInCart = props?.totalNoOfVideosInCart
    const totalPriceOfAllVideos = props?.totalPriceOfAllVideos
    const currencyName = props?.addToCardData[0]?.cc
    const { countryCode, goToPayment } = props

    console.log("billingCardData -- ", billingCardData)
    console.log("countryCode -- ", countryCode)



    return (
        <div className='billing-card-view-main-container' >
            <div className='billing-card-view-title-container'>
                <p className='billing-card-view-title-name'>Billing Details</p>
            </div>
            <div className='billing-card-view-content-container'>
                <div className='billing-card-view-content-container-section1'>
                    <p className='billing-card-bold-font'>
                        {billingCardData.length + "  " + (billingCardData.length <= 1 ? "Course" : "Courses")}
                    </p>

                    {
                        billingCardData.map((value, index) => (
                            <div className='billing-card-list-of-courses' key={index}>
                                <div className='billing-card-list-of-courses-left' >
                                    <p className='billing-card-view-courses-total-heading' style={{ textAlign: 'start' }}>{value?.data?.CourseName}</p>
                                </div>
                                <div className='billing-card-list-of-courses-right'>
                                    <p className='billing-card-view-courses-total-heading' style={{ textAlign: "end" }}>{CountryToCurrency(countryCode)} {value?.data?.Price?.[CountryToCurrency(countryCode)]}</p>
                                </div>
                            </div>
                        ))
                    }

                    {/* {
    taxOption.map((value,index)=>(
                    <div className='billing-card-list-of-courses' key={index}>
                        <div className='billing-card-list-of-courses-left'>
                            <p className='billing-card-view-courses-total-heading-gst'>{value.taxName} {value.percentage}%</p>                    
                        </div>
                        <div className='billing-card-list-of-courses-right'>


                        <p className='billing-card-view-courses-total-heading-gst'>CountryToCurrency(countryCode) 60</p> 
                        </div>
                        </div>

))}
                   */}

                    <div className='billing-card-total-number-of-courses'>
                        <div className='billing-card-total-number-of-courses-left'>
                            <p className='billing-card-bold-font'>Total</p>
                        </div>
                        <div className='billing-card-total-number-of-courses-right'>
                            <p className='billing-card-bold-font' style={{ textAlign: "end" }}>{CountryToCurrency(countryCode)} {billingCardData?.reduce((sum, item) => sum + (item?.data?.Price[CountryToCurrency(countryCode)] || 0), 0)}

                            </p>
                        </div>
                    </div>

                </div>
                {props?.isButton &&
                    <div className='billing-card-view-content-container-section2' onClick={() => {
                        goToPayment();
                    }}>
                        Buy Now
                    </div>
                }
            </div>
        </div>
    )
}

export default BillingCardView;