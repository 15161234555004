import React from "react";
import "../Styles/PaymentPreparationCardStyles.css";
import paymentCardLogo from "../../../new_assets/payment_screen_icons/paymentLogo.png"
import paymentCardSuccessLogo from "../../../new_assets/payment_screen_icons/paymentSuccessLogo.png"
import paymentCardFailLogo from "../../../new_assets/payment_screen_icons/paymentFailLogo.png"
import { useNavigate } from "react-router-dom";
import PaymentLoaderView from "../../PaymentLoader/View/PaymentLoaderView";

const PaymentPreparationCardView = ({paymentCardTitle, paymentCardButtonTxt, secondaryButtonTxt, showPaymentButton, showPaymentLoader, paymentHandler}) => {

  const navigate = useNavigate();

  return (
      <div className="payment-card">

        {/* payment card icon and loader */}
        <div className="icon-container">

          {showPaymentLoader ? <PaymentLoaderView/> : 
          <img src={
            paymentCardTitle === "Prepare for Payment" ? paymentCardLogo :
            paymentCardTitle === "Payment Successful!" ? paymentCardSuccessLogo :
            paymentCardFailLogo
          } 
          alt="Payment Icon" 
          className="payment-icon"/>
          }

        </div>

        {/* payment card title */}
        <h2 className="title"
            style={{color: paymentCardTitle === "Payment couldn't process" ||
              paymentCardTitle === "Payment Failed" ? "#DF615C" :
              paymentCardTitle === "Payment Successful!" ? "#21B573" : "#170F49"

            }}
        >
          {paymentCardTitle}
        </h2>

        {/* payment card description */}

        {/* Prepare for Payment or Payment Processing desc */}
        {(paymentCardTitle === "Prepare for Payment" || paymentCardTitle === "Payment Processing") && (
        <p className="description">
          <strong>Please do not refresh, go back, or close this page</strong> during the payment
          process. This ensures that your transaction completes smoothly.
        </p>
        )}

        {/* Payment couldn't process desc (create order failure) */}
        {paymentCardTitle === "Payment couldn't process" && 
        <p className="description">
          Unfortunately, your payment could <strong>not be processed.</strong> Please try <strong>again or contact support.</strong> 
        </p>
        }

        {/* Something went wrong while payment processing desc (socket closed before payment completion) */}
        {paymentCardTitle === "Something Went Wrong!" && 
        <p className="description">
          Unfortunately, something went wrong and connection get closed, so payment could <strong>not be continue.</strong> Please try <strong>again or contact support.</strong> 
        </p>
        }

        {/* Payment Failed desc */}
        {paymentCardTitle === "Payment Failed" && 
        <p className="description">
          Unfortunately, your payment could <strong>not be processed.</strong> Please try <strong>again or contact support.</strong> If the amount has been deducted, the payment will be automatically <strong>refunded.</strong>
        </p>
        }

        {/* Payment Successful! desc */}
        {paymentCardTitle === "Payment Successful!" && 
        <p className="description">
          <strong>Your transaction was completed successfully. You can now access your courses.</strong>
        </p>
        }

        {/* Payment initiater button */}
        {showPaymentButton && 
        <button className="proceed-btn"
          onClick={paymentCardButtonTxt === "Start Learning" ? () => navigate("/mycourse") : paymentHandler}
        >
          {paymentCardButtonTxt}</button>
        }

        {/* Secondary button in payment card */}
        {!showPaymentLoader &&
          <button className="back-btn"
            onClick={paymentCardTitle === "Payment Successful!" ? () => navigate("/") : paymentCardTitle === "Payment couldn't process" || paymentCardTitle === "Payment Failed" ? () => navigate("#") : () => navigate(-1)
          }
          >{secondaryButtonTxt}</button>
        }

      </div>
  );
};

export default PaymentPreparationCardView;
