import '../Styles/AuthMainContainerStyles.css'
const AuthMainContainerView = ({children})=>{
    return(
        <div className='auth-screen-main-container'>
            {children}
        </div>
    )
}

export default AuthMainContainerView;
