import '../Styles/CourseVideoPageStyles.css';
import Video_Svg from '../../../assets/svg/Video_Svg';
import VideoPlayerView from '../../../CommonComponents/VideoPlayerContainer/View/VideoPlayerView';
import CoursePriceCard from '../../../CommonComponents/CoursePriceCard/View/CoursePriceCard';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import TopicDetailsBoxView from '../../../CommonComponents/TopicDetailsContainer/View/TopicDetailsBoxView';
import { useDispatch, useSelector } from 'react-redux';
import { showbanner } from '../../../redux/action';
import { API_URL } from '../../../api/URL/Api_Route';
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import Configuration from '../../../config/configuration';
import queryString from 'query-string';
import { CountryToCurrency } from '../../../helper/country_to_currency';
import NetworkError from '../../../component/javascript/Network_Error';
import LoaderView from '../../../CommonComponents/LoaderComponet/View/LoaderView';

const CourseVideoPageView = () => {

    const navigate = useNavigate();
    const [videoData, setVideoData] = useState(null);
    console.log("videodata=====>", videoData)
    const [courseVideoPageData, setCourseVideoPageData] = useState(null)
    const { courseId } = useParams();

    const dispatch = useDispatch()

    const [isLoading, setLoading] = useState(false)
    const [is_network_error, set_is_network_error] = useState(false);
    const { session_details } = useSelector(
        (state) => state.applicationState
    );


    const fetchData = async () => {
        setLoading(true)
        const payload = {
            method: "post",
            url: API_URL.getCourseById,
            // Configuration.BASEURL
            baseURL: Configuration.BASEURL + Configuration.SERVICEURL.COURSE_MANAGEMENT_SERVICE,
            // baseURL: "http://192.168.123.134:6004/",
            data: {
                "_id": courseId
            },
        };
        ApiRequestMethod(payload)
            .then((res) => {

                if (res?.status === 200) {
                    if (res?.data?.data) {
                        setVideoData(res?.data?.data?.AssetPath + res?.data?.data?.Video);
                        setCourseVideoPageData(res?.data?.data)
                        setLoading(false)
                    }
                }
            })
            .catch((err) => {
                console.log("Home Page Error===>", err)
                if (err?.response?.status === 404) {
                    dispatch(showbanner({ showbanner: true }))
                    setLoading(false)
                } else {
                    console.error("Get Subject Data Fetching Error:", err);
                    set_is_network_error(true)
                    setLoading(false)
                }
            });

    };

    useEffect(() => {
        if (!courseId) return;
        fetchData();
    }, [courseId]);

    const goToPayment = () => {

        const updatedCourseData = { ...courseVideoPageData, currencyCode: CountryToCurrency(session_details?.cc), countryCode: session_details?.cc };

        let formattedData = [
            {
                data: {
                    ...updatedCourseData,
                    // cartCount: 1 // Adding the cart count as per the desired output
                },
                // thumnail_image: "http://localhost:55509/assert/image?id=NaN"
            }
        ];

        console.log("single buy now course data --- ", formattedData);

        const dataString = JSON.stringify(formattedData);
        const queryStringified = queryString.stringify({ cart: dataString });
        navigate(`/payment?${queryStringified}`);

    }

    if (isLoading) {
        return <LoaderView isLoading={isLoading} />;
    }


    if (is_network_error) {
        return <NetworkError callBack={() => {
            set_is_network_error(false)
            fetchData();
        }} />;
    }

      if(isLoading){
        return <LoaderView isLoading={isLoading} />;
      }

    return (
        <div className='course-video-page-main-container'>
            <div className='course-video-page-left-video-container'>
                <div className='course-video-page-course-video-container'>
                    <VideoPlayerView video_path_data={videoData} />
                </div>
                <div className='course-video-page-course-video-description-container'>
                    <div className='course-video-page-course-title-name-container'>
                        <span className='course-title-text-style'>{courseVideoPageData?.CourseName}</span>
                    </div>
                    <div className='course-video-page-course-description-container'>
                        <span className='course-description-text-style'>
                            {courseVideoPageData?.Description}
                        </span>
                    </div>
                </div>
                <div className='course-video-page-main-topic-container'>
                    <div className='course-video-page-topic-heading-row'>
                        <div className='topic-heading-container'>
                            <span className='topic-heading-styling'>{courseVideoPageData?.CourseName}</span>
                        </div>
                        <div className='topic-icon-details-container'>
                            <div className='topic-icon-details'>
                                <Video_Svg />
                                <span className='icon-desc-styling'>20 Videos</span>
                            </div>
                        </div>
                    </div>
                    <div className='row-wrapup'>
                        <TopicDetailsBoxView setVideoData={setVideoData} set_is_network_error/>
                    </div>
                </div>
            </div>
            <div className='course-video-page-right-video-details-container'>
                <CoursePriceCard price={courseVideoPageData?.Price} courseId={courseId} goToPayment={goToPayment} />
            </div>
        </div>
    );
};

export default CourseVideoPageView;