import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiRequestMethod } from "../../../api/request/Api_Calls";
import { API_URL } from "../../../api/URL/Api_Route";
import queryString from "query-string";
import toast from "react-hot-toast";
import { encryptPassword } from "../../../helper/encrypt_password";
import Configuration from "../../../config/configuration";

const SignupScreenController = () => {
  const navigate = useNavigate();
  const [is_small_screen, setIsSmallScreen] = useState(false);
  const [is_landscape, setIsLandscape] = useState(false);
  const [is_network_error, set_is_network_error] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordEyeIcon, setPasswordEyeIcon] = useState(false);
  const [confirmPasswordEyeIcon, setConfirmPasswordEyeIcon] = useState(false);
  const [passwordIconType, setPasswordIconType] = useState("password");
  const [confirmPasswordIconType, setConfirmPasswordIconType] = useState("password");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
      const isLandscapeOrientation = window.matchMedia(
        "(max-height: 575.98px) and (orientation: landscape)"
      ).matches;
      setIsLandscape(isLandscapeOrientation);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSubmit = (form_data) => {
    setIsLoading(true);

    const payload = {
      method: "post",
      url: API_URL.user_registration,
      baseURL: Configuration.BASEURL + Configuration.SERVICEURL.ACCOUNT_MANAGEMENT_SERVICE,
      data: {
        UserName: form_data?.user_name,
        EmailId: form_data?.user_email,
        Password: encryptPassword(form_data?.password),
        RoleID: Configuration.ROLEID,
        UserType: 1,
      },
    };
    ApiRequestMethod(payload)
      .then((res) => {
        if (res.status == 200) {
          const data = { id: 100, email: form_data.user_email, name: form_data?.user_name, navigate_from: "signup" };
          const queryStringified = queryString.stringify(data);
          navigate(`/otp?${queryStringified}`);
          setIsLoading(false);
          toast("OTP Sent");
        }
      })
      .catch((err) => {
        console.log("error in signup screen catch==>", err)
        if (err?.response?.status === 409) {
          setIsLoading(false);
          toast("Already Email is Registered");
        } else {
          setIsLoading(false);
          set_is_network_error(true);
          console.error("Error during registration:", err);
        }
      });
  };

  const passwordEyeIconChange = () => {
    setPasswordIconType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const confirmPasswordEyeIconChange = () => {
    setConfirmPasswordIconType((prevType) => (prevType === "password" ? "text" : "password"));
  };
  const Navigation = () => {
    navigate(`/signin`);
  };

  return {
    is_small_screen,
    is_landscape,
    is_network_error,
    isLoading,
    passwordIconType,
    passwordEyeIcon,
    confirmPasswordIconType,
    confirmPasswordEyeIcon,
    set_is_network_error,
    setIsLoading,
    onSubmit,
    passwordEyeIconChange,
    confirmPasswordEyeIconChange,
    Navigation,
  };
};

export default SignupScreenController;
