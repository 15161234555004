import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../../api/URL/Api_Route';
import toast from 'react-hot-toast';
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import RunTimeConstant from '../../../config/RuntimeConfig';
import queryString from "query-string";
import Configuration from '../../../config/configuration';

const OtpScreenController = (navigate_from) => {
  console.log("navigate_from -- ", navigate_from)
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [is_network_error, set_is_network_error] = useState(false);

  const onSubmit = ({ user_email, user_otp }, { resetForm }) => {
    setIsLoading(true);
    const dataToSend = { EmailId: user_email, Otp: user_otp }
    if (navigate_from === "forgetpassword") {
      dataToSend.type = "forgotPassword"
    }
    const payload = {
      method: 'post',
      baseURL: Configuration.BASEURL + Configuration.SERVICEURL.ACCOUNT_MANAGEMENT_SERVICE,
      url: "/users/otp_verify",
      data: dataToSend,
    };

    ApiRequestMethod(payload)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          toast("OTP Successfully Verified");
          if (navigate_from === "forgetpassword") {
            let data = { id: 102, email: user_email, navigate_from: "otp" };
            const queryStringified = queryString.stringify(data);
            setIsLoading(false);
            navigate(`/resetpassword?${queryStringified}`);
          } else {
            setIsLoading(false);
            navigate('/signin');
          }
        }

      })
      .catch((err) => {

        console.log("error in Otp", err?.response?.status)
        if (err?.response?.status === 400) {
          setIsLoading(false);
          toast("Invalid OTP");
        }
        else {
          console.log("error====>", err)
          setIsLoading(false);
          set_is_network_error(true);
        }

      });
    resetForm();
  };

  const resendOtp = (email) => {
    console.log("resendOtp --- ", email)
    setIsLoading(true);
    const payload = {
      method: 'post',
      baseURL: Configuration.BASEURL + Configuration.SERVICEURL.ACCOUNT_MANAGEMENT_SERVICE,
      url: API_URL.resend_otp,
      data: { EmailId: email },
    };

    ApiRequestMethod(payload)
      .then((res) => {
        setIsLoading(false);
        console.log("res resendOtp -- ", res)
        res.status === 200 ? toast('OTP resent successfully') : toast('Failed to resend OTP');
      })
      .catch(() => {
        setIsLoading(false);
        set_is_network_error(true)
      });
  };

  const editEmailHandler = (data) => {
    console.log("edit mail clicked --- ", data)
    if (data?.navigate_from === "signup") {
      console.log("edit email from signup")
      const signup_data = { id: 109, email: data.email, name: data.name, flow: "signup_otp" };
      const queryStringified = queryString.stringify(signup_data);
      navigate(`/signup?${queryStringified}`);
    } else if (data?.navigate_from === "forgetpassword") {
      console.log("edit email from forgetpassword")
      const forgetPassword_data = { id: 110, email: data.email, flow: "forgotpassword_otp" };
      const queryStringified = queryString.stringify(forgetPassword_data);
      navigate(`/forgetpassword?${queryStringified}`);
    }
  }

  return {
    isLoading,
    setIsLoading,
    is_network_error,
    set_is_network_error,
    onSubmit,
    resendOtp,
    navigate,
    editEmailHandler,
    set_is_network_error
  };
};

export default OtpScreenController;