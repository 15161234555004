// console.log("process", process)

console.log("process.env", process.env)
const Configuration = {
    BASEURL: process.env.REACT_APP_SERVER_BASE_URL,
    BASEURL_WS: process.env.REACT_APP_SERVER_BASE_URL_WS,
    CURRENT_PROCESS: process.env.REACT_APP_PROCESS,
    SERVICEURL: {
        ACCOUNT_MANAGEMENT_SERVICE: process.env.REACT_APP_ACCOUNT_MANAGEMENT_SERVICE_URL, // Account Management Service
        IDENTITY_MANAGEMENT_SERVICE: process.env.REACT_APP_IDENTITY_MANAGEMENT_SERVICE_URL, // Identity Management Service
        POLICY_MANAGEMENT_SERVICE: process.env.REACT_APP_POLICY_MANAGEMENT_SERVICE_URL, // Policy Management Service
        CACHE_MANAGEMENT_SERVICE: process.env.REACT_APP_CACHE_MANAGEMENT_SERVICE_URL, // Cache Management Service
        COURSE_MANAGEMENT_SERVICE: process.env.REACT_APP_COURSE_MANAGEMENT_SERVICE_URL, // Course Service
        MESSAGE_SERVICE: process.env.REACT_APP_MESSAGE_SERVICE_URL, // Message Service
        WEBSOCKET_SERVICE: process.env.REACT_APP_WEBSOCKET_MESSAGE_SERVICE_URL, // Websocket Service
        PURCHASE_SERVICE: process.env.REACT_APP_PURCHASE_SERVICE_URL, // Purchase Service
        PAYMENT_SERVICE: process.env.REACT_APP_PAYMENT_SERVICE_URL, // Payment Service
    },
    CDN_URL: process.env.REACT_APP_CDN_URL,
    ROLEID: "67aca3055997b1612988c47b",
    RAZOR_PAY_KEY_ID: process.env.REACT_APP_RAZOR_PAY_KEY_ID, // Razorpay key id
}

export default Configuration;