import { useEffect, useState } from "react";
import { LogIn, sessionDetails } from "../../../redux/action";
import { useNavigate, useLocation } from "react-router-dom";
import { API_URL } from "../../../api/URL/Api_Route";
import { ApiRequestMethod } from "../../../api/request/Api_Calls";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import RunTimeConstant from "../../../config/RuntimeConfig";
import { encryptPassword } from "../../../helper/encrypt_password";
import Configuration from "../../../config/configuration";

const SigninScreenController = () => {
  const [is_small_screen, setIsSmallScreen] = useState(false);
  const [is_landscape, setIsLandscape] = useState(false);
  const [is_network_error, set_is_network_error] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [height, setHeight] = useState(720);
  const [width, setWidth] = useState(1200);
  const [passwordIconType, setPasswordIconType] = useState("password");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();



  useEffect(() => {
    const handleBackButton = () => {
      navigate('1'); // Moves the user forward
    };

    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);


  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
      if (window.innerWidth < 992) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
      const isLandscapeOrientation = window.matchMedia(
        "(max-height: 575.98px) and (orientation: landscape)"
      ).matches;
      setIsLandscape(isLandscapeOrientation);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  const passwordEyeIconChange = () => {
    setPasswordIconType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const onSubmit = (form_data) => {
    console.log("RunTimeConstant.SERVICEURL.IMS", RunTimeConstant.SERVICEURL.IMS)
    console.log("Login values", form_data);
    setIsLoading(true);

    let payload = {
      method: "post",
      url: API_URL.login,
      baseURL: Configuration.BASEURL + Configuration.SERVICEURL.IDENTITY_MANAGEMENT_SERVICE,
      data: {
        EmailId: form_data?.user_email,
        Password: encryptPassword(form_data?.password),
        FingerPrint: "zwedaszsdzw"
      },
    };
    console.log("Payload", payload)
    ApiRequestMethod(payload)
      .then(async (res) => {
        if (res?.status == 200) {
          console.log("authentication reply 200 entered")
          let payload = {
            baseURL: Configuration.BASEURL + Configuration.SERVICEURL.IDENTITY_MANAGEMENT_SERVICE,
            url: API_URL.authorize,
          };
          ApiRequestMethod(payload)
            .then(async (res) => {
              console.log("entered authorize")
              console.log("lo`gin res", res);
              if (res?.status == 200) {
                let payload = {
                  baseURL: Configuration.BASEURL + Configuration.SERVICEURL.CACHE_MANAGEMENT_SERVICE,
                  url: `${API_URL.getSession}`,
                };
                ApiRequestMethod(payload)
                  .then(async (res) => {
                    console.log("entered getsession", res.data)
                    if (res?.status == 200 && res.data?.status) {
                      dispatch(LogIn({ token: res.data.sub }));
                      dispatch(sessionDetails({ session_details: res.data }))
                      navigate('/')
                      setTimeout(() => {
                        setIsLoading(false)
                      }, 3000);
                      toast.success('Login successful')
                    }
                  })
                  .catch((err) => {
                    if (err?.response?.status == 404) {
                      console.log("err?.response?.status == 404")
                    }
                    else {
                      console.log("Under else")
                    }
                  })
              }
            }
            )
            .catch((err) => {
              setIsLoading(false);
              set_is_network_error(true);
              console.log("Authorize catch error", err);
            });

        } else {
          console.log('Error')

        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log("err?.response?.status====>", err?.response?.status)
          setIsLoading(false);
          toast("Password incorrect");
          console.log("What is the error ====>", err.response.data.error);
          console.log("Password Email Incorrect Error");
        }
        else if (err?.response?.status === 404) {
          setIsLoading(false);
          console.log("err?.response?.status====>", err?.response?.status)
          toast("Email not exist");
          console.log("What is the error ====>", err.response.data.error);
          console.log("Password Email Incorrect Error");
        }
        else if (err?.response?.status == 409) {
          setIsLoading(false)
          navigate('/existing_alert')
          console.log("Naviating to Dialog box for getting reply from the user whether they are going to proceed with current session or previous session") ////print
        }
        else {
          setIsLoading(false);
          set_is_network_error(true);
          console.log(" authentication catch error", err);
        }
      });
  }

  const forgetPageNavigation = () => {
    navigate(`/forgetPassword`)
  }

  const signupPageNavigation = () => {
    navigate(`/signup`)
  }

  return {
    is_small_screen,
    is_landscape,
    is_network_error,
    isLoading,
    height,
    width,
    passwordIconType,
    // passwordEyeIcon,
    setHeight,
    setWidth,
    setIsSmallScreen,
    setIsLandscape,
    setIsLoading,
    set_is_network_error,
    // setPasswordEyeIcon,
    setPasswordIconType,
    onSubmit,
    forgetPageNavigation,
    signupPageNavigation,
    passwordEyeIconChange,
    // confirmPasswordEyeIconChange,

  };
}
export default SigninScreenController;