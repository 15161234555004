import { useNavigate } from 'react-router-dom';
import '../Styles/GetStartedSectionStyles.css';
import { getStartedSetionContent } from './../Model/GetStartedSectionModel';
import { useSelector } from 'react-redux';

const GetStartedSectionView = () => {
  const { token} = useSelector((state) => state.applicationState);
  const navigate = useNavigate();
  return (
    <div className="get-started-main-container" data-aos="fade-up">
      <p className="get-started-main-title">{getStartedSetionContent.title}</p>
      <p className="get-started-main-subtext">{getStartedSetionContent.subtext}</p>
      {token?(
         <div className="get-started-container-button" onClick={()=>{navigate('/courses')}}>{getStartedSetionContent.buttonText}</div>
      ):(
        <div className="get-started-container-button" onClick={()=>{navigate('/signin')}}>{getStartedSetionContent.buttonText}</div>
      )}
     
    </div>
  );
};

export default GetStartedSectionView;
