import { useState } from 'react';
import { ApiRequestMethod } from '../../../api/request/Api_Calls';
import { API_URL } from '../../../api/URL/Api_Route';
import { useNavigate } from "react-router-dom";
import { LogOut, sessionDetails, LogIn } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import RunTimeConstant from '../../../config/RuntimeConfig';
import toast from 'react-hot-toast';
import Configuration from '../../../config/configuration';



const Dialogpagecontroller = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const onSubmit = () => {
        setIsLoading(true)
        let payload = {
            baseURL: Configuration.BASEURL + Configuration.SERVICEURL.IDENTITY_MANAGEMENT_SERVICE,
            url: API_URL.logout,
        };
        ApiRequestMethod(payload)
            .then(async (res) => {

                if (res?.status == 200) {
                    dispatch(LogOut());
                    toast("Logged out Successfully")
                    navigate('/')
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
            })
    }

    const onConfirmSubmit = () => {
        setIsLoading(true)
        let payload = {
            method: "delete",
            baseURL: Configuration.BASEURL + Configuration.SERVICEURL.CACHE_MANAGEMENT_SERVICE,
            url: API_URL.deleteExistingSession,
        };
        ApiRequestMethod(payload)
            .then(async (res) => {
                if (res?.status == 200) {
                    let payload = {
                        baseURL: Configuration.BASEURL + Configuration.SERVICEURL.CACHE_MANAGEMENT_SERVICE,
                        url: `${API_URL.getSession}`,
                    };
                    ApiRequestMethod(payload)
                        .then(async (res) => {
                            if (res?.status == 200) {
                                dispatch(LogIn({ token: res.data.sub }));
                                dispatch(sessionDetails({ session_details: res.data }))
                                navigate('/')
                                setTimeout(() => {
                                    setIsLoading(false)
                                }, 3000);
                            }
                        }
                        )
                        .catch((err) => {
                            setIsLoading(false);
                            console.log(err)
                        })
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err)
            })
    }

    return {
        onSubmit,
        onConfirmSubmit,
        isLoading,
        setIsLoading
    }
}

export default Dialogpagecontroller;